// eslint-disable-next-line import/no-cycle
import moment from 'moment';
import {
  defaultColor,
  defaultDirection,
  defaultLocale,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from '../constants/defaultValues';

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error,
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error,
    );
  }
};

export const getCurrentLanguage = () => {
  let language;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage'),
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error,
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error,
    );
  }
};

export const getCurrentUser = () => {
  let user;
  try {
    user =
      localStorage.getItem('gogo_current_user') != null
        ? JSON.parse(localStorage.getItem('gogo_current_user'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('gogo_current_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('gogo_current_user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

/**
 * @method getToken
 * @description Used to get the current JWT token from local storage
 * @return {string}-token
 * */
export const getItem = (key) => {
  let value;
  try {
    value =
      localStorage.getItem(key) != null ? localStorage.getItem(key) : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    value = null;
  }
  return value;
};

/**
 * @method getToken
 * @description Used to set the current JWT token to local storage
 * @param {token} - JWT token
 * */
export const setItem = (key, value) => {
  try {
    localStorage.removeItem(key);
    if (value) {
      localStorage.setItem(key, value);
    } /* else {
      localStorage.removeItem('token');
    } */
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const removeItem = (key) => {
  localStorage.removeItem(key);
};
export const unAuthorizedToken = () => {
  console.log('unAuthorizedToken', 'unAuthorizedToken');
};

export function isValidURL(url) {
  const pattern = new RegExp(
    '^((ft|htt)ps?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?' + // port
      '(\\/[-a-z\\d%@_.~+&:]*)*' + // path
      '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return pattern.test(url);
}

export function dateFormate(date) {
  return moment(date).format('DD/MM/YYYY');
}

export const ConfigData = {
  currency: '₹',
};
export function currencyFormatter(amt) {
  if (amt === null  || amt === undefined) {
    return 0;
  } 
  return amt.toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
}

// Function to generate a unique identifier for the tab
export const generateTabId = () => {
  try {
    if (!sessionStorage.tabId) {
      sessionStorage.tabId = Math.random().toString(36).substring(2, 15);
    }
    return sessionStorage.tabId;
  } catch (error) {
    console.error('Error generating tabId:', error);
    // You might want to handle the error or log it accordingly
    return null;
  }
};

// Update your storage functions to include the tabId
export const setItemSession = (key, value) => {
  try {
    sessionStorage.setItem(`${key}_${generateTabId()}`, JSON.stringify(value));
  } catch (error) {
    console.error(`Error setting sessionStorage item for key ${key}:`, error);
    // You might want to handle the error or log it accordingly
  }
};

export const getItemSession = (key) => {
  try {
    const value = sessionStorage.getItem(`${key}_${generateTabId()}`);
    return value ? JSON.parse(value) : null;
  } catch (error) {
    console.error(`Error getting sessionStorage item for key ${key}:`, error);
    // You might want to handle the error or log it accordingly
    return null;
  }
};
