import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { Accordion, AccordionTab } from 'primereact/accordion';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Button } from 'primereact/button';
import { getItemSession } from '../helpers/Utils';

function LoanFilter(props) {
    const { handelFilter, dataU } = props;
    const [statuses] = useState(["INITIATED", "LOGIN", "IN PROCESS", "PENDING", "APPROVED", "DISBURSED", "REJECTED", "REVOKED"]);
    const [incomeSource] = useState({ "1": "Salaried", "2": "Business", "3": "Others" });
    const {
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        setValue,
        reset
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onBlur',
        defaultValues: {
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "income_source": "",
            "loan_type_id": "",
            "status": "",
            "from_date": "",
            "to_date": "",
        }
    });
    const handleReset = () => { reset(); handelFilter() };
    const submitFilter = () => {
        handelFilter(0, getValues())
    };
    return (
        <Accordion activeIndex={[0]}>
            <AccordionTab header={
                <span className="flex align-items-center gap-2 w-full m-2">
                    <span className="font-bold white-space-nowrap">Filter </span>
                </span>
            }>
                <form method="post" onSubmit={handleSubmit(submitFilter)} className="mt-3 px-3 bit-1" id="checkEligibility_form">
                    <div className='row'>
                        {/*<div className='col-lg-2'>
                            <div className="mb-5 position-relative">
                                <FormControl sx={{ width: '100%' }}   >
                                    <InputLabel id="loanTypeValue">Select Loan Type*</InputLabel>
                                    <Controller
                                        name="loan_type_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="loan_type_id"                             {...field}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setValue('loan_type_id', e.target.value);
                                                }}
                                            >
                                                <MenuItem value="" disabled > <em>Select Loan Type</em> </MenuItem>
                                                {
                                                    
                                                    pageData?.map((value) => {
                                                        return (
                                                            <MenuItem key={value.id} value={value.id}>
                                                                {value.name}
                                                            </MenuItem>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                {errors.loan_type_id && getValues('loan_type_id') === "" && <p className="error"> {errors.loan_type_id.message}</p>}
                            </div>
                        </div>*/}
                        <div className='col-lg-2'>
                            <div className="mb-3 position-relative">
                                <FormControl sx={{ width: '100%' }}   >
                                    <InputLabel id="status">Select Status</InputLabel>
                                    <Controller
                                        name="status"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                fullWidth
                                                labelId="status"
                                                id="status"
                                                label="Select Bank"
                                                {...field}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setValue('status', e.target.value);
                                                }}
                                            >
                                                <MenuItem value="" disabled > <em>Select Ststus</em> </MenuItem>
                                                {Object.entries(statuses).map(([key, value]) => (
                                                    <MenuItem key={key} value={key}>
                                                        {value}
                                                    </MenuItem>
                                                ))
                                                }
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                {errors.status && <p className="error"> {errors.status.message}</p>}
                            </div>
                        </div>
                        <div className='col-lg-2'>
                            <div className="mb-3 position-relative">
                                <FormControl sx={{ width: '100%' }}   >
                                    <InputLabel id="income_source">Select Income source</InputLabel>
                                    <Controller
                                        name="income_source"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                fullWidth
                                                labelId="income_source"
                                                id="income_source"
                                                label="Select Bank"
                                                {...field}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setValue('income_source', e.target.value);
                                                }}
                                            >
                                                <MenuItem value="" disabled > <em>Select Income source</em> </MenuItem>
                                                {Object.entries(incomeSource).map(([key, value]) => (
                                                    <MenuItem key={key} value={key}>
                                                        {value}
                                                    </MenuItem>
                                                ))
                                                }
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                {errors.income_source && <p className="error"> {errors.income_source.message}</p>}
                            </div>
                        </div>
                        <div className='col-lg-2'>
                            <div className="mb-5 position-relative">
                                <Controller
                                    name="from_date"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            type='date'
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            id="from_date"
                                            label="Date From"
                                            variant="outlined"
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setValue('from_date', e.target.value);
                                            }}
                                        />
                                    )}
                                />
                                {errors.from_date && <p className="error"> {errors.from_date.message}</p>}
                            </div>
                        </div>
                        <div className='col-lg-2'>
                            <div className="mb-5 position-relative">
                                <Controller
                                    name="to_date"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            type='date'
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            id="to_date"
                                            label="Date To"
                                            variant="outlined"
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setValue('to_date', e.target.value);
                                            }}
                                        />
                                    )}
                                />
                                {errors.to_date && <p className="error"> {errors.to_date.message}</p>}
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <Button type="submit" className='ml-2 mb-2' icon="pi pi-search" label="&nbsp; Search" outlined />
                            <Button type="button" className='ml-2 mb-2' icon="pi pi-filter-slash" label="&nbsp; Clear" outlined onClick={handleReset} />

                        </div>
                    </div>
                </form>
            </AccordionTab>
        </Accordion>
    )
}

export default LoanFilter