import { createSlice } from '@reduxjs/toolkit';

const masterDataSlice = createSlice({
  name: 'mastersList',
  initialState: {
    data: null,
  },
  reducers: {
    setMasterData: (state, action) => {
      state.data = action.payload;
      //return action.payload;
    },
  },
});

export const { setMasterData } = masterDataSlice;

export default masterDataSlice.reducer