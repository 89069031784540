/* eslint-disable no-unused-vars */
import React from 'react';
import { createBrowserRouter} from "react-router-dom";
// import BlankPage from './views/app/blank-page'; 
import Home from '../components/Home'; 
import NotFound from '../components/NotFound'; 
import LoanBasicDetails from '../components/LoanBasicDetails';
import Addresses from '../components/Addresses';
import CoApplicant from '../components/CoApplicant';
import Reference from '../components/Reference';
import PreviousLoan from '../components/PreviousLoan';
import UploadDoc from '../components/UploadDoc';
import LoansList from '../components/LoansList';
import NotFoundDep from '../components/NotFoundDep';
import LoanView from '../components/LoanView';
import DynamicRoutng from '../components/DynamicRoutng';
import DynamicRoutes from './DynamicRoutes';
import CreditCardDetails from '../components/CreditCard/CreditCardDetails';
import CreditCardList from '../components/CreditCard/CreditCardList';
import CreditCardReport from '../components/CreditCard/CreditCardReport';
// import { getItem } from '../helpers/Utils';

// export const ViewError = React.lazy(() =>
//   import(/* webpackChunkName: "views-error" */ './views/error'),
// );

// export const ViewError401 = React.lazy(() =>
//   import(/* webpackChunkName: "views-error" */ './views/error-401'),
// );

const Routes = createBrowserRouter([
  {
    path: "/",
    element: <DynamicRoutes />,
  },
  // {
  //   path: "/",
  //   element: sessionStorage.getItem('plugin_accessibility') && (sessionStorage.getItem('plugin_accessibility') === "Darcy" || sessionStorage.getItem('plugin_accessibility') === 'Eliza') ? <LoansList /> : <Home/>,
  // },
  {
    path: "/:loanTy?/:applicationId?",
    element: <Home/>,
    errorElement:<NotFound/>
  },
  // {
  //   path: "/:loanTy/:applicationId",
  //   element: <Home/>,
  //   errorElement:<NotFound/>
  // },
  {
    path: "/home",
    element: <LoansList/>,
    errorElement:<NotFound/>
  },
  {
    path: "/loan/basic-details/:applicationId",
    element: <LoanBasicDetails/>,
    errorElement:<NotFound/>
  },
  {
    path: "/loan/address/:applicationId",
    element: <Addresses/>,
    errorElement:<NotFound/>
  },
  {
    path: "/loan/co-applicant/:applicationId",
    element: <CoApplicant/>,
    errorElement:<NotFound/>
  },
  {
    path: "/loan/reference/:applicationId",
    element: <Reference/>,
    errorElement:<NotFound/>
  },
  {
    path: "/loan/previous-loan/:applicationId",
    element: <PreviousLoan/>,
    errorElement:<NotFound/>
  },
  {
    path: "/loan/upload-loan-doc/:applicationId",
    element: <UploadDoc/>,
    errorElement:<NotFound/>
  },
  {
    path: "/error-401",
    element: <Home/>,
    errorElement:<NotFound/>
  },
  {
    path: "/error-404",
    element: <NotFoundDep/>, 
  },
  {
    path: "/view-Application/:applicationId?",
    element: <LoanView/>, 
  },
  {
    path: "/apply/credit-cards",
    element: <CreditCardList/>, 
  },
  {
    path: "/report/credit-cards",
    element: <CreditCardReport/>, 
  },
  {
    path: "/credit-cards-details/:productCode",
    element: <CreditCardDetails/>, 
  }
]);
export default Routes;
