/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useState, useReducer, useEffect, useMemo,useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { setFormData } from '../redux/formDataSlice';
import { setMasterData } from '../redux/masterDataSlice';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert'; 
import { Button, Input } from 'reactstrap'; 
import FormControl from '@mui/material/FormControl';
import useAxiosInstance from '../axiosi-ins';
import Loader from '../Loader'
import LoanProgress from './LoanProgress';
import { updateProgress } from '../redux/actions';
import { getItem, getItemSession, setItem } from '../helpers/Utils';
import TopButton from './BackButton';
import DataUContext from '../context/DataUContext';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const CoApplicant = React.memo(() => {
    const { applicationId } = useParams();
    const axios = useAxiosInstance();
    const dispatch = useDispatch();
    const history = useNavigate();
    // const dataU  = useContext(DataUContext);
    const dataU  = useSelector((state) => state.payload);  
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isSubmitted, isSubmitting },
        setValue,
        setError,
        getValues,
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onBlur',
        defaultValues: {
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "application_id": applicationId,
            "is_draft": 0,
            "name": "",
            "relationship": "",
            "email": "",
            "phone": "",
            "address_line1": "",
            "address_line2": "",
            "address_line3": "",
            "pincode": "",
            "state": "",
            "city": "",
            "state_name": "",
            "city_name": "",
            "landmark": "",
            "alternate_no": "",
            "occupation": "",
        }
    });
    const [state, setState] = React.useState({ 
        vertical: 'top',
        horizontal: 'right',
    });
    const [open, setOpen] = React.useState(false);
    const { vertical, horizontal } = state; 
    const handleClose = () => {
        setOpen(false) 
    };
    const [mastersList, setMastersList] = useState([]);
    const formData = useSelector((state) => state.formData.dataBasic);
    const [loading, setLoading] = useState(false);
    const [dataresponse, setdataresponse] = useState([]);
    const [snackBarOption, setSnackBarOption] = useState({
        message: null,
        type: 'E',
    });
    
    const getMasterList = () => {
        axios.get('form/masters')
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    setMastersList(res.data)
                    setLoading(true);
                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)
                }
            })
            .catch((err) => {
                setdataresponse([]);
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
                console.log(errorMessage);
            });
    };
    const handleChange1 = (event) => {
        const val = event.target.value
        const name = event.target.name
        if (val.length === 6) {
            axios.get(`pincodes-list?pincode=${val}`)
                .then((response) => {
                    const res = response.data;
                    if (res.success === true) {
                        const firstResult = res.data[0];
                        const city = firstResult.city_id;
                        const state = firstResult.state_id;
                        const cityName = firstResult.city;
                        const stateName = firstResult.state;
                        switch (name) {
                            case 'pincode':
                                setValue('state', state)
                                setValue('city', city)
                                setValue('state_name', stateName)
                                setValue('city_name', cityName)
                                break;
                            default:
                                break;
                        }
                    } else {
                        setSnackBarOption({
                            type: 'E',
                            message: res.message,
                        });
                        setOpen(true)
                    }
                })
                .catch((err) => {
                    setdataresponse([]);
                    let errorMessage = err.message;
                    if (err?.response?.data?.message) {
                        errorMessage = err.response.data.message;
                    }
                    setSnackBarOption({
                        type: 'E',
                        message: errorMessage,
                    });
                    setOpen(true)
                    console.log(errorMessage);
                });

        }
    };
    const handleSubmitDarft = () => {
        setValue('is_draft', 1)
        submitForm();
    };

    const submitForm = () => {
        axios.post(`/loan/saveCoApplicant`, getValues())
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    // setLoading(true);
                    setSnackBarOption({
                        type: 'S',
                        message: res.message,
                    });
                    setOpen(true)
                    setTimeout(() => {
                        history(`/loan/reference/` + res.data.application_id);
                    }, 3000);

                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)
                }
            })
            .catch((error) => {
                let errorMessage = error.message;
                if (error?.response?.data?.message) {
                    errorMessage = error.response.data.message;
                    const res = error.response.data
                    if (res.message === "VALIDATION_ERROR") {
                        Object.entries(res.data).map(([value, text]) => setError(value, { message: text }))
                    }
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
            });
    };


    useEffect(() => {
        dispatch(updateProgress(3))
        getMasterList();
        const sendData = {
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "application_id": applicationId
        }
        axios.get(`/loan/reportDetail?${queryString.stringify(sendData)}`)
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    if (res.data?.co_applicant !== null) {
                        Object.entries(res.data?.co_applicant).map(([value, text]) => {
                            setValue(value, text)
                            if (value === 'city') {
                                setValue(`city`, text?.id);
                                setValue(`city_name`, text?.name);
                            }
                            if (value === 'state') {
                                setValue(`state`, text?.id);
                                setValue(`state_name`, text?.name);
                            }
                            if (value === 'alternate_number') {
                                setValue(`alternate_no`, text);
                            }
                        })
                    }
                    setLoading(true);
                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)

                }

            })
            .catch((err) => {
                setdataresponse([]);
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
                console.log(errorMessage);
            });
            // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [applicationId, dispatch, setdataresponse,dataU ]);
    return (
        <div>
            {loading ? (
                <div className="App">
                    <div className='container width_1170'>
                        <div className='card'>
                            <div className='row'>
                                <TopButton />
                            </div>
                            <div className='row mt-4 mb-2 '>
                                <LoanProgress />
                            </div>
                            <form method="post" onSubmit={handleSubmit(submitForm)} className="mt-3 px-3 bit-1" id="checkEligibility_form">
                                <div className='row'>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="name"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="name"
                                                        label="Co-Applicant Name*"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('name', e.target.value);
                                                        }}
                                                        variant="outlined"
                                                        {...register('name', {
                                                            required: 'name is required',
                                                            minLength: {
                                                                value: 3,
                                                                message: 'Minimum 3 Characters Required for Name',
                                                            },
                                                            maxLength: {
                                                                value: 30,
                                                                message: 'Name was Too High',
                                                            },
                                                            pattern: {
                                                                value: /^[a-zA-z ]{3,30}$/,
                                                                message: 'Enter a valid Name',
                                                            },
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.name && <p className="error"> {errors.name.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3 mt-1'>
                                        <div className="mb-3 position-relative">
                                            <FormControl sx={{ width: '100%' }}   >
                                                <InputLabel id="relationship">Relationship with Applicant*</InputLabel>
                                                <Controller
                                                    name="relationship"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            required
                                                            labelId="relationship"
                                                            variant="outlined"
                                                            {...field}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue('relationship', e.target.value);
                                                            }}
                                                            {...register('relationship', {
                                                                required: 'Relationship is required',
                                                            })}
                                                        >
                                                            <MenuItem value="" disabled > <em>Select Relationship</em> </MenuItem>
                                                            {
                                                                mastersList?.filter(master => master.type === "relationship").map((value) => {
                                                                    return (
                                                                        <MenuItem key={value.value} value={value.label}>
                                                                            {value.label}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                            {errors.relationship && getValues('relationship') === "" && <p className="error"> {errors.relationship.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="email"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="email"
                                                        label="Email ID*"
                                                        variant="outlined" {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('email', e.target.value);
                                                        }}
                                                        {...register('email', {
                                                            required: 'Email is required',
                                                            pattern: {
                                                                value: /\S+@\S+\.\S+/,
                                                                message: 'Entered value does not match email format',
                                                            },
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.email && <p className="error"> {errors.email.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="phone"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="phone"
                                                        label="Mobile Number*"
                                                        variant="outlined"
                                                        type='number'
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('phone', e.target.value);
                                                        }}
                                                        {...register('phone', {
                                                            required: 'Mobile no is required',
                                                            pattern: {
                                                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                                                                message:
                                                                    'Entered value Mobile Number in valid format',
                                                            },
                                                            minLength: {
                                                                value: 10,
                                                                message: 'Enter the valid Phone Number',
                                                            },
                                                            maxLength: {
                                                                value: 10,
                                                                message: 'Enter the valid Phone Number',
                                                            },
                                                            type: Number,
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.phone && <p className="error"> {errors.phone.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="address_line1"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="address_line1"
                                                        label="Address Line 1*"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('address_line1', e.target.value);
                                                        }}
                                                        {...register('address_line1', {
                                                            required: 'Address Line 1 is required',
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.address_line1 && <p className="error"> {errors.address_line1.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="address_line2"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="address_line2"
                                                        label="Address Line 2*"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('address_line2', e.target.value);
                                                        }}
                                                        {...register('address_line2', {
                                                            required: 'Address Line 2 is required',
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.address_line2 && <p className="error"> {errors.address_line2.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="address_line3"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="address_line3"
                                                        label="Address Line 3"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('address_line3', e.target.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors.address_line3 && <p className="error"> {errors.address_line3.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="pincode"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="pincode"
                                                        label="Pincode*"
                                                        type='number'
                                                        onKeyUp={handleChange1}
                                                        variant="outlined" {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('pincode', e.target.value);
                                                        }}
                                                        {...register('pincode', {
                                                            required: 'Pin Code is required',
                                                            pattern: {
                                                                value: /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/,
                                                                message: 'Entered the valid Zipcode',
                                                            },
                                                            minLength: {
                                                                value: 6,
                                                                message: 'Zipcode should be 6 in length',
                                                            },
                                                            maxLength: {
                                                                value: 6,
                                                                message: 'Zipcode should be 6 in length',
                                                            },
                                                            type: Number,
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.pincode && <p className="error"> {errors.pincode.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="city_name"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="city_name"
                                                        label="City*"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('city_name', e.target.value);
                                                        }}

                                                    />
                                                )}
                                            />
                                            {errors.city_name && <p className="error"> {errors.city_name.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="state_name"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="state_name"
                                                        label="State*"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('state_name', e.target.value);
                                                        }}

                                                    />
                                                )}
                                            />
                                            {errors.state_name && <p className="error"> {errors.state_name.message}</p>}
                                        </div>
                                    </div>
                                    <Controller
                                        name="state"
                                        control={control}
                                        render={({ field }) => (
                                            <input type='hidden' {...field}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setValue('state', e.target.value);
                                                }} />
                                        )}
                                    />
                                    <Controller
                                        name="city"
                                        control={control}
                                        render={({ field }) => (
                                            <input type='hidden' {...field}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setValue('city', e.target.value);
                                                }} />
                                        )}
                                    />
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="landmark"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="landmark"
                                                        label="Landmark*"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('landmark', e.target.value);
                                                        }}
                                                        {...register('landmark', {
                                                            required: 'Landmark is required',
                                                        })}
                                                    />
                                                )}
                                            />

                                            {errors.landmark && <p className="error"> {errors.landmark.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="alternate_no"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="alternate_no"
                                                        label="Alternative Contact Number"
                                                        variant="outlined"
                                                        type='number'
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('alternate_no', e.target.value);
                                                        }}
                                                        {...register('alternate_no', {
                                                            pattern: {
                                                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                                                                message:
                                                                    'Entered value Phone Number in valid format',
                                                            },
                                                            minLength: {
                                                                value: 10,
                                                                message: 'Enter the valid Phone Number',
                                                            },
                                                            maxLength: {
                                                                value: 10,
                                                                message: 'Enter the valid Phone Number',
                                                            },
                                                            type: Number,
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.alternate_no && <p className="error"> {errors.alternate_no.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3 mt-1'>
                                        <div className="mb-3 position-relative">
                                            <FormControl sx={{ width: '100%' }}   >
                                                <InputLabel id="occupation">Occupation*</InputLabel>
                                                <Controller
                                                    name="occupation"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            required
                                                            labelId="occupation"
                                                            variant="outlined"
                                                            {...field}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue('occupation', e.target.value);
                                                            }}
                                                        {...register('occupation', {
                                                            required: 'Occupation is required',
                                                        })}
                                                        >
                                                            <MenuItem value="" disabled > <em>select Occupation</em> </MenuItem>
                                                            {
                                                                mastersList?.filter(master => master.type === "occupation").map((value) => {
                                                                    return (
                                                                        <MenuItem key={value.value} value={value.label}>
                                                                            {value.value}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                            {errors.occupation && getValues('occupation') === "" && <p className="error"> {errors.occupation.message}</p>}
                                        </div>
                                    </div>

                                </div>


                                <div className='btn-center'>

                                    <Button color="secondary" variant="contained" className=" ml-2 " onClick={() => history(`/loan/address/` + applicationId)} >
                                        Previous Page
                                    </Button>
                                    <Button color='warning' type="button" className='ml-2' onClick={handleSubmitDarft}>
                                        Save as Draft
                                    </Button>
                                    <Button type="button" color='primary' className='ml-2' onClick={handleSubmit(submitForm)} disabled={isSubmitting}>
                                        Submit
                                    </Button>
                                    <Button color="warning" variant="contained" className=" ml-2 " onClick={() => history(`/loan/reference/` + applicationId)} >
                                        Skip
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                    {snackBarOption.message ? (
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                            anchorOrigin={{ vertical, horizontal }}>
                            <Alert onClose={handleClose} severity={snackBarOption.type === 'E' ? "error" : 'success'} sx={{ width: '100%' }}>
                                {snackBarOption.message}
                            </Alert>
                        </Snackbar>) : null}
                </div>) : (<Loader />)}
        </div>
    )
});

export default CoApplicant;
