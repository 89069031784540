import React, { useState, useEffect, useRef } from 'react';

const LoaderInit = ({ progress = 0 }) => {
  // const mesg = "please-wait-while-we-are-fatching-details";
  // const mesg = "please wait while we are fatching details";
  // const [characters, setCharacters] = useState([]);
  const [fillProgress, setFillProgress] = useState(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    // const splitChars = mesg.split('');
    // const animatedChars = splitChars.map((char, index) => ({
    //   char,
    //   delay: (index + 1) * 0.3 + 1.2, // Start at 1.2s, increment by 0.1s
    // }));
    // setCharacters(animatedChars);
    const updateFillProgress = () => {
      setFillProgress(progress);
    };

    updateFillProgress();

    // Optional: Animate the fill progress smoothly
    const animationDuration = 0.5; // Customize duration (in seconds)
    const animationSteps = 100;

    if (progress !== fillProgress) {
      const step = (progress - fillProgress) / animationSteps;
      const animateFill = () => {
        setFillProgress((prevFillProgress) => Math.min(prevFillProgress + step, progress));

        if (fillProgress === progress) {
          clearInterval(intervalRef.current);
        }
      };

      // Start the interval within the useEffect hook
      intervalRef.current = setInterval(animateFill, animationDuration / animationSteps * 1000);
    }

    return () => clearInterval(intervalRef.current); // Clear interval when component unmounts
  }, [progress,fillProgress]);

  return (
    <div className="loader center loading-overlay loading_overlay">

      <div className='loader-main-container'>
        <div className='loader-message ' style={{ margin: '0 30%', marginBottom: '1.5rem' }}>
          <div className='loader-text'>
            {/*/characters.map((charObj, index) => (
              charObj.char !== '-' ?
              <span
                key={index}
                className={ charObj.char === '' ?' ': `let${index + 1}`}
                style={{
                  animation: 'drop 0.8s ease-in-out infinite',
                  animationDelay: `${charObj.delay}s`,
                }}
              >
                {charObj.char === '' ? '&Tab' : charObj.char}
              </span> : 
              <span
                key={index}  
              > {'&Tab '}
              </span>
            ))*/}
           {/* <span class="let1">l</span>
            <span class="let2">o</span>
            <span class="let3">a</span>
            <span class="let4">d</span>
            <span class="let5">i</span>
            <span class="let6">n</span>
            <span class="let7">g</span>*/}<span className="loader-text">Loading...</span> 
          </div>
        </div>
        <div className="loader-container">
          <div className="loader-bar" style={{ width: `${progress}%` }}></div>
          <span className="progress-text"><h1>{progress}%</h1></span>
        </div>
      </div>
    </div >
  );
};

export default LoaderInit;
