/* eslint-disable no-unused-vars */
import React from 'react';
import moment from 'moment';
import { Document, Page } from 'react-pdf';
import { read, utils } from 'react-excel-renderer';
import { Viewer } from 'react-doc-viewer';
// import { Image } from 'react-image';
import { isValidURL } from '../helpers/Utils';
import { cdnUrl } from '../constants/defaultValues'; 

function FileViewer({ item }) {
  const { file, doc_type } = item;

  if (!isValidURL(cdnUrl + file)) {
    return <div>{file}</div>;
  }

  if (file.endsWith('.jpg') || file.endsWith('.jpeg') || file.endsWith('.png')) {
    return <img src={cdnUrl + file} alt={doc_type} style={{width:'6.5rem',height:'6.5rem'}} />;
  } else if (file.endsWith('.pdf')) {
    return (
      <Document file={cdnUrl + file}
      style={{width:'6.5rem',height:'6.5rem'}}
       onLoadError={(error) => {
        console.error("PDF load error:", error);
      }} >
        <Page pageNumber={1} />
      </Document>
    );
  } else if (file.endsWith('.xls') || file.endsWith('.xlsx')) {
    return (
      <div style={{width:'6.5rem',height:'6.5rem'}}>
        {read(cdnUrl + file, { type: 'binary' }, (err, workbook) => {
          if (err) {
            console.error(err);
            return;
          }
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = utils.sheet_to_json(sheet, { header: 1 });
          console.log(jsonData);
        })}
      </div>
    );
  } else if (file.endsWith('.docx') || file.endsWith('.doc')) {
    return <Viewer fileType="docx" filePath={cdnUrl + file} style={{width:'6.5rem',height:'6.5rem'}} />;
  } else {
    return (
      <a href={cdnUrl + file} download target='_blank' rel="noreferrer">
        <p>{doc_type}</p>
      </a>
    );
  }
}

export default FileViewer;
