/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';
export const updateProgress = (progress) => {
  return {
    type: UPDATE_PROGRESS,
    payload: progress,
  };
};
export const UPDATE_PAYLOAD = 'UPDATE_PAYLOAD';
export const updatePayLoad = (payload) => {
  return {
    type: UPDATE_PAYLOAD,
    payload: payload,
  };
};
export const SET_LOAN_APPLICATION = 'SET_LOAN_APPLICATION';
export const updateLoanApplication = (loanApplication) => {
  return {
    type: SET_LOAN_APPLICATION,
    payload: loanApplication,
  };
};

// export * from './auth/actions';
export * from './settings/actions';

