/* eslint-disable no-unused-vars */
import React from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';




const LoanProgress = () => {
    const history = useNavigate();
    const { applicationId } = useParams();
    //   const { progress } = props;
    const progress = useSelector((state) => state.progress);
    const loanApplication = useSelector((state) => state.loanApplication);
    
    const handleNavigate = (url)=>{
        if (loanApplication) {
            history(`/loan/${url}/` + loanApplication.application_id);
        } else if(applicationId){
            history(`/loan/${url}/` + applicationId);
        }
    } 
    
    return (
        <>
            <ProgressBar percent={progress * 12.5 * 1.4}>
                {/* <Step className="p3">                                                   
          {({ accomplished, index }) => (
            <div
              className={`indexedStep ${accomplished ? 'accomplished' : null}`}
            >
              {accomplished ? <i className="fa fa-check" /> : index + 1}
            </div>
          )}
        </Step> */}
                <Step className="p3">
                    {({ accomplished }) => (
                        <div
                            className={`indexedStep ${accomplished ? 'accomplished' : null}`}
                        >
                            {accomplished ? (
                                <Button className="P0 pg_button" onClick={() => history(`/apply-loan/` + applicationId)}>
                                    <i className="fa fa-check pr-1" />
                                    Check Eligibility
                                </Button>
                            ) : (
                                <Button className="P0 pg_button" onClick={() => history(`/`)}>
                                    <i className="fas fa-sync-alt pr-1" />
                                    Check Eligibility
                                </Button>
                            )}
                        </div>
                    )}
                </Step>
                <Step>
                    {({ accomplished }) => (
                        <div
                            className={`indexedStep ${accomplished ? 'accomplished' : null}`}
                        >
                            {accomplished ? ( 
                                <Button className="P0 pg_button"  onClick={() => handleNavigate('basic-details')}>
                                    <i className="fa fa-check  pr-1" />
                                    Basic Details
                                </Button>
                            ) : (
                                <Button className="P0 pg_button"  onClick={() => handleNavigate('basic-details')}>
                                    <i className="fas fa-sync-alt pr-1" />
                                    Basic Details
                                </Button>
                            )}
                        </div>
                    )}
                </Step>
                <Step className="p3">
                    {({ accomplished }) => (
                        <div
                            className={`indexedStep ${accomplished ? 'accomplished' : null}`}
                        >
                            {accomplished ? ( 
                                <Button className="P0 pg_button" onClick={() => handleNavigate('address')}>
                                    <i className="fa fa-check pr-1" />
                                    Address
                                </Button>
                            ) : (
                                <Button className="P0 pg_button" onClick={() => handleNavigate('address')}>
                                    <i className="fas fa-sync-alt pr-1" />
                                    Address
                                </Button>
                            )}
                        </div>
                    )}
                </Step>
                <Step className="p3">
                    {({ accomplished }) => (
                        <div
                            className={`indexedStep ${accomplished ? 'accomplished' : null}`}
                        >
                            {accomplished ? ( 
                                <Button className="P0 pg_button" onClick={() => handleNavigate('co-applicant')}>
                                    <i className=" fa fa-check pr-1" />
                                    Co-Applicant
                                </Button>
                            ) : (
                                <Button className="P0 pg_button" onClick={() => handleNavigate('co-applicant')}>
                                    <i className="fas fa-sync-alt pr-1" />
                                    Co-Applicant
                                </Button>
                            )}
                        </div>
                    )}
                </Step>
                <Step className="p3">
                    {({ accomplished }) => (
                        <div
                            className={`indexedStep ${accomplished ? 'accomplished' : null}`}
                        >
                            {accomplished ? ( 
                                <Button className="P0 pg_button" onClick={() => handleNavigate('reference')}>
                                    <i className="fa fa-check  pr-1" />
                                    Reference
                                </Button>
                            ) : (
                                <Button className="P0 pg_button" onClick={() => handleNavigate('reference')}>
                                    <i className="fas fa-sync-alt pr-1" />
                                    Reference
                                </Button>
                            )}
                        </div>
                    )}
                </Step>
                <Step className="p3">
                    {({ accomplished }) => (
                        <div
                            className={`indexedStep ${accomplished ? 'accomplished' : null}`}
                        >
                            {accomplished ? ( 
                                <Button className="P0 pg_button" onClick={() => handleNavigate('previous-loan')}>
                                    <i className="pr-1 fa fa-check" />
                                    Previous Loan
                                </Button>
                            ) : (
                                <Button className="P0 pg_button" onClick={() => handleNavigate('previous-loan')}>
                                    <i className="fas fa-sync-alt pr-1" />
                                    Previous Loan
                                </Button>
                            )}
                        </div>
                    )}
                </Step>
                <Step className="p3">
                    {({ accomplished }) => (
                        <div
                            className={`indexedStep ${accomplished ? 'accomplished' : null}`}
                        >
                            {accomplished ? (
                                <Button className="P0 pg_button" onClick={() => handleNavigate('upload-loan-doc')}>
                                    <i className="fa fa-check pr-1" />
                                    Upload Documents
                                </Button> 
                            ) : (
                                <Button className="P0 pg_button" onClick={() => handleNavigate('upload-loan-doc')}>
                                    <i className="fas fa-sync-alt pr-1" />
                                    Upload Documents
                                </Button>
                            )}
                        </div>
                    )}
                </Step>
            </ProgressBar>
        </>
    );
};

export default LoanProgress;
