/* eslint-disable no-unused-vars */
import React, { Suspense } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouterProvider } from "react-router-dom";
import { IntlProvider } from 'react-intl';
import AppLocale from './lang';
import ColorSwitcher from './components/common/ColorSwitcher';
import { NotificationContainer } from './components/common/react-notifications';
import { isMultiColorActive } from './constants/defaultValues';
import { getDirection, setItem } from './helpers/Utils';
import Routes from './router/Routes';
import { updatePayLoad } from './redux/actions';
import DataUContext from './context/DataUContext';
class App extends React.Component {
  constructor(props) {
    super(props);
    const direction = getDirection();
    if (direction.isRtl) {
      document.body.classList.add('rtl');
      document.body.classList.remove('ltr');
    } else {
      document.body.classList.add('ltr');
      document.body.classList.remove('rtl');
    }
  }
  componentDidMount() {
    // window.addEventListener("message", (event) => {
    //   const Datas = event.data;
    //   // const { dispatch } = this.props; 
    //   // dispatch(updatePayLoad(Datas)); 
    //   // setItem('dependency',Datas)   
    //   // if (Datas && Object.keys(Datas).length > 0 && !Datas.hasOwnProperty('data')) { 
    //   //   setItem('apiKey',Datas?.apiKey )
    //   //   setItem('apiSecret',Datas?.apiSecret)
    //   //   setItem('refCode',Datas?.refCode )
    //   //   setItem('serverIp',Datas?.serverIp);
    //   //   setItem('source',Datas?.source);
    //   //   setItem('plugin_accessibility',Datas?.plugin_accessibility);
    //   //   setItem('services',Datas?.services || 'CRT');
    //   //   setItem('laptype',Datas?.laptype);
    //   //   setItem('editAppId', Datas?.editAppId);  
    //   //   setItem('device_id', Datas?.device_id);  
    //   //   setItem('other_info', Datas?.other_info);  
    //   //   // // session Stoge data set 
    //   //   sessionStorage.setItem('apiKey', Datas?.apiKey)
    //   //   sessionStorage.setItem('apiSecret', Datas?.apiSecret)
    //   //   sessionStorage.setItem('refCode', Datas?.refCode)
    //   //   sessionStorage.setItem('serverIp', Datas?.serverIp);
    //   //   sessionStorage.setItem('source', Datas?.source);
    //   //   sessionStorage.setItem('plugin_accessibility', Datas?.plugin_accessibility);
    //   //   sessionStorage.setItem('services',Datas?.services || 'CRT');
    //   //   sessionStorage.setItem('laptype', Datas?.laptype);
    //   //   sessionStorage.setItem('editAppId', Datas?.editAppId);
    //   //   sessionStorage.setItem('device_id', Datas?.device_id);
    //   //   sessionStorage.setItem('other_info', Datas?.other_info);

    //   // // local run data 

    //   const dataSring = JSON.stringify({ extra: "testData from local ", extraData: "YXOZF28823" })
    //   setItem('apiKey', Datas?.apiKey || 'f415d0dca75ea17bf0d433e5b9ab3360f0eaeb4262215d58091e873922fbff90')
    //   setItem('apiSecret', Datas?.apiSecret || 'bk33RsF+sE7gH1fdAGTz//ygdL/+ZFiceZvgh6JDL0NHoujGykx6bXjbvlO12H8ry7p53unewUSUK18XkeXT2ndokZE6so1y/uNDeynZthPqimdU9p/X9HokMMkdDFhwh3Z9EaFz5KsKphBP15KtbtOgmVxx0VF6kUrAiIUyaA3sjM9+TOQoWtU3ZM5aD8Z5')
    //   setItem('refCode', Datas?.refCode || 'CROKMH86790')//YXOZF28823
    //   setItem('serverIp', Datas?.serverIp || '10.0.2.224')
    //   setItem('source', 'web')
    //   setItem('services', Datas?.services || 'CRT')
    //   setItem('plugin_accessibility', Datas?.plugin_accessibility || 'Eliza')
    //   setItem('laptype', Datas?.laptype || 115)
    //   setItem('other_info', dataSring)
    //   // setItem('urlCallBack',Datas?.urlCallBack || 'http://financial.local/loan-application.php?ref=YXOZF28823&cid=2&ltype=2') 
    //   // setItem('editAppId',Datas?.editAppId || 'SLA00495') 
    //   // //session storage 
    //   sessionStorage.setItem('apiKey', Datas?.apiKey || 'f415d0dca75ea17bf0d433e5b9ab3360f0eaeb4262215d58091e873922fbff90')
    //   sessionStorage.setItem('apiSecret', Datas?.apiSecret || 'bk33RsF+sE7gH1fdAGTz//ygdL/+ZFiceZvgh6JDL0NHoujGykx6bXjbvlO12H8ry7p53unewUSUK18XkeXT2ndokZE6so1y/uNDeynZthPqimdU9p/X9HokMMkdDFhwh3Z9EaFz5KsKphBP15KtbtOgmVxx0VF6kUrAiIUyaA3sjM9+TOQoWtU3ZM5aD8Z5')
    //   sessionStorage.setItem('refCode', Datas?.refCode || 'CROKMH86790')//YXOZF28823
    //   sessionStorage.setItem('serverIp', Datas?.serverIp || '10.0.2.224')
    //   sessionStorage.setItem('source', 'web')
    //   sessionStorage.setItem('plugin_accessibility', Datas?.plugin_accessibility || 'Eliza')
    //   sessionStorage.setItem('services', Datas?.services || 'CRT')
    //   sessionStorage.setItem('laptype', Datas?.laptype || 115)
    //   sessionStorage.setItem('other_info', dataSring)
    //   // sessionStorage.setItem('urlCallBack',Datas?.urlCallBack || 'http://financial.local/loan-application.//php?ref=YXOZF28823&cid=2&ltype=2') 
    //   // // sessionStorage.setItem('editAppId',Datas?.editAppId || 'SLA00495') 
    //   // const { dispatch } = this.props; 
    //   // dispatch(updatePayLoad(Data)); 
    //   // } 
    // });

    // window.addEventListener('beforeunload', function(event) {
    //   localStorage.setItem('pageRefreshing', 'true');
    // });

    // window.addEventListener('load', function(event) {
    //   localStorage.removeItem('pageRefreshing');
    // });
    // window.addEventListener('unload', function(event) {
    //   if(localStorage.getItem('pageRefreshing') !== 'true'){
    //     localStorage.clear(); // Clear localStorage
    //   }
    // });
    // window.addEventListener('storage', function (event) {
    //   // if (event.key === 'token') {
    //   //   localStorage.removeItem('token');
    //   //   sessionStorage.removeItem('token');
    //   //   // window.location = '/';
    //   // }
    //   if (event.key === 'plugin_accessibility') {
    //     window.location.reload(false);
    //   }
    // });
  }

  render() {
    const { locale, dataU } = this.props;
    // console.log('dataU yyyyyy----------', dataU)
    const currentAppLocale = AppLocale[locale];

    return (
      <DataUContext.Provider value={dataU}>
        <div className="h-100">
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <div>
              <NotificationContainer />
              {isMultiColorActive && <ColorSwitcher />}
              <Suspense fallback={<div className="loading" />}>
                <RouterProvider router={Routes} />
              </Suspense>
            </div>
          </IntlProvider>
        </div>
      </DataUContext.Provider>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale} = settings;
  return { locale};
};
const mapActionsToProps = (state) => {
  // console.log('payload from App.js in mapActionsToProps', state.payload)
  return {
    payload: state.payload,
  };
};

export default connect(mapStateToProps, mapActionsToProps)(App);
