/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions*/
import React, { useState, useReducer, useEffect, useContext } from 'react';
import { useForm, Controller, get } from 'react-hook-form';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { setFormData } from '../redux/formDataSlice';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { Button, Input } from 'reactstrap';
import FormControl from '@mui/material/FormControl';
// import ReactDatePicker from 'react-datepicker';
import DatePicker from "react-datepicker";
import { useNavigate, Link, useParams } from 'react-router-dom';
import { balanceTransfer, cdnUrl, loanAgainstProperty, bissnessLoan, personalLoan } from '../constants/defaultValues';
import Loader from '../Loader'

import useAxiosInstance from '../axiosi-ins';
import "../App.css";
import LoanProgress from './LoanProgress';
import { updateProgress } from '../redux/actions';
import { currencyFormatter, getItem, getItemSession, removeItem, setItem } from '../helpers/Utils';
import TopButton from './BackButton';
import useLocalStorageChange from '../useLocalStorageChange';
import voiceAlert from '../VoiceAlert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import checkPng from '../assets/img/check.png';
import DataUContext from '../context/DataUContext';
// import BankGrids from './BankGrids';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function ConfirmationDialogRaw(props) {
    const { onClose, open, headerContent,
        modalContent, ...other } = props;
    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose();
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={open}
            {...other}
        >
            <DialogTitle>{headerContent}</DialogTitle>
            <DialogContent dividers>
                {modalContent}
            </DialogContent>
            {/*<DialogActions>
                <Button onClick={handleOk}>Ok</Button>
    </DialogActions>*/}
        </Dialog>
    );
}

function Home() {
    const axios = useAxiosInstance();
    const dispatch = useDispatch();
    // const dataU = useContext(DataUContext);
    const dataU = useSelector((state) => state.payload);
    // useLocalStorageChange();
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'right',
    });
    const [open, setOpen] = React.useState(false);
    const { vertical, horizontal } = state;
    const handleClose = () => {
        setOpen(false)
    };
    // const userData = getItem('token') !== null && getItem('token') !== undefined ? jwtDecode(getItem('token')) : { role: 0 }
    const userData = getItem('token') !== undefined && getItem('token') !== null ? jwtDecode(getItem('token')) : { role: 0 }
    // console.log('userData :>> ', userData);
    const { applicationId, loanTy } = useParams();
    const [utmUrl, setUtmUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showExisting, setShowExisting] = useState(false);
    const [showLap, setShowLap] = useState(false);
    const [showBuissness, setShowBuissness] = useState(false);
    const [showSalaried, setShowSalaried] = useState(false);
    const [showAllIncomeSource, setShowAllIncomeSource] = useState(false);
    const [selectedBanks, setSelectedBanks] = useState([]);
    const [selectedBanksPre, setSelectedBanksPre] = useState([]);
    const [org1, setOrg1] = useState();
    const [dataresponse, setdataresponse] = useState([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [reloadedForForm, setReloadedForForm] = useState(false);
    // const [dropList, setDropList] = useState([]);
    const [loanTypeValue, setLoanTypeValue] = useState();
    const [snackBarOption, setSnackBarOption] = useState({
        message: null,
        type: 'E',
    });
    const [modal, setModal] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(false);
    const toggleM = () => setModal(!modal);
    const [applicationIds, setApplicationIds] = useState(null);
    const [startDate, setStartDate] = useState();
    const [eligibleBanks, setEligibleBanks] = useState([]);
    const [recheckCount, setRecheckCount] = useState(0);
    const [chekEligTxt, setChekEligTxt] = useState('Check eligiblity');
    const [loanAmt, setLoanAmt] = useState(0);
    const [formDatas, setFormDatas] = useState({
        formData: null
    });
    const formData = useSelector((state) => state.formData.dataBasic);
    // console.log('formData :>> ', formData);

    const date = !startDate ? null : moment(startDate).format('DD/MM/YYYY');

    const history = useNavigate();
    const [visible, setVisible] = useState(true);
    const [copied, setcopied] = useState(false);
    const onDismiss = () => {
        setSnackBarOption({
            type: 'E',
            message: null,
        })
        setVisible(false)
    };
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        setError,
        trigger,
        setValue
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "other_info": dataU?.other_info,
            "device_id": dataU?.device_id,
            "loan_type_id": "",
            "name": "",
            "email": "",
            "mobile": "",
            "income_source": "",
            "income": "",
            "pincode": "",
            "dob": "",
            "pan_no": "",
            "aadhaar_no": "",
            "cibil_score": "",
            "loan_amount": "",
            "existing_loan_start_date": "",
            "property_age": ""
        }
    });
    const [pageData, setPageData] = useState({
        loanType: [],
        loan_type_id: false,
        loan_type_id_value: '',
    });

    const goBack = () => {
        toggleM()
        if (utmUrl) {
            window.open(utmUrl, '_blank');
        } else {
            history(`/loan/basic-details/` + applicationIds);
        }
    };
    const getMaxDate = () => {
        const currentDate = new Date();
        const maxDate = new Date(currentDate);
        maxDate?.setFullYear(currentDate.getFullYear() - 18); // 18 years ago
        return maxDate?.toISOString().split('T')[0];
    };
    const handleShowHide = (value) => {
        value = parseInt(value)
        if (value === balanceTransfer) {
            setShowExisting(true)
            setShowLap(false)
            setShowBuissness(false)
            setShowSalaried(false)
            setShowAllIncomeSource(true)
        } else if (value === loanAgainstProperty) {
            setShowLap(true)
            setShowExisting(false)
            setShowBuissness(false)
            setShowSalaried(false)
            setShowAllIncomeSource(true)
        }
        else if (value === bissnessLoan) {
            setShowBuissness(true)
            setShowSalaried(false)
            setShowAllIncomeSource(false)
        }
        else if (value === personalLoan) {
            setShowSalaried(true)
            setShowBuissness(false)
            setShowAllIncomeSource(false)
        } else {
            setShowLap(false)
            setShowExisting(false)
            setShowBuissness(false)
            setShowSalaried(false)
            setShowAllIncomeSource(true)
        }
    };

    const handleCheckboxChange = (e) => {
        const value = parseInt(e.target.value);
        if (e.target.checked) {
            setSelectedBanks((prevSelectedBanks) => [...prevSelectedBanks, value]);
        } else {
            setSelectedBanks((prevSelectedBanks) =>
                prevSelectedBanks.filter((bank) => bank !== value)
            );
        }
    };
    const randerData = (BankList) => {
        let itemList = [];
        let dataArray;
        if (Array.isArray(BankList.data)) {
            dataArray = BankList.data;
        } else if (typeof BankList.data === 'object' && BankList.data !== null) {
            dataArray = Object.values(BankList.data);
        } else {
            return itemList[0] = <div className='m-auto'><p className='text-danger'>No Banks Available </p></div>;
        }
        if (dataArray.length > 0) {
            dataArray.forEach((list) => {
                itemList.push(
                    <div key={list.id} className="col-md-12">
                        <div className="card-success m-1">
                            <div className="card-body text-success cardBody_elegibility">
                                <div className='row'>
                                    {list['type'] !== "UTM" ? <div className="col-md-4">
                                        <label className="bank_checkBox">
                                            <input type="checkbox" className="custom-control-input" name="preferred_banks[]"
                                                value={list.id}
                                                checked={selectedBanks.includes(parseInt(list.id)) || selectedBanksPre.includes(parseInt(list.id))}
                                                onChange={handleCheckboxChange} />
                                            <span className="custom-control-label custom-control-label-md  tx-15">{list['bank']}</span>

                                        </label></div> : null}
                                    {list['bank_logo'] ?
                                        <div className="col-md-2"><a className="eligibilty_logo_width" href="#">
                                            <img
                                                src={cdnUrl + 'evoluto/bank_logo/' + list['bank_logo']} className="" alt="logo" /></a></div>
                                        : null
                                    }
                                    {list['loan_amount'] ?
                                        <div className="col-md-2"><div className="tx-14 text-left text-muted loan_amount_col">
                                            Amount: {list['loan_amount']}
                                        </div> </div> : null}
                                    {list['tenure'] ? <div className="col-md-2"><div className="text-left text-muted tx-14 tenure_number">Tenure: {list['tenure']}</div></div> : null}

                                    {list['bank_interest_rate'] ? <div className="col-md-2"><div className="text-left text-muted tx-14 interest_rate">Interest rate: {list['bank_interest_rate']}</div></div> : null}

                                    {list['bank_description'] ? <div className="col-md-2"><div className="text-left text-muted tx-14 description_col">Description: {list['bank_description']}</div></div> : null}

                                    {list['bank_policy_doc'] ? <div className="col-md-2"><a className="eligibilty_readPolicy" href={cdnUrl + 'evoluto/bank_policy/' + list['bank_policy_doc']} target="_blank">Read Policy</a></div> : null}
                                    {list['type'] && list['type'] === "UTM" && list['utm_url'] && list['utm_url'] !== "" ? <div className="col-md-2"><button className="eligibilty_readPolicy" style={{ border: 'none' }} onClick={() => { submitViatAppyNow(list['utm_url'], list['id']) }} >Apply</button></div> : null}
                                </div></div>
                            {/*list['type'] === 'API' ?
                                <div className="card-footer" align="center">
                                    <a href="javascript:void(0)">
                                        <button data-url="#"
                                            data-id="{{ $list['id'] }}" type="button" className="btn btn-sm btn-success ipl-apply">Apply</button>
                                    </a>
                            </div> : null*/}

                        </div>

                    </div>
                )
            });

        }
        return itemList;
    }
    const submitViatAppyNow = (url, id) => {
        // setSelectedBanks((prevSelectedBanks) =>
        //     prevSelectedBanks.filter((bank) => bank !== id)
        // );
        setSelectedBanks((prevSelectedBanks) => [...prevSelectedBanks, id]);
        setUtmUrl(url);
        handleSubmitPreBank();
    }
    const handleSubmitPreBank = () => {

        let formDatass = formDatas.formData;
        console.log('formDatass', formDatass)
        const selBank = selectedBanks.concat(selectedBanksPre);
        const uniqueBanksArray = [...new Set(selBank)];
        if (uniqueBanksArray.length > 0) {
            setSubmitDisable(true);
            formDatass = {
                ...formDatass,
                preferred_banks: uniqueBanksArray.toString(),
                aadhaar: formDatass.aadhaar_no,
                pan: formDatass.pan_no,
                monthly_income: formDatass.income,
                application_id: applicationIds ?? formDatass.application_id,
            }
            const updatedData = {
                data: formDatass,
            };
            dispatch(setFormData(updatedData));

            axios.post(`/loan/storeBasicDetails`, formDatass)
                .then((response) => {
                    const res = response.data;
                    if (res.success === true) {
                        // console.log('res.data :>> ', res.data);
                        setSnackBarOption({
                            type: 'S',
                            message: res.message + ' your application ID is ' + res.data.application_id,
                        });
                        toggleM()
                        setApplicationIds(res.data.application_id)
                        setValue('application_id', res.data.application_id)
                        setLoanAmt(parseInt(getValues('loan_amount')))
                        // voiceAlert(res.message + ' your application ID is ' + res.data.application_id)
                        setState({ vertical: 'bottom', horizontal: 'center' });
                        setOpen(true)
                        // setTimeout(() => {
                        //     history(`/loan/basic-details/` + res.data.application_id);
                        // }, 2500);

                    } else {
                        setSnackBarOption({
                            type: 'E',
                            message: res.message,
                        });
                        setOpen(true)

                    }
                    // setFormDatas({
                    //     formData: null
                    // });

                    setTimeout(() => {
                        setSnackBarOption({
                            type: 'E',
                            message: null,
                        });
                    }, 10000);
                })
                .catch((error) => {
                    setSubmitDisable(false)
                    let errorMessage = error.message;
                    if (error?.response?.data?.message) {
                        errorMessage = error.response.data.message;
                    }
                    setSnackBarOption({
                        type: 'E',
                        message: errorMessage,
                    });
                    setOpen(true)
                });
        } else {
            setSnackBarOption({
                type: 'E',
                message: " Please Select Banks !!",
            });
            setOpen(true)
        }

    };
    const getLoanType = async () => {
        if (getItem('token')) {
            axios.get(`loan/getLoanTypes`, { params: { ref_code: dataU?.refCode || getItemSession('refCode') } }).then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setPageData((prevState) => {
                        return {
                            ...prevState,
                            loanType: data.data
                        };
                    });
                    setLoading(true);
                }
            })
                .catch((err) => {
                    // setPageData((prevState) => {
                    //     return {
                    //         ...prevState,
                    //         loanType: []
                    //     };
                    // });
                    let errorMessage = err.message;
                    if (err?.response?.data?.message) {
                        errorMessage = err.response.data.message;
                    }
                    setSnackBarOption({
                        type: 'E',
                        message: errorMessage,
                    });
                    // window.location.reload(false);
                    // window.location.href = location.href
                    console.log(errorMessage);
                });
        } else {

            axios.get(`loan/getLoanTypesPlugin`, { params: { ref_code: dataU?.refCode || getItemSession('refCode') } }).then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setPageData((prevState) => {
                        return {
                            ...prevState,
                            loanType: data.data
                        };
                    });
                    setLoading(true);
                }
            }).catch((err) => {
                // setPageData((prevState) => {
                //     return {
                //         ...prevState,
                //         loanType: []
                //     };
                // });                    
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                // window.location.reload(false);
                // window.location.href = location.href
                console.log(errorMessage);
                getLoanType();
            });
        }
    };
    const checkCreditScore = async () => {
        const isValidationSuccessful = await trigger(["name", "mobile", "pan_no", "dob"]);
        // Object.keys(getValues()).forEach((key) => { 
        //     if (getValues(key) === "") {
        //       switch (key) {
        //         case "name":
        //           errors[key] =setError(key, { message: "Name as per PAN Required" });
        //           break;
        //         case "mobile":
        //           setError(key, { message: "Mobile Number Required" });
        //           break;
        //         case "pan_no":
        //           setError(key, { message: "Pan no Required" });
        //           break;
        //         case "dob":
        //           setError(key, { message: "Date Of Birth Required" });
        //           break; 
        //         default:
        //           break; 
        //       }
        //     }
        //   });
        //   console.log('Object.keys(errors).length', Object.keys(errors).length)
        //   if (Object.keys(errors).length <= 0) {
        if (isValidationSuccessful) {
            const names = getValues('name').split(" ");
            const sendData = {
                "ref_code": dataU?.refCode || getItemSession('refCode'),
                "fname": names[0],
                "lname": names[1],
                "phone": getValues('mobile'),
                "pan_no": getValues('pan_no'),
                "dob": getValues('dob')
            }
            axios.post(`/loan/checkCreditScore`, sendData)
                .then((response) => {
                    const { status, data, message } = response;
                    if (status === 200) {
                        setValue('cibil_score', data.data.score)
                        setLoading(true);
                    } else {
                        setSnackBarOption({
                            type: 'E',
                            message: response,
                        });
                        setOpen(true)
                    }
                })
                .catch((err) => {
                    console.log('err :>> ', err);
                    let errorMessage = err.message;
                    if (err?.response?.data?.message) {
                        errorMessage = err.response.data.message;
                    }
                    errorMessage = typeof errorMessage === 'string' ? errorMessage : 'An error occurred';
                    if (err.response.data?.data?.CCRResponse?.CIRReportDataLst[0]?.Error) {
                        errorMessage = err.response.data?.data?.CCRResponse?.CIRReportDataLst[0]?.Error?.ErrorDesc
                    }
                    setSnackBarOption({
                        type: 'E',
                        message: errorMessage,
                    });
                    setOpen(true)
                    console.log(errorMessage);
                });
        } else {
            // If validation fails, log the errors
            console.log('Validation errors:', errors);
            Object.keys(errors).forEach(key => {
                console.log(`Field: ${key}, Message: ${errors[key]?.message}`);
            });
        }
    };

    const submitForm = (dd) => {
        setLoading(false)
        setChekEligTxt('checking....')
        axios.post(`/loan/checkEligibility`, getValues())
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    setChekEligTxt('re-check');
                    setSelectedBanks([]);
                    setEligibleBanks([]);
                    setdataresponse(res)
                    setEligibleBanks(res.data);
                    // console.log('selectedBanskPre :>> ', selectedBanksPre);
                    // console.log('selectedBanks :>> ', selectedBanks);
                    // setSelectedBanks([...selectedBanks, selectedBanksPre]);
                    setLoading(true);
                    setFormDatas({
                        formData: getValues()
                    });
                    setSnackBarOption({
                        type: 'S',
                        message: res.message,
                    });
                    setOpen(true)
                    // voiceAlert(res.message)

                } else {
                    setSelectedBanks([]);
                    setEligibleBanks([]);
                    setLoading(true);
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)
                    setChekEligTxt('re-check');
                }
            })
            .catch((error) => {
                setSelectedBanks([]);
                setEligibleBanks([]);
                let errorMessage = error.message;
                if (error?.response?.data?.message) {
                    errorMessage = error.response.data.message;
                    const res = error.response.data
                    if (res.message === "VALIDATION_ERROR") {
                        Object.entries(res.data).map(([value, text]) => setError(value, { message: text }))
                    }
                }
                console.log(errorMessage);

                setLoading(true);
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
                setChekEligTxt('re-check');
            });
    };
    const recheckIdAndSubmit = async (ApId, ty = null) => {
        const sendData = {
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "application_id": ApId
        }
        axios.get(`/loan/reportDetail?${queryString.stringify(sendData)}`)
            .then((response) => {
                const res = response.data;
                if (res.success === true) {

                    const promises = Object.entries(res.data).map(([value, text]) => {
                        setValue(value, text)
                        if (value === "aadhaar") {
                            setValue('aadhaar_no', text)
                        }
                        if (value === "pan") {
                            setValue('pan_no', text)
                        }
                        if (value === "monthly_income") {
                            setValue('income', text)
                        }
                        if (value === "loan_amount") {
                            setLoanAmt(text)
                        }
                        if (value === "preferred_banks") {
                            const preferredBanksArray = text?.split(',').map(Number);
                            const uniqueBanksArray = [...new Set(preferredBanksArray)];
                            setSelectedBanks(uniqueBanksArray);
                            setSelectedBanksPre(uniqueBanksArray);
                        }

                        return new Promise(resolve => {
                            setValue(value, text, { shouldDirty: true });
                            resolve();
                        });

                    })
                    Promise.all(promises).then(() => {
                        handleShowHide(getValues('loan_type_id'));
                        if (!isFormSubmitted) {
                            submitForm();
                            setIsFormSubmitted(true);
                        }
                        setLoading(true);
                    });
                    setLoading(true);
                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)

                }

            })
            .catch((err) => {
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                // window.location.reload(true);
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
                console.log(errorMessage);
            });
    }
    const handleKeyDown = (event) => {
        event.preventDefault();

        const inputBox = event.target;
        const currentValue = inputBox.value;

        if (event.key === 'Backspace') {
            inputBox.value = currentValue.substring(0, currentValue.length - 1);
        }

        if (event.key.length === 1) {
            const currentLength = currentValue.length;
            const alphaValid = currentLength < 5 || currentLength === 9;
            const numericValid = currentLength >= 5 && currentLength < 9;

            if (alphaValid && event.key >= 'a' && event.key <= 'z' && !event.ctrlKey) {
                inputBox.value += event.key.toUpperCase();
            } else if (alphaValid && event.key >= 'A' && event.key <= 'Z' && !event.ctrlKey) {
                inputBox.value += event.key;
            } else if (numericValid && event.key >= '0' && event.key <= '9' && !event.ctrlKey) {
                inputBox.value += event.key;
            }
        }
        setValue('pan_no', event.target.value);
    };
    useEffect(() => {
        dispatch(updateProgress(0))
        // console.log('payload from use selector  ', payload)
        // console.log('dataU from use context  ', dataU)
        setLoading(false)
        const beawaited = async () => {
            await getLoanType();
            setLoading(true);
            if (applicationId !== undefined) {
                await recheckIdAndSubmit(applicationId);
            } else if (dataU?.editAppId !== null && dataU?.editAppId !== undefined) {
                await recheckIdAndSubmit(dataU?.editAppId, 'edit');
            } else {
                setValue('loan_type_id', dataU?.laptype || getItemSession('laptype'))
                handleShowHide(dataU?.laptype || getItemSession('laptype'));
            }
        }
        beawaited();
        // if (userData?.role === 16) {
        //     history(`/home`)
        // }

    }, [dispatch, dataU?.laptype || getItemSession('laptype'), dataU, getItemSession('credit_score_permission')]);

    return (
        <div>
            {loading ? (<div className="App">
                <div className='container width_1170'>
                    <div className='card'>
                        <div className='row'>
                            {dataU?.editAppId ? <div className='all_aplied_backBTN'>
                                <Button color='info' onClick={() => history('/view-Application/' + dataU?.editAppId)}>
                                    View Application
                                </Button> </div> : null}
                            <TopButton />
                        </div>
                        <div className='row mt-4 mb-2 '>
                            <LoanProgress />
                        </div>
                        <form method="post" onSubmit={handleSubmit(submitForm)} className="mt-3 px-3 bit-1" id="checkEligibility_form">
                            <div className='row'>
                                <div className='col-lg-3'>
                                    <div className="mb-5 position-relative">
                                        <FormControl sx={{ width: '100%' }}   >
                                            <InputLabel id="loanTypeValue">Select Loan Type*</InputLabel>
                                            <Controller
                                                name="loan_type_id"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        required
                                                        labelId="loan_type_id"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('loan_type_id', e.target.value);
                                                            handleShowHide(e.target.value)
                                                        }}
                                                    // {...register('loan_type_id', {
                                                    //     required: 'Loan Type is required',
                                                    // })}
                                                    >
                                                        <MenuItem value="" disabled > <em>Select Loan Type</em> </MenuItem>
                                                        {
                                                            pageData?.loanType?.map((value) => {
                                                                return (
                                                                    <MenuItem key={value.id} value={value.id}>
                                                                        {value.name}
                                                                    </MenuItem>
                                                                );
                                                            })
                                                        }
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                        {errors.loan_type_id && getValues('loan_type_id') === "" && <p className="error"> {errors.loan_type_id.message}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="mb-5 position-relative">
                                        <Controller
                                            name="name"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    id="name"
                                                    label="Name*"
                                                    variant="outlined"
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue('name', e.target.value);
                                                    }}
                                                    {...register('name', {
                                                        required: 'Name as per PAN is required',
                                                        minLength: {
                                                            value: 3,
                                                            message: 'Minimum 3 Characters Required for Name',
                                                        },
                                                        maxLength: {
                                                            value: 30,
                                                            message: 'Name was Too High',
                                                        },
                                                        pattern: {
                                                            value: /^[a-zA-z ]{3,30}$/,
                                                            message: 'Enter a valid Name',
                                                        },
                                                    })}
                                                />
                                            )}
                                        />
                                        {errors.name && <p className="error"> {errors.name.message}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="mb-5 position-relative">
                                        <Controller
                                            name="email"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    id="email"
                                                    label="Email*"
                                                    variant="outlined"
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue('email', e.target.value);
                                                    }}
                                                    {...register('email', {
                                                        required: 'Email is required',
                                                        pattern: {
                                                            value: /\S+@\S+\.\S+/,
                                                            message: 'Entered value does not match email format',
                                                        },
                                                    })}
                                                />
                                            )}
                                        />
                                        {errors.email && <p className="error"> {errors.email.message}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="mb-5 position-relative">
                                        <Controller
                                            name="mobile"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    id="mobile"
                                                    label="Mobile*"
                                                    variant="outlined"
                                                    type='number'
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue('mobile', e.target.value);
                                                    }}
                                                    {...register('mobile', {
                                                        required: 'Mobile no is required',
                                                        pattern: {
                                                            value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                                                            message:
                                                                'Entered value Mobile Number in valid format',
                                                        },
                                                        minLength: {
                                                            value: 10,
                                                            message: 'Enter the valid Phone Number',
                                                        },
                                                        maxLength: {
                                                            value: 10,
                                                            message: 'Enter the valid Phone Number',
                                                        },
                                                        type: Number,
                                                    })}
                                                />
                                            )}
                                        />
                                        {errors.mobile && <p className="error"> {errors.mobile.message}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="mb-5 position-relative">
                                        <FormControl sx={{ width: '100%' }}   >
                                            <InputLabel id="income_source">Income Source*</InputLabel>
                                            <Controller
                                                name="income_source"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        fullWidth
                                                        labelId="income_source"
                                                        id="income_source"
                                                        label="Income Source"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('income_source', e.target.value);
                                                        }}
                                                        {...register('income_source', {
                                                            required: 'Income Source is required',
                                                        })}
                                                    >
                                                        <MenuItem value="" disabled > <em>Income Source</em> </MenuItem>
                                                        {showBuissness && <MenuItem value={2}>Business</MenuItem>}
                                                        {showSalaried ?
                                                            <MenuItem value={1}>Salaried</MenuItem>
                                                            : null}
                                                        {showSalaried ?
                                                            <MenuItem value={3}>Others</MenuItem>
                                                            : null}
                                                        {showAllIncomeSource ?
                                                            <MenuItem value={1}>Salaried</MenuItem> : null}
                                                        {showAllIncomeSource ?
                                                            <MenuItem value={2}>Business</MenuItem> : null}
                                                        {showAllIncomeSource ?
                                                            <MenuItem value={3}>Others</MenuItem> : null}
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                        {errors.income_source && getValues('income_source') === '' && <p className="error"> {errors.income_source.message}</p>}
                                    </div>
                                </div>

                                <div className='col-lg-3'>
                                    <div className="mb-5 position-relative">
                                        <Controller
                                            name="income"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    id="income"
                                                    label="Monthly Income*"
                                                    type='number'
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue('income', e.target.value);
                                                    }}
                                                    variant="outlined"
                                                    {...register('income', {
                                                        required: 'Monthly Income is required',
                                                        validate: {
                                                            min: (value) => Number(value) >= 15000 || 'Minimum Monthly Income is 15000',
                                                        },
                                                        type: Number,
                                                    })}
                                                />
                                            )}
                                        />
                                        {errors.income && <p className="error"> {errors.income.message}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="mb-5 position-relative">
                                        <Controller
                                            name="pincode"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    id="pincode"
                                                    label="Pin Code*"
                                                    type='number'
                                                    variant="outlined"
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue('pincode', e.target.value);
                                                    }}
                                                    {...register('pincode', {
                                                        required: 'Pin Code is required',
                                                        pattern: {
                                                            value: /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/,
                                                            message: 'Entered the valid Zipcode',
                                                        },
                                                        minLength: {
                                                            value: 6,
                                                            message: 'Zipcode should be 6 in length',
                                                        },
                                                        maxLength: {
                                                            value: 6,
                                                            message: 'Zipcode should be 6 in length',
                                                        },
                                                        type: Number,
                                                    })}
                                                />
                                            )}
                                        />
                                        {errors.pincode && <p className="error"> {errors.pincode.message}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="mb-5 position-relative">
                                        {/*  <DatePicker 
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                />
                               <ReactDatePicker
                                selected={startDate}
                                onChange={setStartDate}
                                placeholderText="Date of Birth"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                minDate={moment().subtract(100, 'years').toDate()}
                                dropdownMode="select"
                                maxDate={moment().toDate()}
                                showDisabledMonthNavigation
                                includeDateIntervals={[
                                    {
                                    start: moment().subtract(100, 'years').toDate(),
                                    end: moment().toDate(),
                                    },
                                ]}
                                /> */}
                                        <Controller
                                            name="dob"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    type='date'
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    id="dob"
                                                    label="Date Of Birth*"
                                                    variant="outlined"
                                                    max={getMaxDate()}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue('dob', e.target.value);
                                                    }}
                                                    {...register('dob', {
                                                        required: 'Date Of Birth is required',
                                                        validate: (value) => {
                                                            if (value) {
                                                                const dobDate = new Date(value);
                                                                const currentDate = new Date();
                                                                const minDate = new Date(currentDate);
                                                                minDate.setFullYear(currentDate.getFullYear() - 18);

                                                                if (dobDate > minDate) {
                                                                    return 'You must be at least 18 years old.';
                                                                }
                                                            }
                                                            return true;
                                                        }
                                                    })}
                                                />
                                            )}
                                        />
                                        {errors.dob && <p className="error"> {errors.dob.message}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="mb-5 position-relative">
                                        <Controller
                                            name="pan_no"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    id="pan_no"
                                                    label="Pan Card*"
                                                    variant="outlined"
                                                    onKeyDown={handleKeyDown}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue('pan_no', e.target.value);
                                                    }}
                                                    InputProps={{
                                                        inputProps: {
                                                            pattern: '[A-Za-z]{5}[0-9]{4}[A-Za-z]',
                                                        },
                                                    }}
                                                    {...register('pan_no', {
                                                        required: 'Pan Card is required',
                                                        maxLength: {
                                                            value: 10,
                                                            message: 'Name was Too High',
                                                        },
                                                        pattern: {
                                                            value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                                                            message: 'Enter a valid PAN Card (e.g., XXXXX1234X)',
                                                        },
                                                    })}
                                                />
                                            )}
                                        />
                                        {errors.pan_no && <p className="error"> {errors.pan_no.message}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="mb-5 position-relative">
                                        <Controller
                                            name="aadhaar_no"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    type='number'
                                                    id="aadhaar_no"
                                                    label="Aadhaar Card No.*"
                                                    variant="outlined"
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue('aadhaar_no', e.target.value);
                                                    }}
                                                    {...register('aadhaar_no', {
                                                        required: 'Aadhaar number is required',
                                                        minLength: {
                                                            value: 12,
                                                            message: 'Minimum 12 Characters Required for Aadhaar card',
                                                        },
                                                        maxLength: {
                                                            value: 12,
                                                            message: '12 Characters Required for Aadhaar card',
                                                        },
                                                        type: Number,
                                                    })}
                                                />
                                            )}
                                        />
                                        {errors.aadhaar_no && <p className="error"> {errors.aadhaar_no.message}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="mb-5 position-relative">
                                        <Controller
                                            name="loan_amount"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    type='number'
                                                    id="loan_amount"
                                                    label="Loan Amount*"
                                                    variant="outlined"
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue('loan_amount', e.target.value);
                                                    }}
                                                    {...register('loan_amount', {
                                                        required: 'Loan Amount is required',
                                                        validate: {
                                                            min: (value) => Number(value) >= 15000 || 'Minimum value is 15000',
                                                            max: (value) => Number(value) <= 50000000 || 'Maximum value is 5 crores',
                                                        },
                                                        type: Number,
                                                    })}
                                                />
                                            )}
                                        />
                                        {errors.loan_amount && <p className="error"> {errors.loan_amount.message}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="mb-5 position-relative check_credit_score">
                                        <Controller
                                            name="cibil_score"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    type='number'
                                                    id="cibil_score"
                                                    label="Credit Score*"
                                                    variant="outlined"
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue('cibil_score', e.target.value);
                                                    }}
                                                    {...register('cibil_score', {
                                                        required: 'Credit Score is required',
                                                        validate: {
                                                            min: (value) => Number(value) >= 300 || 'Minimum value is 300',
                                                            max: (value) => Number(value) <= 900 || 'Maximum value is 900 ',
                                                        },
                                                        type: Number,
                                                    })}
                                                />
                                            )}
                                        />
                                        {errors.cibil_score && <p className="error"> {errors.cibil_score.message}</p>}
                                        {getItemSession('credit_score_permission') && getItemSession('credit_score_permission') !== undefined && getItemSession('credit_score_permission') !== null && parseInt(getItemSession('credit_score_permission')) === 1 ?
                                            <Button className="check_credit_score_btn1" onClick={(event) => {
                                                event.preventDefault(); // Prevent default action
                                                checkCreditScore();
                                            }}>
                                                Check Credit Score
                                            </Button> : null}
                                    </div>
                                </div>
                                {showExisting ? <div className='col-lg-3'>
                                    <div className="mb-5 position-relative">
                                        <Controller
                                            name="existing_loan_start_date"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    type='date'
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    id="existing_loan_start_date"
                                                    label="Existing Loan Start Date*"
                                                    variant="outlined"
                                                    max={getMaxDate()}
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue('existing_loan_start_date', e.target.value);
                                                    }}
                                                    {...register('existing_loan_start_date', {
                                                        required: 'Existing Loan Start Date is required',
                                                    })}
                                                />
                                            )}
                                        />
                                        {errors.existing_loan_start_date && <p className="error"> {errors.existing_loan_start_date.message}</p>}
                                    </div>
                                </div> : null}
                                {showLap ? <div className='col-lg-3'>
                                    <div className="mb-5 position-relative">
                                        <Controller
                                            name="property_age"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    id="property_age"
                                                    label="Property Age*"
                                                    type='number'
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue('property_age', e.target.value);
                                                    }}
                                                    variant="outlined"
                                                    {...register('property_age', {
                                                        required: 'Property Age is required',
                                                        minLength: {
                                                            value: 0,
                                                            message: 'Enter the valid Property Age',
                                                        },
                                                        maxLength: {
                                                            value: 10,
                                                            message: 'Enter the valid Property Age',
                                                        },
                                                        type: Number,
                                                    })}
                                                />
                                            )}
                                        />
                                        {errors.property_age && <p className="error"> {errors.property_age.message}</p>}
                                    </div>
                                </div> : null}
                            </div>

                            <div className='btn-center checkEligibilitySubmit'>
                                <Button type="submit" disabled={chekEligTxt === 'checking....' ? true : false} className="btn btn-success" >
                                    {chekEligTxt}
                                </Button>
                            </div>
                        </form>
                        <div>

                            {eligibleBanks.length > 0 ?
                                <>
                                    <div className='row'>
                                        {randerData(dataresponse)}
                                        {/* <BankGrids BankList={dataresponse}  callBacks={callBackData} /> */}
                                    </div>
                                    <div allign='center' className='mt-2 btn-center checkEligibilitySubmit'>
                                        <Button color="success" disabled={submitDisable} className='ml-2' onClick={() => { handleSubmitPreBank() }}>
                                            Submit
                                        </Button>
                                    </div>
                                </>
                                : null}
                            <ConfirmationDialogRaw
                                id="ringtone-menu"
                                keepMounted
                                open={modal}
                                onClose={goBack}
                                headerContent={
                                    <div className="p-dialog-header d-flex justify-content-between" data-pc-section="header"><div id="pr_id_131_header" className="p-dialog-title" data-pc-section="headertitle">Successfully Submitted</div><div className="p-dialog-header-icons" data-pc-section="headericons"><button type="button" onClick={() => { goBack() }} className="p-dialog-header-icon p-dialog-header-close p-link" aria-label="Close" data-pc-section="closebutton"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="p-icon p-dialog-header-close-icon" aria-hidden="true" data-pc-section="closebuttonicon"><path d="M8.01186 7.00933L12.27 2.75116C12.341 2.68501 12.398 2.60524 12.4375 2.51661C12.4769 2.42798 12.4982 2.3323 12.4999 2.23529C12.5016 2.13827 12.4838 2.0419 12.4474 1.95194C12.4111 1.86197 12.357 1.78024 12.2884 1.71163C12.2198 1.64302 12.138 1.58893 12.0481 1.55259C11.9581 1.51625 11.8617 1.4984 11.7647 1.50011C11.6677 1.50182 11.572 1.52306 11.4834 1.56255C11.3948 1.60204 11.315 1.65898 11.2488 1.72997L6.99067 5.98814L2.7325 1.72997C2.59553 1.60234 2.41437 1.53286 2.22718 1.53616C2.03999 1.53946 1.8614 1.61529 1.72901 1.74767C1.59663 1.88006 1.5208 2.05865 1.5175 2.24584C1.5142 2.43303 1.58368 2.61419 1.71131 2.75116L5.96948 7.00933L1.71131 11.2675C1.576 11.403 1.5 11.5866 1.5 11.7781C1.5 11.9696 1.576 12.1532 1.71131 12.2887C1.84679 12.424 2.03043 12.5 2.2219 12.5C2.41338 12.5 2.59702 12.424 2.7325 12.2887L6.99067 8.03052L11.2488 12.2887C11.3843 12.424 11.568 12.5 11.7594 12.5C11.9509 12.5 12.1346 12.424 12.27 12.2887C12.4053 12.1532 12.4813 11.9696 12.4813 11.7781C12.4813 11.5866 12.4053 11.403 12.27 11.2675L8.01186 7.00933Z" fill="currentColor"></path></svg><span role="presentation" aria-hidden="true" className="p-ink" data-pc-name="ripple" data-pc-section="root" style={{ "height": "19.6px", "width": "19.6px", "top": " 4.75px", "left": "1px" }}></span></button></div></div>
                                }
                                modalContent={
                                    <>
                                        <div className="application_status">
                                            <img src={checkPng} width="40" alt='Success' />
                                            <p> Your Application ID has been generated successfully : {applicationIds} <br /> Please copy this Application ID for future reference</p>
                                            <span>Application id : {applicationIds}</span>
                                            <span>Loan Amount: {currencyFormatter(loanAmt)}</span>
                                            <div className="anchor_btn"> <></>
                                                <CopyToClipboard text={'Application Id for Your Applied Loan is : ' + applicationIds} onCopy={() => {
                                                    setcopied(true);
                                                    setTimeout(() => {
                                                        goBack();
                                                    }, 2000);
                                                }} >
                                                    <button className='bt' >
                                                        {copied ? 'Copied' : 'Copy Application ID'}
                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </>
                                }
                            />
                            {snackBarOption.message && !modal ? (
                                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                                    anchorOrigin={{ vertical, horizontal }}>
                                    <Alert onClose={handleClose} severity={snackBarOption.type === 'E' ? "error" : 'success'} sx={{ width: '100%' }}>
                                        {snackBarOption.message}
                                    </Alert>
                                </Snackbar>

                            ) : null}
                        </div>
                    </div>
                </div>
            </div>) : (<Loader />)}
        </div>
    );
}

export default Home;
// "husky": {
//     "hooks": {
//       "pre-commit": "lint-staged"
//     }
//   },
//   "lint-staged": {
//     "src/.js": [
//       "eslint --fix",
//       "git add"
//     ]
//   },
// "devDependencies": {
//     "@babel/core": "^7.23.2",
//     "@babel/preset-env": "^7.23.2",
//     "babel-eslint": "^10.1.0",
//     "eslint": "^8.51.0",
//     "eslint-config-airbnb": "^19.0.4",
//     "eslint-config-prettier": "^9.0.0",
//     "eslint-config-react-app": "^7.0.1",
//     "eslint-plugin-import": "^2.28.1",
//     "eslint-plugin-jsx-a11y": "^6.7.1",
//     "eslint-plugin-prettier": "^5.0.1",
//     "eslint-plugin-react": "^7.33.2",
//     "husky": "^8.0.3",
//     "lint-staged": "^14.0.1",
//     "prettier": "^3.0.3"
//   }