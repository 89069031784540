/* eslint-disable no-unused-vars */
import React, { useState, useReducer, useEffect, useMemo,useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { setFormData } from '../redux/formDataSlice';
import { setMasterData } from '../redux/masterDataSlice';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { Button, Input } from 'reactstrap';
import { Toast, ToastBody, ToastHeader, Spinner } from 'reactstrap';
import FormControl from '@mui/material/FormControl';
import useAxiosInstance from '../axiosi-ins';
import Loader from '../Loader'
import LoanProgress from './LoanProgress';
import { updateProgress } from '../redux/actions';
import { getItem, getItemSession, setItem } from '../helpers/Utils';
import DataUContext from '../context/DataUContext';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PreviousLoan = React.memo(() => {
    const axios = useAxiosInstance();
    const history = useNavigate();
    const { applicationId } = useParams();
    const dispatch = useDispatch();
    // const dataU  = useContext(DataUContext); 
    const dataU  = useSelector((state) => state.payload); 
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitted, isSubmitting },
        control,
        setValue,
        setError,
        getValues
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onBlur',
        defaultValues: {
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "application_id": applicationId,
            "is_draft": 0,
            "loan_data": []
        }
    });
    const formData = useSelector((state) => state.formData.dataBasic);
    const [loading, setLoading] = useState(false);
    const [dataresponse, setdataresponse] = useState([]);
    const [formCount, setFormCount] = useState(1);
    const [loanData, setLoanData] = useState([]);
    const [bankLists, setBankLists] = useState([]);
    const [snackBarOption, setSnackBarOption] = useState({
        message: null,
        type: 'E',
    });
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'right',
    });
    const [open, setOpen] = React.useState(false);
    const { vertical, horizontal } = state;
    const handleClose = () => {
        setOpen(false)
    };

    const getMasterList = () => {
        axios.get('master/all-banks')
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    setBankLists(res.data);
                    setLoading(true);
                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)
                }
            })
            .catch((err) => {
                setdataresponse([]);
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
                console.log(errorMessage);
            });
    };

    const renderForm = () => {
        const forms = [];
        for (let i = 0; i < formCount; i++) {
            forms.push(
                <div className='' key={i}>
                    <Divider>
                        <Chip label={`Previous Loan ${i + 1}`} />
                    </Divider>
                    <div className='row mt-5' key={i}>
                        <div className='col-lg-4 '>
                            {/*<input type='hidden' name={`id_${i}`}  value={loanData[i].id || null}/> */}
                            <div className="mb-3 position-relative">
                                <Controller
                                    name={`loan_data[${i}].loan_account_no`}
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            fullWidth
                                            id={`loan_data[${i}].loan_account_no`}
                                            label="Loan Account No.*"
                                            variant="outlined"
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setValue(`loan_data[${i}].loan_account_no`, e.target.value);
                                            }}
                                            {...register(`loan_data[${i}].loan_account_no`, {
                                                required: 'loan Account no is required',
                                                minLength: {
                                                    value: 3,
                                                    message: 'Minimum 3 Characters Required for Name',
                                                },
                                            })}
                                        />
                                    )}
                                />
                                {errors[`loan_data[${i}].loan_account_no`] && <p className="error"> {errors[`loan_data[${i}].loan_account_no`].message}</p>}
                            </div>
                        </div>
                        <div className='col-lg-4 '>
                            <div className="mb-3 position-relative">
                                <Controller
                                    name={`loan_data[${i}].loan_year`}
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            fullWidth
                                            id={`loan_data[${i}].loan_year`}
                                            label="Loan Year*"
                                            variant="outlined"
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setValue(`loan_data[${i}].loan_year`, e.target.value);
                                            }}
                                            {...register(`loan_data[${i}].loan_year`, {
                                                required: 'Loan year is required',
                                                minLength: {
                                                    value: 4,
                                                    message: 'Loan Year should be 4 in length',
                                                },
                                                maxLength: {
                                                    value: 4,
                                                    message: 'Loan Year should be 4 in length',
                                                },
                                                type: Number,
                                            })}
                                        />
                                    )}
                                />
                                {errors[`loan_data[${i}].loan_year`] && <p className="error"> {errors[`loan_data[${i}].loan_year`].message}</p>}
                            </div>
                        </div>
                        <div className='col-lg-4 '>
                            <div className="mb-3 position-relative">
                                <Controller
                                    name={`loan_data[${i}].loan_amount`}
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            fullWidth
                                            id={`loan_data[${i}].loan_amount`}
                                            label="Loan Amount*"
                                            type='number'
                                            variant="outlined"
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setValue(`loan_data[${i}].loan_amount`, e.target.value);
                                            }}
                                            {...register(`loan_data[${i}].loan_amount`, {
                                                required: 'Loan Amount is required',
                                                minLength: {
                                                    value: 4,
                                                    message: 'Enter the valid Loan Amount',
                                                },
                                                type: Number,
                                            })}
                                        />
                                    )}
                                />
                                {errors[`loan_data[${i}].loan_amount`] && <p className="error"> {errors[`loan_data[${i}].loan_amount`].message}</p>}
                            </div>
                        </div>
                        <div className='col-lg-4 '>
                            <div className="mb-3 position-relative">
                                <Controller
                                    name={`loan_data[${i}].emi_amount`}
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            fullWidth
                                            id={`loan_data[${i}].emi_amount`}
                                            label="Emi Amount*"
                                            type='number'
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setValue(`loan_data[${i}].emi_amount`, e.target.value);
                                            }}
                                            variant="outlined"
                                            {...register(`loan_data[${i}].emi_amount`, {
                                                required: 'Emi Amount is required',
                                                minLength: {
                                                    value: 3,
                                                    message: 'Enter the valid Emi Amount',
                                                },
                                                type: Number,
                                            })}
                                        />
                                    )}
                                />
                                {errors[`loan_data[${i}].emi_amount`] && <p className="error"> {errors[`loan_data[${i}].emi_amount`].message}</p>}
                            </div>
                        </div>
                        <div className='col-lg-4 '>
                            <div className="mb-3 position-relative">
                                <Controller
                                    name={`loan_data[${i}].product`}
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            fullWidth
                                            id={`loan_data[${i}].product`}
                                            label="Product*"
                                            variant="outlined"
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                setValue(`loan_data[${i}].product`, e.target.value);
                                            }}
                                            {...register(`loan_data[${i}].product`, {
                                                required: 'Product is required',
                                                minLength: {
                                                    value: 3,
                                                    message: 'Enter the valid Product',
                                                },
                                            })}
                                        />
                                    )}
                                />
                                {errors[`loan_data[${i}].product`] && <p className="error"> {errors[`loan_data[${i}].product`].message}</p>}
                            </div>
                        </div>
                        <div className='col-lg-4 '>
                            <div className="mb-3 position-relative">
                                <FormControl sx={{ width: '100%' }}   >
                                    <InputLabel id={`bank_name_${i}`}>Bank Name*</InputLabel>
                                    <Controller
                                        name={`loan_data[${i}].bank_name`}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                required
                                                labelId={`loan_data[${i}].bank_name`}
                                                name={`loan_data[${i}].bank_name`}
                                                {...field}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    setValue(`loan_data[${i}].bank_name`, e.target.value);
                                                }}
                                            {...register(`loan_data[${i}].bank_name`, {
                                                required: 'Bank Name is required',
                                            })}
                                            >
                                                <MenuItem value="" disabled > <em>Select Bank name </em> </MenuItem>
                                                {
                                                    bankLists.map((value) => {
                                                        return (
                                                            <MenuItem key={value.name} value={value.id} >
                                                                {value.name}
                                                            </MenuItem>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                {errors[`loan_data[${i}].bank_name`] && getValues(`loan_data[${i}].bank_name`) === "" && <p className="error"> {errors[`loan_data[${i}].bank_name`].message}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return forms;
    }

    const handleAddMore = () => {
        setFormCount(formCount + 1);
    };
    const handleRemove = () => {
        if (formCount > 1) {
            setFormCount(formCount - 1);
            const loanData = getValues('loan_data')
            loanData.pop();
            setValue('loan_data', loanData);
        }
    };

    const handleSubmitDarft = () => {
        setValue('is_draft', 1)
        submitForm();
    };
    const submitForm = () => {
        axios.post(`/loan/previousLoans`, getValues())
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    setdataresponse(res)
                    setLoading(true);
                    setSnackBarOption({
                        type: 'S',
                        message: res.message,
                    });
                    setOpen(true)
                    setTimeout(() => {
                        history(`/loan/upload-loan-doc/` + applicationId);
                    }, 3000);


                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)
                }
            })
            .catch((error) => {
                let errorMessage = error.message;
                if (error?.response?.data?.message) {
                    errorMessage = error.response.data.message;
                    const res = error.response.data
                    if (res.message === "VALIDATION_ERROR") {
                        Object.entries(res.data).map(([value, text]) => setError(value, { message: text }))
                    }
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
            });
    };


    useEffect(() => {
        dispatch(updateProgress(5))
        getMasterList();
        const sendData = {
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "application_id": applicationId
        }
        axios.get(`/loan/reportDetail?${queryString.stringify(sendData)}`)
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    setdataresponse(res.data);
                    if (res.data.previous_loans.length > 0) {
                        res.data.previous_loans.forEach((preLoan, ind) => {
                            const setNestedValue = (prefix, object) => {
                                Object.entries(object).forEach(([key, value]) => {
                                    const field = `${prefix}.${key}`;
                                    setValue(field, value);
                                });
                            };
                            setNestedValue(`loan_data[${ind}]`, preLoan);
                        });
                        setLoanData(res.data.previous_loans)
                        setFormCount(res.data.previous_loans.length)
                    }
                    setLoading(true);
                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)
                }

            })
            .catch((err) => {
                setdataresponse([]);
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
                console.log(errorMessage);
            });
            // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [applicationId, dispatch, setdataresponse, setValue]);
    return (
        <div>
            {loading ? (
                <div className="App">
                    <div className='container width_1170'>
                        <div className='card'>
                            <div className='row'>
                                <div className='all_aplied_backBTN'>
                                    {getItemSession('plugin_accessibility') !== null && (getItemSession('plugin_accessibility') === "Darcy" || getItemSession('plugin_accessibility') === 'Eliza') ? <Button color='info' onClick={() => history('/home')}>
                                        &lArr;	 All Applied Loans
                                    </Button> : null}
                                    <Button className="btn btn-outline-primary addMore ml-2" variant="contained" onClick={handleAddMore} >
                                        &#x002B; Add More
                                    </Button>
                                    <Button color="danger" className="btn btn-outline-danger ml-2" variant="contained" onClick={handleRemove} >
                                        &#x002B; Remove
                                    </Button>
                                </div>
                            </div>
                            <div className='row mt-4 mb-2 '>
                                <LoanProgress />
                            </div>
                            <form method="post" onSubmit={handleSubmit(submitForm)} className="mt-3 px-3 bit-1" id="checkEligibility_form">
                                {renderForm()}
                                <div className='btn-center' >

                                    <Button color="secondary" variant="contained" className=" ml-2 " onClick={() => history(`/loan/reference/` + applicationId)} >
                                        Previous Page
                                    </Button>
                                    <Button color='warning' type="button" className='ml-2' onClick={handleSubmitDarft} >
                                        Save as Draft
                                    </Button>

                                    <Button type="button" color='success' className='ml-2 submit_green_btn' onClick={handleSubmit(submitForm)} disabled={isSubmitting}>
                                        Submit
                                    </Button>
                                    <Button color="light" variant="contained" className="ml-2 " onClick={() => history(`/loan/upload-loan-doc/` + applicationId)} >
                                        Skip
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                    {snackBarOption.message ? (
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                            anchorOrigin={{ vertical, horizontal }}>
                            <Alert onClose={handleClose} severity={snackBarOption.type === 'E' ? "error" : 'success'} sx={{ width: '100%' }}>
                                {snackBarOption.message}
                            </Alert>
                        </Snackbar>) : null}
                </div>) : (<Loader />)}
        </div>
    )
});

export default PreviousLoan;
