import { combineReducers } from 'redux';
import settings from './settings/reducer'; 
import formDataReducer from './formDataSlice';
import mastersListReducer from './masterDataSlice';
import { UPDATE_PROGRESS , SET_LOAN_APPLICATION,UPDATE_PAYLOAD} from './actions'; 

// const mastersListReducer = (state = [], action) => {
//   switch (action.type) {
//     case 'SET_MASTERS_LIST':
//       return action.payload;
//     default:
//       return state;
//   }
// };
const progressReducer = (state = 0, action) => {
  switch (action.type) {
    case UPDATE_PROGRESS:
      return action.payload;
    default:
      return state;
  }
}; 
const applicationReducer = (state = null, action) => {
  switch (action.type) {
    case SET_LOAN_APPLICATION:
      return action.payload;
    default:
      return state;
  }
}; 
const initialState = {};
// const payLoadReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case UPDATE_PAYLOAD:
//       return { ...state, ...action.payload };
//     default:
//       return state;
//   }
// }; 
const payLoadReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAYLOAD:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const reducers = combineReducers({
  settings,
  formData: formDataReducer,
  mastersData: mastersListReducer,
  progress: progressReducer,  
  loanApplication: applicationReducer,  
  payLoad: payLoadReducer,  
});

export default reducers;
