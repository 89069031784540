/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { getItem, setItem,getItemSession,setItemSession } from './helpers/Utils';
import cookies from './cookieinstance/cookieinstance';
import { useContext } from 'react';
import DataUContext from './context/DataUContext';
import { useSelector } from 'react-redux';

const useAxiosInstance = () => {
  // const datSource = useSelector((state) => state.payload); 
  const datSource = useContext(DataUContext);
  const instance = axios.create({
    baseURL: process.env.REACT_APP_REST_API_URL,
  });
  /**
   * @description Used to Handle Request interceptor for API calls
   * @return {Promise}-Return a Promise function
   * */
  instance.interceptors.request.use(
    async (config) => {
      if (getItemSession('token')) {
        // console.log('getItemSession("token") :>> ', getItemSession('token'));
        // const tokenData = jwtDecode(getItemSession('token')) || { refCode: '', plugin_accessibility: '' };
        // if (getItemSession('refCode') === tokenData?.refCode || getItemSession('plugin_accessibility') === tokenData?.plugin_accessibility) {
        config.headers = {
          token: `${getItemSession('token')}`,
        };
        // } else {
        //   // If the refCode or plugin_accessibility doesn't match, remove the token
        //   sessionStorage.removeItem('token');
        //   // localStorage.removeItem('token');
        // }
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  /**
   * @description Used to Handle Response interceptor for API calls
   * @return {Promise}-Return a Promise function
   * */
  instance.interceptors.response.use(
    async (response) => {
      // Handle success responses
      if (response.data.success === false && (response.data.message === 'Authorisation Token Missing!' || response.data.message === 'Authorisation Token Expired!')) {
        // console.log('test1 :>> ',response.data.message );
        return refreshTokenAndRetryRequest(response);
      }
      return response;
    },
    async (error) => {
      // Handle errors
      if (error.response) {
        if (error.response.status === 401 && error.response.data.message === 'Unauthorized') {
          console.log('test2  :>> ', error.response);
          return refreshTokenAndRetryRequest(error);
        } else if (error.response.status === 422) {
          logoutAndRedirect();
        }
      }
      return Promise.reject(error);
    }
  );

  // const refreshTokenAndRetryRequest = async (responseOrError) => {
  //   try {
  //     const originalRequest = responseOrError.config;
  //     if (originalRequest._retry) {
  //       return Promise.reject(responseOrError);
  //     }
  //     originalRequest._retry = true;

  //     // const refreshToken = getItem('token');
  //     // if (!refreshToken) {
  //       const tokenData = await refreshAuthenticationToken();
  //       // console.log('tokenData :>> ', tokenData);
  //       if (!tokenData.success) {
  //         logoutAndRedirect();
  //       } else { 
  //         setTokenAndRetry(originalRequest, tokenData.data.token);
  //       }
  //     // }  
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // };
  const refreshTokenAndRetryRequest = async (responseOrError) => {
    try {
      const originalRequest = responseOrError.config;
      if (!originalRequest._retry) {
        originalRequest._retry = true;

        // Refresh the token
        const tokenData = await refreshAuthenticationToken();
        if (!tokenData.success) {
          logoutAndRedirect();
        } else {
          setTokenAndRetry(originalRequest, tokenData.data.token);
        }
        // if (!tokenData.success) {
        //   logoutAndRedirect();
        // } else {
        //   setTokenAndRetry(originalRequest, tokenData.data.token);
        // }
        // if (tokenData.success) {
        //   // Update the token in sessionStorage or wherever it's stored
        //   setItemSession('token', tokenData.data.token);
        //   setItem('token', tokenData.data.token);
        //   const Userd = jwtDecode(tokenData.data.token)
        //   setItemSession('credit_score_permission', Userd?.credit_score_permission)

        //   // Update the original request's header with the new token
        //   originalRequest.headers['token'] = tokenData.data.token;

        //   // Retry the original request with the new token
        //   return axios(originalRequest);
        // }
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };
  const setTokenAndRetry = (requestConfig, token) => {
    setItemSession('token', token); 
    setItem('token', token);
    const Userd = jwtDecode(token)
    // const decode = jwtDecode(token)
    // setItemSession('plugin_accessibility', JSON.stringify(decode.plugin_accessibility));
    // setItemSession('plugin_accessibility',decode.plugin_accessibility)
    setItemSession('credit_score_permission', Userd?.credit_score_permission)

    instance.defaults.headers.token = token;
    return instance(requestConfig);
  };

  const refreshAuthenticationToken = async () => {
    try {
      // if (datSource &&
      //   (datSource.apiKey === undefined || datSource.apiKey === null ||
      //     datSource.apiSecret === undefined || datSource.apiSecret === null ||
      //     datSource.refCode === undefined || datSource.refCode === null)
      // ) {
      //   window.alert('Something went wrong !! there is missing dependency  ');
      //   // window.location = "/error-404";
      // }
      instance.defaults.headers.Authorization = `Basic ${btoa(`${datSource.apiKey || getItemSession('apiKey')}:${datSource.apiSecret || getItemSession('apiSecret')}`)}`;
      // instance.defaults.headers.Authorization = `Basic ${APIKEY}` ; 
      instance.defaults.headers.common['Request-src'] = `loan-plugin`
      instance.defaults.headers.common['Source'] = datSource.source || getItemSession('source')
      instance.defaults.headers.common['Package'] = datSource.serverIp || getItemSession('serverIp')
      instance.defaults.headers.common['Outletid'] = datSource.refCode || getItemSession('refCode')
      instance.defaults.headers.common['plugin-accessibility'] = datSource.plugin_accessibility || getItemSession('plugin_accessibility')
      const response = await instance.post('authentication');
      return response.data;
    } catch (error) {
      console.log('response err msg :>> ', error);
      let errorMessage = error.message;
      if (error?.response?.data?.message) {
        errorMessage = error.response.data.message;
      }
      console.log('response err errorMessage :>> ', errorMessage);
      // window.alert(errorMessage)
      return error.response.data;
    }
  };

  const logoutAndRedirect = () => {
    // localStorage.clear();
    // sessionStorage.clear();
    // cookies.remove('curr_userid');
    window.location = '/';
  };
  return instance;
};

export default useAxiosInstance;
// export default instance;
