/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useReducer, useEffect, useRef, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { classNames } from 'primereact/utils';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { ToggleButton } from 'primereact/togglebutton';
import { Tag } from 'primereact/tag';
import { useNavigate, Link, useParams } from 'react-router-dom';
import {
    Button as Rbtn,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'primeicons/primeicons.css';
import { cdnUrl } from '../constants/defaultValues';
import Loader from '../Loader';
import LoanFilter from './LoanFilter';

import useAxiosInstance from '../axiosi-ins';
import { dateFormate, getItem, getItemSession, setItem } from '../helpers/Utils';
// import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import { updateProgress } from '../redux/actions';
import DataUContext from '../context/DataUContext';
// import BankGrids from './BankGrids';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function LoansList() {
    const axios = useAxiosInstance();
    const dispatch = useDispatch();
    // const dataU  = useContext(DataUContext); 
    const dataU  = useSelector((state) => state.payload); 
    const userData = getItem('token') !== null ? jwtDecode(getItem('token')) : { role: 0 }
    const blue = {
        100: '#DAECFF',
        200: '#b6daff',
        400: '#3399FF',
        500: '#007FFF',
        600: '#0072E5',
        900: '#003A75',
    };

    const grey = {
        50: '#F3F6F9',
        100: '#E5EAF2',
        200: '#DAE2ED',
        300: '#C7D0DD',
        400: '#B0B8C4',
        500: '#9DA8B7',
        600: '#6B7A90',
        700: '#434D5B',
        800: '#303740',
        900: '#1C2025',
    };

    const Textarea = styled(BaseTextareaAutosize)(
        ({ theme }) => `
        width: 320px;
        font-family: IBM Plex Sans, sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 8px 12px;
        border-radius: 8px;
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
        background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
        box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    
        &:hover {
          border-color: ${blue[400]};
        }
    
        &:focus {
          border-color: ${blue[400]};
          box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
        }
    
        // firefox
        &:focus-visible {
          outline: 0;
        }
      `,
    );
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'right',
    });
    const dt = useRef(null);
    const [open, setOpen] = React.useState(false);
    const { vertical, horizontal } = state;
    const handleClose = () => {
        setOpen(false)
    };
    const [valueTab, setValueTab] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        getLoanType(newValue);
        setValueTab(newValue);
    }
    const { applicationId } = useParams();
    const [loading, setLoading] = useState(false);
    const [dataresponse, setdataresponse] = useState([]);
    const [pageData, setPageData] = useState([]);
    const [partnerDependency, setPartnerDependency] = useState([]);
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [snackBarOption, setSnackBarOption] = useState({
        message: null,
        type: 'E',
    });
    const [pagination, setPagination] = useState({
        current_page: 1,
        last_page: 1,
        per_page: 10,
        total: 0,
    });
    const [balanceFrozen, setBalanceFrozen] = useState(false);
    const [spiner, setSpiner] = useState(false);
    const [modal, setModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isModalVisible, setModalVisible] = useState(false);

    const toggleM = () => setModal(!modal);
    const toggleR = () => setModal(!isModalVisible);
    const [startDate, setStartDate] = useState();

    const date = !startDate ? null : moment(startDate).format('DD/MM/YYYY');

    const history = useNavigate();
    const [visible, setVisible] = useState(true);

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        setValue
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onBlur',
        defaultValues: {
            "application_id": "",
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "loan_application_id": "",
            "status": "",
            "remarks": "",
        }
    });
    const [statuses] = useState(["INITIATED", "LOGIN", "IN PROCESS", "PENDING", "APPROVED", "DISBURSED", "REJECTED", "REVOKED"]);
    const [incomeSource] = useState({ "1": "Salaried", "2": "Business", "3": "Others" });
    const [subStatus] = useState({ 1: "Yes", 0: "No", null: "" });
    const getSeverity = (status) => {
        switch (status) {
            case 'INITIATED':
                return 'danger';

            case 'LOGIN':
                return 'success';

            case 'IN PROCESS':
                return 'info';

            case 'PENDING':
                return 'warning';
            case 'APPROVED':
                return 'warning';
            case 'DISBURSED':
                return 'warning';
            case 'REJECTED':
                return 'info';
            case 'REVOKED':
                return 'success';
            default:
                return null;
        }
    };
    const getSeveritysub = (status) => {
        switch (status) {
            case 1:
                return 'danger';
            case 0:
                return 'success';
            default:
                return null;
        }
    };
    const statusBodyTemplate = (rowData) => {
        return <Tag value={statuses[rowData.status]} severity={getSeverity(statuses[rowData.status])} />;
    };
    const incomeSourceBody = (rowData) => {
        return <Tag value={incomeSource[rowData.income_source]} />;
    };
    const dependencyBody = (rowData) => {
        return <span className="font-bold">{subStatus[rowData.sub_status]}</span>;
        // return <Tag value={} />; 
    };
    const appliedByBody = (rowData) => {
        const lastName  = rowData.user?.last_name !== 'null' && rowData.user?.last_name !== null && rowData.user?.last_name !== undefined ? rowData.user?.last_name  : '';
        return <span className="font-bold">{rowData.user?.first_name+' '+ lastName  }</span>;
        // return <Tag value={} />; 
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };
    const handleBalance = () => {
        setBalanceFrozen(!balanceFrozen)
    };
    const rowClassName = (rowData) => {
        return partnerDependency.length > 0  && partnerDependency?.partnerDependencyapplications?.includes(rowData.application_id) ? 'highlighted-row' : '';
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end filter_search_box">
                {/*<Button type="button" className='mb-2' icon={ "pi pi-recycle"} label="Re-Fetch All data" outlined onClick={() => window.location.reload(false)} />
                <Button type="button" className='mb-2' icon={balanceFrozen ? "pi pi-lock" : "pi pi-lock-open"} label="Balance width" outlined onClick={handleBalance} />*/}
                <Button type="button" className='ml-2 mb-2' icon="pi pi-filter-slash" label="&nbsp; Clear" outlined onClick={clearFilter} />
                {userData.role === 3 || 16 ? <Button icon="pi pi-plus-circle" label=" &nbsp; Add loan" className="mb-2 apply_middle_filter p-button p-component p-button-outlined" outlined onClick={handleAdd} /> : null}
                <Button type="button" className='ml-2 mb-2' disabled label={`\u00A0 Partner Dependency \u00A0 ${partnerDependency.partnerDependencyCount || 0}`}     outlined />

                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };
    const headerBody = () => {
        return (
            <div className="flex justify-content-center ">
                <h1>Remarks</h1>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        // console.log('value :>> ', value);
        let _filters = { ...filters };

        if (_filters && _filters['global']) {
            _filters['global'].value = value;
        }

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            status: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            application_id: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            mobile: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            loan_amount: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            created_date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
        setGlobalFilterValue('');
    };
    function getPartnerDependency() { 
        axios.get(`loan/partner-dependency`, { params: { ref_code: dataU?.refCode || getItemSession('refCode')} }).then((response) => {
            const res = response.data;
            if (res.success === true) {
                setPartnerDependency(res.data) 
                // setLoading(true);
            } else {
                setSnackBarOption({
                    type: 'E',
                    message: res.message,
                });
                setOpen(true)
            }

        })
            .catch((err) => {
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true) 
                console.log(errorMessage);
            });
    };
    function getLoanType(page = 0,fData={}) {
        let val = "all"
        if (page === 1) {
            val = "act"
        }else if(page === 2){
            val = "app"
        }else if(page === 3){
            val = "rej"
        }
        const filterData={ 
            ref_code: dataU?.refCode || getItemSession('refCode'),
            pagination: 0,
            loan_activity : val,
            income_source:fData.hasOwnProperty('income_source') ? fData.income_source :'',
            loan_type_id:fData.hasOwnProperty('loan_type_id') ? fData.loan_type_id :'',
            status:fData.hasOwnProperty('status') ? fData.status :'',
            from_date:fData.hasOwnProperty('from_date') ? fData.from_date :'',
            to_date:fData.hasOwnProperty('to_date') ? fData.to_date :'',
        }   
        axios.get(`loan/fetchAll`, { params: filterData }).then((response) => {
            const res = response.data;
            if (res.success === true) {
                setdataresponse(res.data)
                // setPagination({
                //     current_page: res.data.pagination.current_page,
                //     last_page: res.data.pagination.last_page,
                //     per_page: res.data.pagination.per_page,
                //     total: res.data.pagination.total,
                // });
                setLoading(true);
            } else {
                setSnackBarOption({
                    type: 'E',
                    message: res.message,
                });
                setOpen(true)
            }

        })
            .catch((err) => {
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
                setTimeout(() => {
                    window.location.reload(false);
                }, 1500);
                console.log(errorMessage);
            });
    };
    function getAllLoanType() {
        axios.get(`loan/getLoanTypes`, { params: { ref_code: dataU?.refCode || getItemSession('refCode') } }).then((response) => {
            const { status, data } = response;
            if (status === 200) {
                setPageData();
                setLoading(true);
            }
        })
            .catch((err) => { 
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                // window.location.reload(false);
                // window.location.href = location.href
                console.log(errorMessage);
            });
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="action-buttons">
                  <Button
                    icon="pi pi-pencil"
                    onClick={() => handleEdit(rowData)}
                    className="p-button-success p-mr-2"
                /> 
                <Button
                    icon="pi pi-eye"
                    onClick={() => handleView(rowData)}
                    className="p-button-danger p-mr-2"
                />
                <Button
                    icon="pi pi-refresh"
                    onClick={() => handleUpdateStatus(rowData)}
                    className="p-button-warning"
                />
            </div>
        );
    };
    const handleEdit = (rowData) => {
        history(`/apply-loan/` + rowData.application_id);
    };
    const handleAdd = () => {
        history('/apply-loan');
    };

    const handleView = (rowData) => {
        history('/view-Application', { state: { rowData: rowData } });

        // return <LoanView />
    };
    const exportCSV = () => {
        // dt.current.exportCSV();
        const exportedData = dataresponse.map((item) => ({
            ...item,
            income_source: incomeSource[item.income_source],
            status: statuses[item.status],
            sub_status: subStatus[item.sub_status],
        }));
        let dd = []
        const exportedDataTocsv = exportedData.map((item) => (
            dd.push(
                {
                    "Application ID": item.application_id,
                    "Name": item.name,
                    "Mobile": item.mobile,
                    "Pan NO": item.pan,
                    "Aadhaar": item.aadhaar,
                    "Loan Amount": item.loan_amount,
                    "Income Source": item.income_source,
                    "Status": item.status,
                    "Remarks": item.remarks,
                    "Partner Dependency": item.sub_status,
                    "Agent Name": item.user.first_name + " " + item.user.last_name,
                    "Created Date": item.created_date,
                }
            )
        ));
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(dd);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'Loan Application');
        });
        // if (exportedData.length > 0 && dt.current) {
        //     dt.current.exportCSV({ selectionOnly: false, customData: exportedData });
        // }
        // dt.current.exportCSV({ selectionOnly: false, customData: exportedData }); 
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };
    const rightToolbarTemplate = () => {
        return <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />;
    };

    const handleUpdateStatus = (rowData) => {
        toggleM();
        setValue("application_id", rowData.application_id)
    };
    const submitForm = () => {
        setSpiner(true);
        axios.post(`/loan/updateStatus`, getValues())
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    setSpiner(false)
                    toggleM();
                    setValue('application_id', "")
                    setValue('status', "")
                    setValue('remarks', "")
                    setSnackBarOption({
                        type: 'S',
                        message: res.message,
                    });
                    setOpen(true)
                    getLoanType()
                    // window.location.reload(false);
                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)

                }
            })
            .catch((error) => {
                let errorMessage = error.message;
                if (error?.response?.data?.message) {
                    errorMessage = error.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
            });
    };
    useEffect(() => {
        setLoading(false)
        initFilters()
        dispatch(updateProgress(0))
        // if (getItem('refCode')) {
        getPartnerDependency();
        getAllLoanType();
        getLoanType();
        // }
    }, [dispatch, applicationId]);
    const onPageChange = (event) => {
        getLoanType(event.page + 1); // PrimeReact uses 0-based index, Laravel expects 1-based index
    };
    const header = renderHeader();
    const paginatorRight = <Button type="button" icon="pi pi-download" text onClick={exportCSV} />;
    const balanceTemplate = (rowData) => {
        return <span className="font-bold">{formatCurrency(rowData.loan_amount)}</span>;
    };
    const dateBodyTemplate = (rowData) => {
        return <span className="font-bold">{dateFormate(rowData.created_date)}</span>;
    };
    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    const formatCurrency = (value = 0) => {
        return value?.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
    };
    const onRemarksClick = (rowData) => {
        setSelectedRow(rowData);
        setModalVisible(true);
    };
    const renderTable = () => {
        return (
            <>
            <LoanFilter dataU={dataU} handelFilter={getLoanType} pageData={pageData} />
            <DataTable ref={dt} value={dataresponse} rowClassName={rowClassName} scrollable scrollHeight="400px" size='small' paginator filterDisplay='menu' rows={10} dataKey="id"
                filters={filters} globalFilterFields={['name', 'application_id', 'mobile','status','created_date']} header={header}
                emptyMessage="No Loans Record  found." globalFilter={globalFilterValue} sortMode='multiple' removableSort paginatorRight={paginatorRight}>

                <Column field="application_id" header="Application ID" filter sortable filterPlaceholder="Search by Application Id"  />

                <Column field="name" header="Name" filter sortable filterPlaceholder="Search by name" style={{ minWidth: '8rem' }} />
                <Column field="mobile" header="Mobile" filter sortable filterPlaceholder="Search by Mobile" style={{ minWidth: '8rem' }} />
                {/*<Column field="pan" header="Pan" filter sortable filterPlaceholder="Search by pan" style={{ minWidth: '8rem' }} />*/}
                <Column field="loan_amount" header="Loan Amount" body={balanceTemplate} filter sortable filterPlaceholder="Search by Loan Amount" style={{ mixWidth: '5rem' }} />
                <Column field="loan_type.bank_name" header="Loan Type" sortable style={{ minWidth: '8rem' }} />
                <Column field="income_source" sortable header="Income Source" body={incomeSourceBody} style={{ minWidth: '8rem' }} />
                <Column field="status" header="Status" sortable style={{ minWidth: '8rem' }} body={statusBodyTemplate} />
                <Column field="created_date" header="Applied Date"   sortable style={{ minWidth: '8rem' }} body={dateBodyTemplate}  />
                <Column field="user.first_name" header="Applied By"   sortable style={{ minWidth: '8rem' }} body={appliedByBody}  />
                <Column field="sub_status" header="Partner Dependency" sortable body={dependencyBody} style={{ maxWidth: '8rem' }} />
                <Column field="remarks" header="Remarks" style={{ maxWidth: '8rem', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    body={(rowData) => (
                        <div onClick={() => onRemarksClick(rowData)} style={{ cursor: 'pointer' }}>
                            {rowData.remarks}
                        </div>
                    )}
                />
                <Column header="Actions"  body={actionBodyTemplate} style={{ minWidth: '8rem' }} alignFrozen="right" />
            </DataTable>
            </>
        )
    };
    return (
        <div>
            {loading ? (<div className="App">
                <div className='container api_home_page'>
                    <div className="card p-0 m-1">
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={valueTab} onChange={handleChangeTab} variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile aria-label="All Status Of Loans">
                                    <Tab label="All Loans" {...a11yProps(0)} />
                                    <Tab label="Active Loans" {...a11yProps(1)} />
                                    <Tab label="Approved Loans" {...a11yProps(2)} />
                                    <Tab label="Rejected Loans" {...a11yProps(3)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={valueTab} index={0}>
                                {renderTable()}
                            </CustomTabPanel>
                            <CustomTabPanel value={valueTab} index={1}>
                                {renderTable()}
                            </CustomTabPanel>
                            <CustomTabPanel value={valueTab} index={2}>
                                {renderTable()}
                            </CustomTabPanel>
                            <CustomTabPanel value={valueTab} index={3}>
                                {renderTable()}
                            </CustomTabPanel>
                        </Box>

                    </div>
                    <div>
                        {snackBarOption.message ? (
                            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                                anchorOrigin={{ vertical, horizontal }}>
                                <Alert onClose={handleClose} severity={snackBarOption.type === 'E' ? "error" : 'success'} sx={{ width: '100%' }}>
                                    {snackBarOption.message}
                                </Alert>
                            </Snackbar>) : null}
                    </div>
                </div>
                <Modal isOpen={modal} toggle={toggleM} className="updateStatusModal">
                    <ModalHeader toggle={toggleM}>Update Status</ModalHeader>
                    <form method="post" onSubmit={handleSubmit(submitForm)} className="mt-3 px-3 bit-1 updateStatusModalForm" id="checkEligibility_form">
                        <ModalBody>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="mb-3 position-relative">
                                        <FormControl sx={{ width: '100%' }}   >
                                            <InputLabel id="status">Select Status*</InputLabel>
                                            <Controller
                                                name="status"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        fullWidth
                                                        labelId="status"
                                                        id="status"
                                                        label="Select Status"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('status', e.target.value);
                                                        }}
                                                    >
                                                        <MenuItem value="" disabled > <em>None</em> </MenuItem>
                                                        <MenuItem value={7}>REVOKED</MenuItem>
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                        {errors.status && <p className="error"> {errors.status.message}</p>}
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className="mb-3 position-relative">
                                        <Controller
                                            name="remarks"
                                            control={control}
                                            render={({ field }) => (
                                                <Textarea
                                                    minLength={2}
                                                    minRows={3}
                                                    variant="outlined"
                                                    placeholder="Write Remarks "
                                                    id='remarks'
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue('remarks', e.target.value);
                                                    }}
                                                    {...register('remarks', {
                                                        required: 'Reamrks no is required',
                                                    })}
                                                />

                                            )}
                                        />
                                        {errors.remarks && <p className="error"> {errors.remarks.message}</p>}
                                    </div>
                                </div>
                            </div>

                            <div className='updateStatus_btn_01'>
                                <Button color="primary" type='submit' >
                                    {spiner ? "Submiting Wait... " : "Save Changes"}
                                </Button>{' '}
                                <Button color="secondary" onClick={toggleM}>
                                    Cancel
                                </Button>
                            </div>
                        </ModalBody>

                      
                    </form>
                </Modal>
                {selectedRow && (
                    <Dialog header={headerBody} breakpoints={{ '960px': '75vw', '641px': '100vw' }} visible={isModalVisible} style={{ width: '50vw' }} onHide={() => setModalVisible(false)}>
                        <div className="container m-2">
                            <p className="m-0">
                                {selectedRow.remarks}
                            </p>
                        </div>
                    </Dialog>
                )}

            </div>) : (<Loader list={true} />)}
        </div>
    );


}



export default LoansList; 