// import { useRouteError } from "react-router-dom";

export default function NotFoundDep() {
  // const error = useRouteError();
  // console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <h2>Sorry, an unexpected error has occurred. Some Missing dependency Please check  Agin And try After  Some time  Or Contact Admin </h2>
        
    </div>
  );
}