/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Button } from 'reactstrap';
import useAxiosInstance from '../axiosi-ins';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../Loader';
import { getItem, getItemSession, setItem } from '../helpers/Utils';
import FileViewer from './FileViewer';
import { cdnUrl } from '../constants/defaultValues';
import userImage from '../assets/img/rupee_india.png';
import DataUContext from '../context/DataUContext';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const LoanView = () => {
  const history = useNavigate();
  const axios = useAxiosInstance();
  const location = useLocation();
  const { applicationId } = useParams();
  // const dataU = useContext(DataUContext);
  const dataU  = useSelector((state) => state.payload); 
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });
  const [rowData, setRowData] = React.useState(location.state?.rowData);
  const [open, setOpen] = React.useState(false);
  const { vertical, horizontal } = state;
  const handleClose = () => {
    setOpen(false)
  };
  const [loading, setLoading] = useState(false);
  const [dataresponse, setdataresponse] = useState([]);
  const [bankLists, setBankLists] = useState([]);
  const [snackBarOption, setSnackBarOption] = useState({
    message: null,
    type: 'E',
  });
  const [LOAN_BANK_STATUS_ARR] = useState({
    "0": "LOGIN",
    "1": "IN PROCESS",
    "2": "APPROVED",
    "3": "DISBURSED",
    "4": "REJECTED",
  });
  const getAllBank = () => {
    axios.get('master/all-banks')
      .then((response) => {
        const res = response.data;
        if (res.success === true) {
          setBankLists(res.data);
          setLoading(true);
        } else {
          setSnackBarOption({
            type: 'E',
            message: res.message,
          });
        }
      })
      .catch((err) => {
        setdataresponse([]);
        let errorMessage = err.message;
        if (err?.response?.data?.message) {
          errorMessage = err.response.data.message;
        }
        setSnackBarOption({
          type: 'E',
          message: errorMessage,
        });
        setOpen(true)
        console.log(errorMessage);
      });
  };
  const handleClick = (appID) => {
    history('/apply-loan/' +appID);
  };
  const dataLoan = () => {
    const sendData = {
      "ref_code": dataU?.refCode || getItemSession('refCode'),
      "application_id": applicationId ? applicationId: rowData?.application_id,
      "pagination":0
    }
    axios.get(`/loan/reportDetail?${queryString.stringify(sendData)}`)
      .then((response) => {
        const res = response.data;
        if (res.success === true) {
          setdataresponse(res.data);
          setLoading(true);
        } else {
          setSnackBarOption({
            type: 'E',
            message: res.message,
          });
          setOpen(true)
        }

      })
      .catch((err) => {
        setdataresponse([]);
        let errorMessage = err.message;
        if (err?.response?.data?.message) {
          errorMessage = err.response.data.message;
        }
        setSnackBarOption({
          type: 'E',
          message: errorMessage,
        });
        setOpen(true)
        console.log(errorMessage);
      });
    if(applicationId) {
      axios.get(`/loan/fetchAll?${queryString.stringify(sendData)}`)
        .then((response) => {
          const res = response.data;
          if (res.success === true) {
            setRowData(res.data);
            setLoading(true);
          } else {
            setSnackBarOption({
              type: 'E',
              message: res.message,
            });
            setOpen(true)
          }

        })
        .catch((err) => {
          setdataresponse([]);
          let errorMessage = err.message;
          if (err?.response?.data?.message) {
            errorMessage = err.response.data.message;
          }
          setSnackBarOption({
            type: 'E',
            message: errorMessage,
          });
          setOpen(true)
          console.log(errorMessage);
        });

    }
  };
  useEffect(() => {
    getAllBank();
    dataLoan();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [rowData, setdataresponse, applicationId]);
  return (
    <div id="root">
      {loading ? (
        <div>
          <div className="h-100">
            <div className="top_strip1">
              <div className='all_aplied_backBTN'>
                <Button color='info' className='mt-3' onClick={() => history('/home')}>
                  &lArr;	All Applied Loans
                </Button>
              </div>
            </div>
            <div className="container loanViewWrap">
              <div className="row" style={{ margin: "0 0.5rem" }}>
                <div className="col-md-3 basic_detail_view">

                  <div className="user_img">
                    <img src={userImage} alt="User Images" style={{ width: '60px', borderRadius: '40%' }} />
                  </div>
                  <h2>Basic detail</h2>
                  <div className="detailes_view">

                    <span className="email"><b>Name :</b> {rowData?.name || '-'}</span>
                    <span className="mb_number"><b>Father name :</b> {rowData?.applicant_details?.father_name || '-'}</span>
                    <span className="mb_number"><b>Mother name :</b> {rowData?.applicant_details?.mother_name || '-'} </span>
                    <span className="gen_der"><b>Gender :</b> {rowData?.applicant_details?.gender || '-'}</span>
                    <span className="gen_der"><b>Marital status :</b> {rowData?.applicant_details?.marital_staus || '-'}</span>
                    <span className="gen_der"><b>Religion :</b> {rowData?.applicant_details?.religion || '-'}</span>
                  </div>
                  {/*
                    <a href="#" className="readMore">Eligible</a>*/} 
                  <button className="readMore">Loan Amount : {rowData?.loan_amount || '-'}</button> 
                  <button   className="readMore"  onClick={() => handleClick(applicationId || rowData?.application_id)} >Edit - {applicationId || rowData?.application_id}</button>

                </div>
                <div className="col-md-8 right_view_sec">



                  <div className="basic_detail_sec">
                    <h3><i className="fa fa-graduation-cap" aria-hidden="true"></i> Educational & Professional Detail</h3>
                    <div className="row row_head">
                      <div className="col-md-4">
                        Educational Qualification
                      </div>
                      <div className="col-md-4">
                        No.of Dependent
                      </div>
                      <div className="col-md-4">
                        Total Employment Stability
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-4">
                        {rowData?.applicant_details?.qualification || '-'}
                      </div>
                      <div className="col-md-4">
                        {rowData?.applicant_details?.dependent || '-'}
                      </div>
                      <div className="col-md-4">
                        {rowData?.applicant_details?.current_emp_stability || '-'}
                      </div>
                    </div>
                    <div className="row row_head">
                      <div className="col-md-4">
                        Industry of working
                      </div>
                      <div className="col-md-4">
                        Company Name
                      </div>
                      <div className="col-md-4">
                        Designation
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-4">
                        {rowData?.applicant_details?.industry_working || " - "}
                      </div>
                      <div className="col-md-4">
                        {rowData?.applicant_details?.employer_name || " - "}
                      </div>
                      <div className="col-md-4">
                        {rowData?.applicant_details?.designation || " - "}
                      </div>
                    </div>
                    <div className="row row_head">
                      <div className="col-md-4">
                        Net take home salary
                      </div>
                    </div>
                    <div className="row mb-2">

                      <div className="col-md-4">
                        {rowData?.monthly_income || " - "}
                      </div>
                    </div>
                  </div>

                  <div className="basic_detail_sec">
                    <h3><i className="fa fa-university" aria-hidden="true"></i> Bank Account Detail</h3>
                    <div className="row row_head">
                      <div className="col-md-4">
                        Salary Bank Account
                      </div>
                      <div className="col-md-4">
                        Branch Name
                      </div>
                      <div className="col-md-4">
                        Account Type
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        {
                          bankLists?.map((value) => {
                            return value.id === parseInt(rowData?.applicant_details?.salary_bank_account) ? value.name : " "
                          })
                        }
                      </div>
                      <div className="col-md-4">
                        {rowData?.applicant_details?.bank_branch || " - "}
                      </div>
                      <div className="col-md-4">
                        {rowData?.applicant_details?.account_type || " - "}
                      </div>
                    </div>
                  </div>

                  <div className="basic_detail_sec">
                    <h3><i className="fa fa-map-marker" aria-hidden="true"></i> Address</h3>
                    {dataresponse?.addresses && dataresponse?.addresses.length > 0 ?
                      <table className="table mg-b-0 text-md-nowrap">
                        <thead><tr>
                          <th>Address Type</th>
                          <th>Address Line 1</th>
                          <th>Address Line 2</th>
                          <th>Address Line 3</th>
                          <th>State</th>
                          <th>City</th>
                          <th>Pincode</th>
                          <th>Landmark</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Created Date</th>
                        </tr></thead>
                        {dataresponse?.addresses.map((ref, index) => (
                          <tr key={index}>
                            <td>{ref.address_type === 1 ? 'Present Address' : (ref.address_type === 2 ? 'Permanent Address' : 'Office Address')}</td>
                            <td>{ref.address_line1}</td>
                            <td>{ref.address_line2}</td>
                            <td>{ref.address_line3}</td>
                            <td>{ref.state?.name}</td>
                            <td>{ref.city?.name}</td>
                            <td>{ref.pincode}</td>
                            <td>{ref.landmark}</td>
                            <td>{ref.email}</td>
                            <td>{ref.phone}</td>
                            <td>{ref.created_at}</td>
                          </tr>
                        ))}
                      </table> :
                      <table className="table mg-b-0 text-md-nowrap">
                        <tr><td colSpan="11">No Data Found</td></tr>
                      </table>
                    }
                  </div>

                  <div className="basic_detail_sec">
                    <h3><i className="fa fa-user" aria-hidden="true"></i> Co-Applicant</h3>
                    {rowData?.co_applicant ?
                      <div>
                        <div className="row row_head">
                          <div className="col-md-4">
                            Name
                          </div>
                          <div className="col-md-4">
                            Relationship
                          </div>
                          <div className="col-md-4">
                            Email
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-md-4">
                            {rowData?.co_applicant?.name}
                          </div>
                          <div className="col-md-4">
                            {rowData?.co_applicant?.relationship}
                          </div>
                          <div className="col-md-4">
                            {rowData?.co_applicant?.email}
                          </div>
                        </div>
                        <div className="row row_head">
                          <div className="col-md-4">
                            Phone
                          </div>
                          <div className="col-md-4">
                            Address Line 1
                          </div>
                          <div className="col-md-4">
                            Address Line 2
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-md-4">
                            {rowData?.co_applicant?.phone}
                          </div>
                          <div className="col-md-4">
                            {rowData?.co_applicant?.address_line1}
                          </div>
                          <div className="col-md-4">
                            {rowData?.co_applicant?.address_line2}
                          </div>
                        </div>
                        <div className="row row_head">
                          <div className="col-md-4">
                            Address Line 3
                          </div>
                          <div className="col-md-4">
                            City
                          </div>
                          <div className="col-md-4">
                            State
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-md-4">
                            {rowData?.co_applicant?.address_line3}
                          </div>
                          <div className="col-md-4">
                            {rowData?.co_applicant?.city?.name}
                          </div>
                          <div className="col-md-4">
                            {rowData?.co_applicant?.state?.name}
                          </div>
                        </div>
                        <div className="row row_head">
                          <div className="col-md-4">
                            Pincode
                          </div>
                          <div className="col-md-4">
                            Landmark
                          </div>
                          <div className="col-md-4">
                            Alternate No.
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-md-4">
                            {rowData?.co_applicant?.pincode}
                          </div>
                          <div className="col-md-4">
                            {rowData?.co_applicant?.landmark}
                          </div>
                          <div className="col-md-4">
                            {rowData?.co_applicant?.alternate_number}
                          </div>
                        </div>
                        <div className="row row_head">
                          <div className="col-md-4">
                            Occupation
                          </div>
                          <div className="col-md-4">
                            Created Date
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-md-4">
                            {rowData?.co_applicant?.occupation}
                          </div>
                          <div className="col-md-4">
                            {rowData?.co_applicant?.created_at}
                          </div>
                        </div> </div> : <table className="table mg-b-0 text-md-nowrap">
                        <tr><td colSpan="11">No Data Found</td></tr>
                      </table>}
                  </div>

                  <div className="basic_detail_sec">
                    <h3><i className="fa fa-users" aria-hidden="true"></i> References</h3>
                    {dataresponse?.references && dataresponse?.references.length > 0 ?
                      <table className="table mg-b-0 text-md-nowrap">
                        <thead><tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Address</th>
                          <th>Relationship</th>
                          <th>Created Date</th>
                        </tr></thead>
                        {dataresponse?.references.map((ref, index) => (
                          <tr key={index}>
                            <td>{ref.name}</td>
                            <td>{ref.email}</td>
                            <td>{ref.phone}</td>
                            <td>{ref.address}</td>
                            <td>{ref.relationship}</td>
                            <td>{ref.created_at}</td>
                          </tr>
                        ))}
                      </table> :
                      <table className="table mg-b-0 text-md-nowrap">
                        <tr><td colSpan="11">No Data Found</td></tr>
                      </table>
                    }
                  </div>

                  <div className="basic_detail_sec">
                    <h3><i className="fa fa-check" aria-hidden="true"></i> Previous Loan</h3>
                    {dataresponse?.previous_loans && dataresponse?.previous_loans.length > 0 ?
                      <table className="table mg-b-0 responsive">
                        <thead><tr>
                          <th>Name</th>
                          <th>Loan Account No.</th>
                          <th>Loan Year</th>
                          <th>Loan Amount</th>
                          <th>EMI Amount</th>
                          <th>Product</th>
                          <th>Bank Name</th>
                          <th>Created Date</th>
                        </tr></thead>
                        {dataresponse?.previous_loans.map((ref, index) => (
                          <tr key={index}>
                            <td>{ref.loan_account_no}</td>
                            <td>{ref.loan_year}</td>
                            <td>{ref.loan_amount}</td>
                            <td>{ref.emi_amount}</td>
                            <td>{ref.product}</td>
                            <td>{ref.bank_name}</td>
                          </tr>
                        ))}
                      </table> :
                      <table className="table mg-b-0 text-md-nowrap">
                        <tr><td colSpan="11">No Data Found</td></tr>
                      </table>
                    }
                  </div>

                  <div className="basic_detail_sec">
                    <h3><i className="fa fa-upload" aria-hidden="true"></i> Upload Document</h3>
                    {dataresponse?.documents && dataresponse?.documents.length > 0 ?
                      <table className="table mg-b-0 text-md-nowrap">
                        <thead>
                          <tr>
                            <th>Document Type</th>
                            <th>Document No.</th>
                            <th>Password</th>
                            <th>File</th>
                            <th>Created Date</th>
                          </tr>
                        </thead>
                        {dataresponse?.documents.map((ref, index) => (
                          <tr key={index}>
                            <td>{ref.doc_type ? ref.doc_type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') : ''}</td>
                            <td>{ref.doc_no}</td>
                            <td>{ref.doc_password}</td>
                            <td><a href={cdnUrl + ref.file} download target='_blank' rel="noreferrer" > <FileViewer item={ref} /> </a></td>
                            <td>{ref.date_created}</td>
                          </tr>
                        ))}
                      </table> :
                      <table className="table mg-b-0 text-md-nowrap">
                        <tr><td colSpan="11">No Data Found</td></tr>
                      </table>
                    }
                  </div>

                  <div className="basic_detail_sec">
                    <h3><i className="fa fa-check" aria-hidden="true"></i> Bank Status</h3>
                    {dataresponse?.banks_status && dataresponse?.banks_status.length > 0 ?
                      <table className="table mg-b-0 responsive">
                        <thead><tr>
                          <th>Bank</th>
                          <th>Bank Application ID</th>
                          <th>Approved Amount</th>
                          <th>Disbursed Amount</th>
                          <th>Approval Date</th>
                          <th>Disbursed Date</th>
                          <th>ROI</th>
                          <th>Bank Status</th>
                          <th>Created Date</th>
                        </tr></thead>
                        {dataresponse?.banks_status.map((ref, index) => (
                          <tr key={index}>
                            <td>{bankLists?.map((value) => {
                              return value.id === parseInt(ref.bank) ? value.name : " "
                            })}</td>
                            <td>{ref.bank_app_id}</td>
                            <td>{ref.approved_amount}</td>
                            <td>{ref.disburse_amount}</td>
                            <td>{ref.approval_date}</td>
                            <td>{ref.disburse_date}</td>
                            <td>{ref.roi}</td>
                            <td>{LOAN_BANK_STATUS_ARR[ref.bank_status]}</td>
                            <td>{ref.created_date}</td>
                          </tr>
                        ))}
                      </table> :
                      <table className="table mg-b-0 text-md-nowrap">
                        <tr><td colSpan="11">No Data Found</td></tr>
                      </table>
                    }
                  </div>

                </div>
              </div>
            </div>
            <div className="bottom_strip1">
            </div>
          </div>
          {snackBarOption.message ? (
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
              anchorOrigin={{ vertical, horizontal }}>
              <Alert onClose={handleClose} severity={snackBarOption.type === 'E' ? "error" : 'success'} sx={{ width: '100%' }}>
                {snackBarOption.message}
              </Alert>
            </Snackbar>) : null}
        </div>) : (<Loader />)}
    </div>
  );
}

export default LoanView;