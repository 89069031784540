/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useReducer, useEffect, useMemo, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { setFormData } from '../redux/formDataSlice';
import { setMasterData } from '../redux/masterDataSlice';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/system';
import FormControl from '@mui/material/FormControl';
import useAxiosInstance from '../axiosi-ins';
import Loader from '../Loader'
import ImgDropZone from './ImgDropZone';
import DocTabel from './DocTabel';
import LoanProgress from './LoanProgress';
import { updateProgress } from '../redux/actions';
import { currencyFormatter, getItem, getItemSession, setItem } from '../helpers/Utils';
import checkPng from '../assets/img/check.png';
import { LOAN_TYPE, cdnUrl } from '../constants/defaultValues';
import DataUContext from '../context/DataUContext';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const useStyles = styled((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    countdownText: {
        color: 'blue',
        fontSize: '2rem',
        fontWeight: 'bold',
    },
    modalContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
function CircularProgressWithLabel(props) {
    return (
        <Box lg={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                lg={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${props.value} Please Wait while we are processing  ....`}
                </Typography>
            </Box>
        </Box>
    );
}
function ConfirmationDialogRaw(props) {
    const { onClose, open, headerContent,
        modalContent, ...other } = props;
    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose();
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={open}
            {...other}
        >
            <DialogTitle>{headerContent}</DialogTitle>
            <DialogContent dividers>
                {modalContent}
            </DialogContent>
            {/*<DialogActions>
                <Button onClick={handleOk}>Ok</Button>
    </DialogActions>*/}
        </Dialog>
    );
}

//   ConfirmationDialogRaw.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     open: PropTypes.bool.isRequired,
//     value: PropTypes.string.isRequired,
//   };

//   CircularProgressWithLabel.propTypes = {
//     /**
//      * The value of the progress indicator for the determinate variant.
//      * Value between 0 and 100.
//      * @default 0
//      */
//     value: PropTypes.number.isRequired,
//   };
const UploadDoc = React.memo(() => {
    const axios = useAxiosInstance();
    const history = useNavigate();
    const { applicationId } = useParams();
    const dispatch = useDispatch();
    // const dataU  = useContext(DataUContext); 
    const dataU  = useSelector((state) => state.payload); 
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isSubmitted, isSubmitting },
        setValue,
        setError,
        getValues
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onBlur',
        defaultValues: {
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "application_id": applicationId,
            "doc_file": '',
            "doc_type": '',
            "doc_no": '',
            "doc_password": '',
        }
    });
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'right',
    });
    const [open, setOpen] = React.useState(false);
    const { vertical, horizontal } = state;
    const handleClose = () => {
        setOpen(false)
    };
    const formData = useSelector((state) => state.formData.dataBasic);
    const [loading, setLoading] = useState(false);
    const [dataresponse, setdataresponse] = useState([]);
    const [documentsList, setDocumentsList] = useState([]);
    const [allDocList, setallDocList] = useState(null);
    const [bankDetail, setBankDetails] = useState(null);
    const [userDocArrays, setUserDocArrays] = useState([]);
    const [docLists, setDocLists] = useState([]);
    const classes = useStyles();
    const [countdown, setCountdown] = useState(5);
    const [modalOpen, setModalOpen] = useState(false);
    const [snackBarOption, setSnackBarOption] = useState({
        message: null,
        type: 'E',
    });
    const [docFile, setDocFile] = useState([]);
    const [imagefile, setImagefile] = useState();
    const [disable, setdisable] = useState(false);
    const childToParent = (childdata) => {
        if (childdata === 'error') {
            setdisable(true);
        } else {
            setValue('doc_file', childdata[0])
            setImagefile(childdata[0]);
            setdisable(false);
        }
    };


    const callBackDelete = (docId) => {
        const sendData = {
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "application_id": applicationId,
            "doc_id": docId
        }
        axios.post(`/loan/deleteLoanDoc`, sendData)
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    getData()
                    // window.location.reload(false);
                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)
                }
            })
            .catch((err) => {
                setdataresponse([]);
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
                console.log(errorMessage);
            });
    };
    const setDatasMaster = (dataresponses, allDocs) => {
        const loanType = dataresponses?.applicant_details?.loan_type;
        const incomeSource = dataresponses?.income_source;
        let loandocs;

        if (
            dataresponses?.loan_type_id &&
            (dataresponses?.loan_type_id === 117 || dataresponses?.loan_type_id === 124)
        ) {
            loandocs =
                allDocs[0]?.docs[loanType] || allDocs[0]?.docs[1];
        } else if (
            dataresponses?.loan_type_id &&
            (dataresponses?.loan_type_id === 115 || dataresponses?.loan_type_id === 122)
        ) {
            loandocs =
                allDocs[0]?.docs[incomeSource] || allDocs[0]?.docs[1];
        } else {
            loandocs = allDocs[0].docs;
        }

        const userDocsArr = dataresponses?.documents?.map((doc) => doc.doc_type) || [];
        let lcount = 0;
        if (userDocsArr.length > 0) {
            Object.entries(loandocs).map(([value]) => !userDocsArr.includes(value) ? lcount++ : null)
        } else {
            lcount = 1;
        }
        setDocLists(loandocs)
        setUserDocArrays(userDocsArr)
    }
    const getMasterList = (loanID, allData) => {
        const sendData = {
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "loan_type_id": loanID
        }
        axios.get(`/loan/getRequiredDocs?${queryString.stringify(sendData)}`)
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    setallDocList(res.data);
                    setDatasMaster(allData, res.data)
                    setLoading(true);
                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)
                }
            })
            .catch((err) => {
                setdataresponse([]);
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
                console.log(errorMessage);
            });
    };
    const getBankDetails = (bankID) => {
        const sendData = {
            "ref_code": dataU?.refCode || getItemSession('refCode')
        }
        axios.get(`/master/bank-details/${bankID}?${queryString.stringify(sendData)}`)
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    setBankDetails(res.data); 
                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)
                }
            })
            .catch((err) => {
                setdataresponse([]);
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
                console.log(errorMessage);
            });
    };
    const getData = () => {
        const sendData = {
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "application_id": applicationId
        }
        axios.get(`/loan/reportDetail?${queryString.stringify(sendData)}`)
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    setdataresponse(res.data)
                    if (res.data.documents.length > 0) {
                        setDocumentsList(res.data.documents);
                    }
                    const preBank =  res.data?.preferred_banks.split(',');
                    if(preBank !== null || preBank !== undefined) {
                        getBankDetails(preBank[0]);
                    }
                    getMasterList(res.data.loan_type_id, res.data);
                    setLoading(true);
                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)
                }

            })
            .catch((err) => {
                setdataresponse([]);
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
                console.log(errorMessage);
            });
    };
    const submitForm = (dd) => {
        const img = !imagefile ? '' : imagefile;
        const formData = new FormData();
        formData.append('doc_file', getValues('doc_file'));
        formData.append('ref_code', dataU?.refCode || getItemSession('refCode'));
        formData.append('application_id', applicationId);
        formData.append('doc_type', getValues('doc_type'));
        formData.append('doc_no', getValues('doc_no'));
        formData.append('doc_password', getValues('doc_password'));
        axios({
            method: 'post',
            url: `/loan/saveDocs`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
        })
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    getData()
                    // window.location.reload(false);
                    const newDocument = {
                        application_id: applicationId,
                        doc_type: getValues('doc_type'),
                        doc_no: getValues('doc_no'),
                        doc_password: getValues('doc_password'),
                        file: res.data.file,
                        date_created: res.data.date_created,
                        id: res.data.id,
                        isFromServer: 1,
                    };
                    setDocumentsList([...documentsList, newDocument]);
                    // Clear form fields
                    setValue('doc_type', "")
                    setValue('doc_no', "")
                    setValue('doc_password', "")
                    setValue('doc_file', "")
                    setImagefile();
                    setSnackBarOption({
                        type: 'S',
                        message: res.message,
                    });
                    setOpen(true)
                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)
                }
            })
            .catch((error) => {
                let errorMessage = error.message;
                if (error?.response?.data?.message) {
                    errorMessage = error.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
            });
    };
    const handleButtonClick = () => {
        setCountdown(5); // Set the initial countdown value

        // Open the modal
        setModalOpen(true);

        // Start the countdown
        const countdownInterval = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        // Redirect to another page after 5 seconds
        setTimeout(() => {
            clearInterval(countdownInterval); // Clear the countdown interval
            // setModalOpen(false); // Close the modal
            // history('/'); // Redirect to another page
        }, 5000);
    };
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    useEffect(() => {
        dispatch(updateProgress(6))
        getData();
    }, []);
    return (
        <div>
            {loading ? (
                <div className="App">
                    <div className='container width_1170'>
                        <div className='card'>
                            <div className='row mt-4 mb-2 '>
                                <LoanProgress />
                            </div>
                            <form method="post" onSubmit={handleSubmit(submitForm)} className="mt-3 px-3 bit-1" id="checkEligibility_form">
                                <div className='row'>
                                    <div className='col-lg-4 '>
                                        <div className="mb-3 position-relative">
                                            <FormControl sx={{ width: '100%' }}   >
                                                <InputLabel id="doc_type">Document Type*</InputLabel>
                                                <Controller
                                                    name="doc_type"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            required
                                                            fullWidth
                                                            labelId="doc_type"
                                                            {...field}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue('doc_type', e.target.value);
                                                            }}
                                                            MenuProps={{
                                                                style: {
                                                                    width: '100px',
                                                                    whiteSpace: 'normal',
                                                                    wordWrap: 'break-word'
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem value="" disabled > <em>None</em> </MenuItem>
                                                            {
                                                                Object.entries(docLists).map(([value, text]) => {
                                                                    return (
                                                                        <MenuItem key={value} value={value} >
                                                                            {text}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                            {errors[`doc_type`] && <p className="error"> {errors[`doc_type`].message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-4 '>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="doc_no"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id={`doc_no`}
                                                        label="Document No.(optional)"
                                                        // value={docNo}
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('doc_no', e.target.value);
                                                        }}
                                                        // onChange={(e) => setDocNo(e.target.value)}
                                                        {...register(`doc_no`, {
                                                            minLength: {
                                                                value: 3,
                                                                message: 'Enter the valid Document No.',
                                                            },
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors[`doc_no`] && <p className="error"> {errors[`doc_no`].message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-4 '>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="doc_password"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id={`doc_password`}
                                                        label="Document Password.(optional)"
                                                        // name={`doc_password`}
                                                        // value={docPassword}
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('doc_password', e.target.value);
                                                        }}
                                                    // onChange={(e) => setDocPassword(e.target.value)}
                                                    />
                                                )}
                                            />
                                            {errors[`doc_password`] && <p className="error"> {errors[`doc_password`].message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                        <ImgDropZone
                                            childToParent={childToParent}
                                            documents={!docFile ? null : docFile}
                                        />
                                    </div>
                                </div>
                                <div className='btn-center m-2'>
                                    <Button color="warning" variant="contained" onClick={() => history(`/loan/previous-loan/` + applicationId)} >
                                        Previous Page
                                    </Button>
                                    <Button color="secondary " type="submit" className="ml-2 " >
                                        <i className="fa fa-upload" aria-hidden="true"></i> Upload
                                    </Button>
                                    <Button color="success" variant="contained" className='ml-2' onClick={() => handleButtonClick()} >
                                        Done
                                    </Button>
                                </div>

                            </form>
                            {documentsList.length > 0 ? (<DocTabel application={documentsList} callBackDelete={callBackDelete} />) : null}
                        </div>
                    </div>
                    <ConfirmationDialogRaw
                        id="ringtone-menu"
                        keepMounted
                        open={modalOpen}
                        onClose={toggleModal}
                        headerContent='Thank You '
                        modalContent={
                            <>
                                <div className="application_status">
                                    <img src={checkPng} width="40"  alt='Success'/>
                                    <p>Your application has successfully submitted</p>
                                    <span>Application id : {applicationId}</span>
                                    <span>Loan Amount: {currencyFormatter(dataresponse?.loan_amount)}</span>
                                    <div className="anchor_btn"> 
                                        <button className='bt'  onClick={() => history('/')} > 
                                            {bankDetail ? <img src={cdnUrl + 'evoluto/bank_logo/' + bankDetail?.logo} width="85" height="30" style={{ marginBottom :'0.2rem'}} alt={bankDetail?.name} /> : 'No Bank Selected '}
                                        </button>
                                        <button className='bt'  onClick={() => history('/')} >
                                            {LOAN_TYPE[dataresponse?.loan_type_id]}
                                        </button>
                                        <button className='bt'  onClick={() => history('/')} >
                                            close
                                        </button>
                                    </div>
                                </div>
                                {/*<div className="row container">
                                    <div className=" col-md-6 container">
                                        <div className="text-center">
                                            <CircularProgressWithLabel value={(5 - countdown) * 20} />
                                        </div>
                                    </div>
                        </div>*/}
                                {/*countdown === 0 ? <p> Countdown completed! Redirecting to Home page...</p> : null*/}
                            </>
                        }
                    />
                    {/*<Modal isOpen={modalOpen} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>Wait while we process your request ....</ModalHeader>
                        <ModalBody className={classes.modalContent}>
                            <div className="row container">
                                <div className=" col-md-6 container">
                                    <div className="text-center">
                                        <CircularProgressWithLabel value={(5 - countdown) * 20} />
                                    </div>
                                </div>
                            </div>
                            {countdown === 0 ? <p> Countdown completed! Redirecting to Home page...</p> : null}

                        </ModalBody>
                                                    </Modal>*/}
                    {snackBarOption.message ? (
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                            anchorOrigin={{ vertical, horizontal }}>
                            <Alert onClose={handleClose} severity={snackBarOption.type === 'E' ? "error" : 'success'} sx={{ width: '100%' }}>
                                {snackBarOption.message}
                            </Alert>
                        </Snackbar>) : null}
                </div>) : (<Loader />)}
        </div>
    )
});

export default UploadDoc;
