/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, Link, useParams, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'reactstrap';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import useAxiosInstance from '../../axiosi-ins';
import Loader from '../../Loader';
import { cdnUrl } from '../../constants/defaultValues';
// import LoansList from '../LoansList';
// import Home from '../Home';
// import CreditCardForm from './CreditCardList';
// import { useNavigate } from 'react-router-dom';
import { getItem, getItemSession, removeItem } from '../../helpers/Utils';
import DataUContext from '../../context/DataUContext';
import { useSelector } from 'react-redux';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function CreditCardDetails() {
  const history = useNavigate();
  const location = useLocation();
  const { productCode } = useParams();
  // const dataU  = useContext(DataUContext); 
  const dataU = useSelector((state) => state.payload);
  const axios = useAxiosInstance();
  const [rowData, setRowData] = React.useState(location.state?.rowData);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setError,
    setValue,
    trigger,
    reset
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      "ref_code": dataU?.refCode || getItemSession('refCode'),
      "pan_no": "",
      "mobile": "",
      "name": "",
      "email": "",
      "pincode": "",
      "product_code": "",
      "cibil_score": "",
      "dob": "",
    }
  });
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'right',
  });
  const [open, setOpen] = React.useState(false);
  const { vertical, horizontal } = state;
  const handleClose = () => {
    setOpen(false)
  };
  const [loading, setLoading] = useState(false);
  const [dataresponse, setdataresponse] = useState({});
  const [snackBarOption, setSnackBarOption] = useState({
    message: null,
    type: 'E',
  });
  const [mobileNumber, setMobileNumber] = useState('');
  const getMaxDate = () => {
    const currentDate = new Date();
    const maxDate = new Date(currentDate);
    maxDate?.setFullYear(currentDate.getFullYear() - 18); // 18 years ago
    return maxDate?.toISOString().split('T')[0];
  };
  const onMobileNumberChange = (event) => {
    setMobileNumber(event.target.value);
  };
  const checkCreditScore = async () => {
    const isValidationSuccessful = await trigger(["name", "mobile", "pan_no", "dob"]);   
    if (isValidationSuccessful) {
      const names = getValues('name').split(" ");
      const sendData = {
        "ref_code": dataU?.refCode || getItemSession('refCode'),
        "fname": names[0],
        "lname": names[1],
        "phone": getValues('mobile'),
        "pan_no": getValues('pan_no'),
        "dob": getValues('dob')
      }
      axios.post(`/creditcard/checkCreditScore`, sendData)
        .then((response) => {
          const { status, data, message } = response;
          if (status === 200) {
            setValue('cibil_score', data.data.score)
            setLoading(true);
          } else {
            setSnackBarOption({
              type: 'E',
              message: response,
            });
            setOpen(true)
          }
        })
        .catch((err) => {
          console.log('err :>> ', err);
          let errorMessage = err.message;
          if (err?.response?.data?.message) {
            errorMessage = err.response.data.message;
          }
          errorMessage = typeof errorMessage === 'string' ? errorMessage : 'An error occurred';
          if (err.response.data?.data?.CCRResponse?.CIRReportDataLst[0]?.Error) {
            errorMessage = err.response.data?.data?.CCRResponse?.CIRReportDataLst[0]?.Error?.ErrorDesc
          }
          setSnackBarOption({
            type: 'E',
            message: errorMessage,
          });
          setOpen(true)
          console.log(errorMessage);
        });
      } else {
        // If validation fails, log the errors
        console.log('Validation errors:', errors);
        Object.keys(errors).forEach(key => {
            console.log(`Field: ${key}, Message: ${errors[key]?.message}`);
        });
    }
  };

  const redirectIF = () => {
    setTimeout(() => {
      window.open(rowData?.redirect_url, '_blank');
    }, 500);
    // history('/apply/credit-cards');
  };
  const captureInsurance = () => {
    // console.log('object :>> ', mobileNumber);
    const data = {
      "mobile": mobileNumber,
      "product_code": dataresponse?.product_code,
      "ref_code": dataU?.refCode || getItemSession('refCode')
    }
    axios.post(`/creditCardLead`, getValues())
      .then((response) => {
        const res = response.data;
        if (res.success === true) {
          setSnackBarOption({
            type: 'S',
            message: res.message,
          });
          setOpen(true)
          function openInNewTab(url) {
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.target = '_blank';
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
          } 
          setTimeout(() => {
            openInNewTab(res.data?.redirect_url);
          }, 500);
          reset({
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "pan_no": "",
            "mobile": "",
            "name": "",
            "email": "",
            "pincode": "",
            "product_code": dataresponse?.product_code,
            "cibil_score": "",
            "dob": "",
          });
          // setTimeout(() => {
          //   window.open(res.data?.redirect_url, '_blank');
          // }, 500);
          // window.location.reload(false);
          // history('/apply/credit-cards');
        } else {
          setSnackBarOption({
            type: 'E',
            message: res.message,
          });
          setOpen(true)
        }
      })
      .catch((error) => {
        let errorMessage = error.message;
        if (error?.response?.data?.message) {
          errorMessage = error.response.data.message;
        }
        console.log(errorMessage);
        setSnackBarOption({
          type: 'E',
          message: errorMessage,
        });
        setOpen(true)
      });
  };
  const getCreditCardDetails = () => {
    axios.get(`productDetails`, { params: { ref_code: dataU?.refCode || getItemSession('refCode'), product_id: productCode } }).then((response) => {
      const { status, data } = response;
      if (status === 200) {
        setdataresponse(data.data[0])
        setValue('product_code', data.data[0].product_code)
        setLoading(true);
      } else {
        setSnackBarOption({
          type: 'E',
          message: data.message,
        });
        setOpen(true)
      }
    }).catch((err) => {
      let errorMessage = err.message;
      if (err?.response?.data?.message) {
        errorMessage = err.response.data.message;
      }
      if (errorMessage.includes('Cannot destructure property')) {
        getCreditCardDetails();
        // window.location.reload(false);
      }else{
        setSnackBarOption({
          type: 'E',
          message: errorMessage,
        });
        setOpen(true)
        
      }
      console.log(errorMessage);
    });
  };
  const handleKeyDown = (event) => {
    event.preventDefault();

    const inputBox = event.target;
    const currentValue = inputBox.value;

    if (event.key === 'Backspace') {
      inputBox.value = currentValue.substring(0, currentValue.length - 1);
    }

    if (event.key.length === 1) {
      const currentLength = currentValue.length;
      const alphaValid = currentLength < 5 || currentLength === 9;
      const numericValid = currentLength >= 5 && currentLength < 9;

      if (alphaValid && event.key >= 'a' && event.key <= 'z' && !event.ctrlKey) {
        inputBox.value += event.key.toUpperCase();
      } else if (alphaValid && event.key >= 'A' && event.key <= 'Z' && !event.ctrlKey) {
        inputBox.value += event.key;
      } else if (numericValid && event.key >= '0' && event.key <= '9' && !event.ctrlKey) {
        inputBox.value += event.key;
      }
    }
    setValue('pan_no',inputBox.value);

  };
  useEffect(() => {
    getCreditCardDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [dataU?.plugin_accessibility,getItemSession('credit_score_permission')]);

  return (
    <div>{loading ? (
      <div className="App">
        <div className="main_sec">
          <div className="insurance1_div">
            <div className="section_four">
              <div className="section_four_inner">
                <div className="left1">
                  {/*<button
                    className={`top_apply_btn`}
                    onClick={() => { history(-1) }}
                    >
                    Back
    </button>
                  {dataresponse && dataresponse?.product_code === 'IF' ? <button
                    className={`top_apply_btn mt-2`}
                    onClick={redirectIF}
                    style={{marginTop:'83px'}}
                  >
                    Apply
                  </button> :*/}
                  <form name="frm-insurance" method="post" onSubmit={handleSubmit(captureInsurance)} >
                    <input type="hidden" name="uaction" id="uaction" value="product-description" />
                    <div className="form_mbNum" >
                      <div className='row'>
                        {/*<div className='col-lg-6'>
                          <div className="position-relative">
                            <Controller
                              name="pan_no"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  id="pan_no"
                                  label="Pan Card*"
                                  variant="outlined"
                                  className={errors.pan_no && 'mb-0'}
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    setValue('pan_no', e.target.value);
                                  }}
                                  onKeyDown={handleKeyDown}
                                  InputProps={{
                                    inputProps: {
                                      pattern: '[A-Za-z]{5}[0-9]{4}[A-Za-z]',
                                    },
                                  }}
                                  {...register('pan_no', {
                                    required: 'Pan Card is required',
                                    maxLength: {
                                      value: 10,
                                      message: 'Name was Too High',
                                    },
                                    pattern: {
                                      value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                                      message: 'Enter a valid PAN Card (e.g., XXXXX1234X)',
                                    },
                                  })}
                                />
                              )}
                            />
                            {errors.pan_no && <p className="error mb-1"> {errors.pan_no.message}</p>}
                          </div>
                        </div>*/}
                        <div className='col-lg-6'>
                          <div className="">
                            <div className="position-relative">
                              <Controller
                                name="mobile"
                                control={control}
                                render={({ field }) => (
                                  <TextField
                                    fullWidth
                                    id="mobile"
                                    label="Mobile*"
                                    variant="outlined"
                                    type="number"
                                    className={errors.mobile && 'mb-0'}
                                    pattern="[5-9]{1}[0-9]{9}"
                                    {...field}
                                    onChange={(e) => {
                                      field.onChange(e);
                                      setValue('mobile', e.target.value);
                                    }}
                                    {...register('mobile', {
                                      required: 'Mobile no is required',
                                      pattern: {
                                        value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                                        message:
                                          'Entered value Mobile Number in valid format',
                                      },
                                      minLength: {
                                        value: 10,
                                        message: 'Enter the valid Phone Number',
                                      },
                                      maxLength: {
                                        value: 10,
                                        message: 'Enter the valid Phone Number',
                                      },
                                      type: Number,
                                    })}
                                  />
                                )}
                              />
                              {errors.mobile && <p className="error mb-1"> {errors.mobile.message}</p>}
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className="position-relative">
                            <Controller
                              name="name"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  id="name"
                                  label="Name*"
                                  variant="outlined"
                                  className={errors.name && 'mb-0'}
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    setValue('name', e.target.value);
                                  }}
                                  {...register('name', {
                                    required: 'Name as per PAN is required',
                                    minLength: {
                                      value: 3,
                                      message: 'Minimum 3 Characters Required for Name',
                                    },
                                    maxLength: {
                                      value: 30,
                                      message: 'Name was Too High',
                                    },
                                    pattern: {
                                      value: /^[a-zA-z ]{3,30}$/,
                                      message: 'Enter a valid Name',
                                    },
                                  })}
                                />
                              )}
                            />
                            {errors.name && <p className="error mb-1"> {errors.name.message}</p>}
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className="position-relative">
                            <Controller
                              name="email"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  id="email"
                                  label="Email"
                                  variant="outlined"
                                  className={errors.email && 'mb-0'}
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    setValue('email', e.target.value);
                                  }}
                                  {...register('email', {
                                    // required: 'Email is required',
                                    pattern: {
                                      value: /\S+@\S+\.\S+/,
                                      message: 'Entered value does not match email format',
                                    },
                                  })}
                                />
                              )}
                            />
                            {errors.email && <p className="error mb-1"> {errors.email.message}</p>}
                          </div>
                        </div>
                        {/*<div className='col-lg-6'>
                          <div className="position-relative">
                            <Controller
                              name="dob"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  type='date'
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  id="dob"
                                  label="Date Of Birth"
                                  variant="outlined"
                                  className={errors.dob && 'mb-0'}
                                  max={getMaxDate()}
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    setValue('dob', e.target.value);
                                  }}
                                  {...register('dob', {
                                    // required: 'Date Of Birth is required',
                                    validate: (value) => {
                                      if (value) {
                                        const dobDate = new Date(value);
                                        const currentDate = new Date();
                                        const minDate = new Date(currentDate);
                                        minDate.setFullYear(currentDate.getFullYear() - 18);

                                        if (dobDate > minDate) {
                                          return 'You must be at least 18 years old.';
                                        }
                                      }
                                      return true;
                                    }
                                  })}
                                />
                              )}
                            />
                            {errors.dob && <p className="error mb-1"> {errors.dob.message}</p>}
                          </div>
                        </div>*/}
                        <div className='col-lg-6'>
                          <div className="position-relative">
                            <Controller
                              name="pincode"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  id="pincode"
                                  label="Pin Code"
                                  type='number'
                                  className={errors.pincode && 'mb-0'}
                                  variant="outlined"
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    setValue('pincode', e.target.value);
                                  }}
                                  {...register('pincode', {
                                    // required: 'Pin Code is required',
                                    pattern: {
                                      value: /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/,
                                      message: 'Entered the valid Zipcode',
                                    },
                                    minLength: {
                                      value: 6,
                                      message: 'Zipcode should be 6 in length',
                                    },
                                    maxLength: {
                                      value: 6,
                                      message: 'Zipcode should be 6 in length',
                                    },
                                    type: Number,
                                  })}
                                />
                              )}
                            />
                            {errors.pincode && <p className="error mb-1"> {errors.pincode.message}</p>}
                          </div>
                        </div>
                        {/*<div className='col-lg-12'>
                          <div className="position-relative check_credit_score">
                            <Controller
                              name="cibil_score"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  fullWidth
                                  type='number'
                                  id="cibil_score"
                                  label="Credit Score*"
                                  variant="outlined"
                                  className={errors.cibil_score && 'mb-0'}
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    setValue('cibil_score', e.target.value);
                                  }}
                                  {...register('cibil_score', {
                                    required: 'Credit Score is required',
                                    validate: {
                                      min: (value) => Number(value) >= 300 || 'Minimum value is 300',
                                      max: (value) => Number(value) <= 900 || 'Maximum value is 900',
                                    },
                                    type: Number,
                                  })}
                                />
                              )}
                            />
                            {errors.cibil_score && <p className="error mb-1"> {errors.cibil_score.message}</p>}
                            {getItemSession('credit_score_permission') && getItemSession('credit_score_permission') !== undefined && getItemSession('credit_score_permission') !== null && parseInt(getItemSession('credit_score_permission')) === 1 ?
                              <Button className="check_credit_score_btn1" style={{ background: 'transparent' }} onClick={checkCreditScore}>
                                Check Credit Score
                              </Button> : null}
                          </div>
                        </div>*/}
                      </div>
                      <button
                        type='submit'
                        className={`top_apply_btn }`}
                      >
                        Apply
                      </button>
                    </div>
                  </form>
                </div>
                <div className="rhgt1">
                  <img src={dataresponse?.top_banner} alt="Product Banner" />
                </div>
                <div style={{ clear: 'both' }}></div>
              </div>
            </div>
            <div className="cc_section_two">
              <div className="cc_section_twoInner">
                <div className="cc_section_twoInner_img1">
                  <img src={cdnUrl + 'images/terms-banner.png'} alt="Terms Banner" />
                </div>
                <div className="cc_section_twoInner_txt1">
                  <h3>
                    <img src={cdnUrl + 'images/terms-and-conditions.png'} alt="Terms and Conditions Icon" />{' '}
                    <span>Terms & Conditions</span>
                  </h3>
                  <div dangerouslySetInnerHTML={{ __html: dataresponse?.tnc }} />
                </div>
                <div style={{ clear: 'both' }}></div>
              </div>
            </div>

            <div className="cc_section_three">
              <div className="cc_section_threeInner">
                <div className="lft1">
                  <h3>
                    <img src={cdnUrl + 'images/how-it-works-icon.png'} width="30" alt="How it Works Icon" />{' '}
                    <span>How it works</span>
                  </h3>
                  <div dangerouslySetInnerHTML={{ __html: dataresponse?.how_its_work }} />
                </div>
                <div className="rght1">
                  <iframe
                    title="YouTube video player"
                    width="560"
                    height="315"
                    src={dataresponse?.training_video}
                    // frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <div className="downloadPdfBtn">
                    {dataresponse?.detail_content_file && (
                      <Link
                        target="_blank" rel="noreferrer"
                        href={dataresponse?.detail_content_file}
                        className="btn btn-success  statusmsg push-right"
                      >
                        <img src={cdnUrl + 'images/download-pdf.png'} alt="Download PDF Icon" />
                        Download Brochure
                      </Link>
                    )}
                  </div>
                </div>
                <div style={{ clear: 'both' }}></div>
              </div>
            </div>
          </div>
        </div>
        {snackBarOption.message ? (
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}>
            <Alert onClose={handleClose} severity={snackBarOption.type === 'E' ? "error" : 'success'} sx={{ width: '100%' }}>
              {snackBarOption.message}
            </Alert>
          </Snackbar>

        ) : null}
      </div>) : (<Loader />)}
    </div>
  )
}
export default CreditCardDetails;
