/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useReducer, useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { setFormData } from '../../redux/formDataSlice';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { Button, Input } from 'reactstrap';
import FormControl from '@mui/material/FormControl';
// import ReactDatePicker from 'react-datepicker';
import DatePicker from "react-datepicker";
import { useNavigate, Link, useParams } from 'react-router-dom';
import { balanceTransfer, cdnUrl, loanAgainstProperty, bissnessLoan, personalLoan } from '../../constants/defaultValues';
import Loader from '../../Loader'

import useAxiosInstance from '../../axiosi-ins';
import "../../App.css";
import LoanProgress from '../LoanProgress';
import { updateProgress } from '../../redux/actions';
import { currencyFormatter, getItem, getItemSession, removeItem, setItem } from '../../helpers/Utils';
import TopButton from '../BackButton';
import useLocalStorageChange from '../../useLocalStorageChange';
import voiceAlert from '../../VoiceAlert';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import checkPng from '../../assets/img/check.png';
import DataUContext from '../../context/DataUContext';
// import BankGrids from './BankGrids';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function ConfirmationDialogRaw(props) {
    const { onClose, open, headerContent,
        modalContent, ...other } = props;
    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onClose();
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={open}
            {...other}
        >
            <DialogTitle>{headerContent}</DialogTitle>
            <DialogContent dividers>
                {modalContent}
            </DialogContent>
            {/*<DialogActions>
                <Button onClick={handleOk}>Ok</Button>
    </DialogActions>*/}
        </Dialog>
    );
}

function CreditCardList() {
    const dispatch = useDispatch();
    const axios = useAxiosInstance();
    // useLocalStorageChange();  
    const dataU = useSelector((state) => state.payload);
    // const dataU = useContext(DataUContext);
    const userData = getItem('token') !== undefined && getItem('token') !== null ? jwtDecode(getItem('token')) : { role: 0 }
    // const userData = getItem('token') !== null ? jwtDecode(getItem('token')) : { role: 0 }
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'right',
    });
    const [open, setOpen] = React.useState(false);
    const { vertical, horizontal } = state;
    const handleClose = () => {
        setOpen(false)
    };
    const { applicationId, loanTy } = useParams();
    const [loading, setLoading] = useState(false);
    const [cardList, setCardList] = useState({});
    const [zipCode, setZipCode] = useState(null);
    const [dataresponse, setdataresponse] = useState([]);
    const [snackBarOption, setSnackBarOption] = useState({
        message: null,
        type: 'E',
    });
    const [modal, setModal] = useState(false);
    const toggleM = () => setModal(!modal);
    const history = useNavigate();
    const [visible, setVisible] = useState(true);

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        setError,
        setValue
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onBlur',
        defaultValues: {
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "other_info": dataU?.other_info,
            "device_id": dataU?.device_id,
            "loan_type_id": "",
        }
    });
    const getCreditCardList = async (zip = null) => {
        const Data = {
            ref_code: dataU?.refCode || getItemSession('refCode'),
            service_id: 1
        }
        if (zip) {
            Data.zip_code = zip
        }
        axios.get(`serviceProducts`, { params: Data, }).then((response) => {
            const { status, data } = response;
            if (status === 200) {
                setCardList(data.data)
                setLoading(true);
            }
        }).catch((err) => {
            let errorMessage = err.message;
            if (err?.response?.data?.message) {
                errorMessage = err.response.data.message;
            }
            setSnackBarOption({
                type: 'E',
                message: errorMessage,
            });
            console.log(errorMessage);
            getCreditCardList();
        });
    };
    const onInputHandler = (e) => {
        e.target.value = e.target.value.replace(/[^0-9.]/g, '');
        e.target.value = e.target.value.replace(/(\..*)\./g, '$1');
    };

    const onKeyUpSearch = (event) => {
        // Add your onKeyUpSearch logic here
        // For example, you can check if the Enter key is pressed
        if (event.key === 'Enter') {
            searchCreditCard();
        }
    };

    const searchCreditCard = () => {
        console.log('test :>> ');
        // Add your searchCreditCard logic here
        // This function is called when the "Search" button is clicked
    };
    useEffect(() => {
        getCreditCardList();
        // if (userData?.role === 16) {
        //     history(`/report/credit-cards`)
        // }
    }, []);

    return (
        <div>
            {loading ? (<div className="App">
                <div className="creditCardPincode">
                    <div className="creditCardPincode_inner">
                        {/*dataU?.plugin_accessibility && (dataU?.plugin_accessibility === "Darcy" || dataU?.plugin_accessibility === 'Eliza')  || getItemSession('plugin_accessibility') && (getItemSession('plugin_accessibility') === "Darcy" || getItemSession('plugin_accessibility') === 'Eliza') ?
                    <button
                        className={`btn btn-success mt-4`}
                        onClick={() => { history(`/report/credit-cards`) }}
                    >
                        Back
                    </button> :null */}
                        <div className="form_div">
                            <label>Check if card is available in your city </label>
                            <input
                                type="number"
                                id="searchCreditCard"
                                placeholder="enter pincode"
                                maxLength="6"
                                onInput={(event) => {
                                    event.preventDefault();
                                    const inputValue = event.target.value.replace(/[^0-9]/g, ''); // Allow only numeric values
                                    if (inputValue.length <= 6) {
                                        setZipCode(inputValue);
                                        // if (inputValue.length === 6) {
                                        //     getCreditCardList(inputValue);
                                        // }
                                        if (inputValue.length === 0) {
                                            setZipCode(null)
                                            getCreditCardList();
                                        }
                                    }
                                }}
                                value={zipCode || ''}
                            />
                            <button type="button" className={`btn btn-success `}
                                onClick={(event) => {
                                    // setZipCode(null);
                                    getCreditCardList(zipCode)
                                }}  >
                                Search
                            </button>
                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>
                </div>
                <div style={{ clear: 'both' }}></div>
                <div className="list_page_sec">
                    <div className="list_page_sec_inner">
                        <div className="all_rows">
                            {cardList?.length > 0 ?
                                cardList.map((list) => {
                                    return (
                                        <div key={list.id} className="first_row">
                                            <a href="#" onClick={() => { 
                                                history(`/credit-cards-details/${list.id}`, { state: { rowData: list } }) }} target="">
                                                <div className="left_img">
                                                    <img src={list.image} alt={list.name} />
                                                </div>
                                                <div className="right_text">
                                                    <h4>{list.name}</h4>
                                                    <p>{list.description} </p>
                                                </div>
                                                {parseInt(list.flag_recommended) === 1 ?
                                                    <div className="recomended">
                                                        <img src={cdnUrl + "images/recomended-icon.svg"} alt="Recommended" />
                                                    </div> : null}
                                                {list.availability ?
                                                    <div className="eligibilityDiv">
                                                        <img src={cdnUrl + "images/eligible-check.png"} alt="Eligible" />
                                                        Eligible
                                                    </div> : null}
                                                <div style={{ clear: 'both' }}></div>
                                            </a>
                                        </div>)
                                }) : null}

                            <div style={{ clear: 'both' }}></div>
                        </div>
                    </div>
                    {snackBarOption.message && !modal ? (
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                            anchorOrigin={{ vertical, horizontal }}>
                            <Alert onClose={handleClose} severity={snackBarOption.type === 'E' ? "error" : 'success'} sx={{ width: '100%' }}>
                                {snackBarOption.message}
                            </Alert>
                        </Snackbar>

                    ) : null}
                </div>
            </div>) : (<Loader />)}
        </div>
    );
}

export default CreditCardList; 