/* eslint-disable no-unused-vars */
import { createStore, applyMiddleware, compose } from 'redux';
import formDataReducer from './formDataSlice'; 
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

export function configureStore(initialState) {
  const store = createStore(
    reducers,
    initialState,
    compose(applyMiddleware(...middlewares)),
  );

  sagaMiddleware.run(sagas);
  // let isMastersListFetched = false;  

// if (!isMastersListFetched) {
//   store.dispatch({ type: 'FETCH_MASTERS_LIST' }); 
//   isMastersListFetched = true;  
// }


  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}