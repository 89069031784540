/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useForm, Controller } from 'react-hook-form';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useNavigate } from 'react-router-dom';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'primeicons/primeicons.css';
import Loader from '../../Loader'

import useAxiosInstance from '../../axiosi-ins';
import { getItem, getItemSession } from '../../helpers/Utils';
// import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import { cardBank, dateType } from '../../constants/defaultValues';
import { useSelector } from 'react-redux';
// import BankGrids from './BankGrids';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CreditCardReport() {
    const axios = useAxiosInstance();
    const dataU = useSelector((state) => state.payload);
    // const dataU = useContext(DataUContext);
    const userData = getItem('token') !== null ? jwtDecode(getItem('token')) : { role: 0 }
    const blue = {
        100: '#DAECFF',
        200: '#b6daff',
        400: '#3399FF',
        500: '#007FFF',
        600: '#0072E5',
        900: '#003A75',
    };

    const grey = {
        50: '#F3F6F9',
        100: '#E5EAF2',
        200: '#DAE2ED',
        300: '#C7D0DD',
        400: '#B0B8C4',
        500: '#9DA8B7',
        600: '#6B7A90',
        700: '#434D5B',
        800: '#303740',
        900: '#1C2025',
    };
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'right',
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        getValues,
        setValue,
        reset
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onBlur',
        defaultValues: {
            "date_type": "application_date",
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "mobile": "",
            "bank": "",
            "from_date": "",
            "to_date": "",
            "status": "",
        }
    });
    const dt = useRef(null);
    const [open, setOpen] = React.useState(false);
    const { vertical, horizontal } = state;
    const handleClose = () => {
        setOpen(false)
    };
    const [valueTab, setValueTab] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        getCreditReports(newValue);
        setValueTab(newValue);
    }
    const [loading, setLoading] = useState(false);
    const [dataresponse, setdataresponse] = useState([]);
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [snackBarOption, setSnackBarOption] = useState({
        message: null,
        type: 'E',
    });
    const [pagination, setPagination] = useState({
        current_page: 1,
        last_page: 1,
        per_page: 10,
        total: 0,
    });
    const [balanceFrozen, setBalanceFrozen] = useState(false);

    const history = useNavigate();
    const [statuses] = useState(["INITIATED", "LOGIN", "IN PROCESS", "PENDING", "APPROVED", "DISBURSED", "REJECTED", "REVOKED"]);
    const [incomeSource] = useState({ "1": "Salaried", "2": "Business", "3": "Others" });
    const [subStatus] = useState({ 1: "Yes", 0: "No", null: "" });
    const getSeverity = (status) => {
        switch (status) {
            case 'INITIATED':
                return 'danger';

            case 'LOGIN':
                return 'success';

            case 'IN PROCESS':
                return 'info';

            case 'PENDING':
                return 'warning';
            case 'APPROVED':
                return 'warning';
            case 'DISBURSED':
                return 'warning';
            case 'REJECTED':
                return 'info';
            case 'REVOKED':
                return 'success';
            default:
                return null;
        }
    };
    const getSeveritysub = (status) => {
        switch (status) {
            case 1:
                return 'danger';
            case 0:
                return 'success';
            default:
                return null;
        }
    };
    const statusBodyTemplate = (rowData) => {
        return <Tag value={statuses[rowData.status]} severity={getSeverity(statuses[rowData.status])} />;
    };
    const incomeSourceBody = (rowData) => {
        return <Tag value={incomeSource[rowData.income_source]} />;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };
    const handleBalance = () => {
        setBalanceFrozen(!balanceFrozen)
    };
    // const rowClassName = (rowData) => {
    //     return partnerDependency?.partnerDependencyapplications.includes(rowData.application_id) ? 'highlighted-row' : '';
    // };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end filter_search_box"> 
                <Button type="button" className='ml-2' icon="pi pi-filter-slash" label="&nbsp; Clear" outlined onClick={clearFilter} />
                {userData.role === 3 ? <Button icon="pi pi-plus-circle" label=" &nbsp; Apply Credit Card" className="apply_middle_filter p-button p-component p-button-outlined" outlined onClick={handleAdd} /> : null}

                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };
    const handleReset = () => {
        const iniRefCode = getValues('ref_code');

        // Reset all fields except 'status'
        reset(
            {
                // Provide default values for fields you want to reset
                // Here, we set default values for fields other than 'date filter Type'
                date_type: 'application_date',
            },
            {
                // Optional: To prevent re-rendering the form, set isDirty to false
                isDirty: false,
            }
        );
        // Restore the original value of 'status'
        setValue('ref_code', iniRefCode);
        getCreditReports();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        // console.log('value :>> ', value);
        let _filters = { ...filters };

        if (_filters && _filters['global']) {
            _filters['global'].value = value;
        }

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            application_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            mobile: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            application_date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            status: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            application_stage: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            retailer: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            bank: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        });
        setGlobalFilterValue('');
    };
    function getCreditReports(page = 0) {
        let val = "all"
        if (page === 1) {
            val = "act"
        } else if (page === 2) {
            val = "app"
        } else if (page === 3) {
            val = "rej"
        }
        axios.get(`report/creditCard`, { params: getValues() }).then((response) => {
            const res = response.data;
            if (res.success === true) {
                // console.log('res.data :>> ', res.data);
                setdataresponse(res.data)
                setLoading(true);
            } else {
                setSnackBarOption({
                    type: 'E',
                    message: res.message,
                });
                setOpen(true)
            }

        })
            .catch((err) => {
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
                setTimeout(() => {
                    window.location.reload(false);
                }, 1500);
                console.log(errorMessage);
                getCreditReports();
            });
    };
    const handleAdd = () => {
        history('/apply/credit-cards');
    };
    const exportCSV = () => {
        let dd = []
        const exportedDataTocsv = dataresponse.map((item) => (
            dd.push(
                {
                    "Application Name": item.application_name,
                    "Mobile": item.mobile,
                    "Application Date": item.application_date,
                    "Status": item.status,
                    "Application Stage": item.application_stage,
                    "Approval Date": item.approval_date,
                    "Agent": item.retailer,
                    "Bank": item.bank,
                }
            )
        ));
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(dd);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'Credit Card Application');
        });
        // if (exportedData.length > 0 && dt.current) {
        //     dt.current.exportCSV({ selectionOnly: false, customData: exportedData });
        // }
        // dt.current.exportCSV({ selectionOnly: false, customData: exportedData }); 
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };
    const rightToolbarTemplate = () => {
        return <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />;
    };
    const statusBody = (rowData) => {
        return <span className="font-bold">{ rowData.status || 'Initiated'}</span>;
    }; 
    useEffect(() => {
        setLoading(false)
        initFilters()
        getCreditReports();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);
    const onPageChange = (event) => {
        getCreditReports(event.page + 1); // PrimeReact uses 0-based index, Laravel expects 1-based index
    };
    const header = renderHeader();
    const paginatorRight = <Button type="button" icon="pi pi-download" text onClick={exportCSV} />;
    const renderTable = () => {
        return (
            <div>
                <Accordion activeIndex={[0]}>
                    <AccordionTab header={
                        <span className="flex align-items-center gap-2 w-full m-2">
                            <span className="font-bold white-space-nowrap">Filter </span>
                        </span>
                    }>
                        <form method="post" onSubmit={handleSubmit(getCreditReports)} className="mt-3 px-3 bit-1" id="checkEligibility_form">
                            <div className='row'>
                                <div className='col-lg-3'>
                                    <div className="mb-3 position-relative">
                                        <FormControl sx={{ width: '100%' }}   >
                                            <InputLabel id="bank">Select Bank</InputLabel>
                                            <Controller
                                                name="bank"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        fullWidth
                                                        labelId="bank"
                                                        id="bank"
                                                        label="Select Bank"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('bank', e.target.value);
                                                        }}
                                                    >
                                                        <MenuItem value="" disabled > <em>None</em> </MenuItem>
                                                        {Object.entries(cardBank).map(([key, value]) => (
                                                            <MenuItem key={key} value={key}>
                                                                {value}
                                                            </MenuItem>
                                                        ))
                                                        }
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>
                                        {errors.bank && <p className="error"> {errors.bank.message}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-2'>
                                    <div className="mb-3 position-relative">
                                        <Controller
                                            name="mobile"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    id="mobile"
                                                    label="Mobile"
                                                    type='number'
                                                    variant="outlined"
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue('mobile', e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className="mb-3 position-relative">
                                        <FormControl sx={{ width: '100%' }}   >
                                            <InputLabel id="date_type">Date Filter Type*</InputLabel>
                                            <Controller
                                                name="date_type"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        fullWidth
                                                        labelId="date_type"
                                                        id="date_type"
                                                        label="Select Date type"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('date_type', e.target.value);
                                                        }}
                                                        {...register('date_type', {
                                                            required: 'Date Type is required',
                                                        })}
                                                    >
                                                        <MenuItem value="" disabled > <em>None</em> </MenuItem>
                                                        {Object.entries(dateType).map(([key, value]) => (
                                                            <MenuItem key={key} value={key}>
                                                                {value}
                                                            </MenuItem>
                                                        ))
                                                        }
                                                    </Select>

                                                )}
                                            />
                                        </FormControl>
                                        {errors.date_type && <p className="error"> {errors.date_type.message}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-2'>
                                    <div className="mb-3 position-relative">
                                        <Controller
                                            name="from_date"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    type='date'
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    id="from_date"
                                                    label="Date From"
                                                    variant="outlined"
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue('from_date', e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.from_date && <p className="error"> {errors.from_date.message}</p>}
                                    </div>
                                </div>
                                <div className='col-lg-2'>
                                    <div className="mb-3 position-relative">
                                        <Controller
                                            name="to_date"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    type='date'
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    id="to_date"
                                                    label="Date To"
                                                    variant="outlined"
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setValue('to_date', e.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.to_date && <p className="error"> {errors.to_date.message}</p>}
                                    </div>
                                </div>
                                </div>
                                <div className="row">                                
                                    <div className='col-lg-3'>
                                        <Button type="submit" className='mb-2' icon="pi pi-search" label="&nbsp; Search" outlined />
                                        <Button type="button" className='ml-2 mb-2' icon="pi pi-filter-slash" label="&nbsp; Clear" outlined onClick={handleReset} />
                                    </div>
                                </div>
                        </form>
                    </AccordionTab>
                </Accordion> 
                
                <DataTable ref={dt} value={dataresponse} scrollable scrollHeight="400px" size='small' paginator filterDisplay='menu' rows={10} dataKey="id"
                    filters={filters} globalFilterFields={['application_name', 'mobile', 'application_date', 'status', 'application_stage','retailer', 'bank']} header={header}
                    emptyMessage="No Credit Card Record  found." globalFilter={globalFilterValue} sortMode='multiple' removableSort paginatorRight={paginatorRight}>

                    <Column field="application_name" header="Application Name" filter sortable filterPlaceholder="Search by Application Name"  />

                    <Column field="mobile" header="Mobile" filter sortable filterPlaceholder="Search by Mobile" style={{ minWidth: '8rem' }} />
                    <Column field="application_date" header="Application Date" filter sortable filterPlaceholder="Search by Application Date" style={{ minWidth: '8rem' }} />
                    {/*<Column field="pan" header="Pan" filter sortable filterPlaceholder="Search by pan" style={{ minWidth: '8rem' }} />*/}
                    <Column field="status" header="Status" filter sortable filterPlaceholder="Search by Status " style={{ mixWidth: '5rem' }} body={statusBody} />
                    <Column field="application_stage" header="Application Stage" filter sortable filterPlaceholder="Search by Application Stage" style={{ minWidth: '8rem' }} />
                    <Column field="approval_date" sortable header="Approval Date" style={{ minWidth: '8rem' }} />
                    <Column field="retailer" sortable header="Agent Name" style={{ minWidth: '8rem' }} />
                    <Column field="bank" header="Bank"  style={{ minWidth: '8rem' }} alignFrozen="right" />
                </DataTable>
            </div>
        )
    };
    return (
        <div>
            {loading ? (<div className="App">
                <div className='container api_home_page'>
                    <div className="card p-0 m-1">
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={valueTab} onChange={handleChangeTab} variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile aria-label="All Status Of Loans">
                                    <Tab label="All Credit Cards" {...a11yProps(0)} />
                                    {/* <Tab label="Active Loans" {...a11yProps(1)} />
                                    <Tab label="Approved Loans" {...a11yProps(2)} />
                                    <Tab label="Rejected Loans" {...a11yProps(3)} /> */}
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={valueTab} index={0}>
                                {renderTable()}
                            </CustomTabPanel>
                            {/* <CustomTabPanel value={valueTab} index={1}>
                                {renderTable()}
                            </CustomTabPanel>
                            <CustomTabPanel value={valueTab} index={2}>
                                {renderTable()}
                            </CustomTabPanel>
                            <CustomTabPanel value={valueTab} index={3}>
                                {renderTable()}
                                </CustomTabPanel> */}
                        </Box>

                    </div>
                    <div>
                        {snackBarOption.message ? (
                            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                                anchorOrigin={{ vertical, horizontal }}>
                                <Alert onClose={handleClose} severity={snackBarOption.type === 'E' ? "error" : 'success'} sx={{ width: '100%' }}>
                                    {snackBarOption.message}
                                </Alert>
                            </Snackbar>) : null}
                    </div>
                </div>
            </div>) : (<Loader list={true} />)}
        </div>
    );


}



export default CreditCardReport; 