import { put, takeLatest, call ,all } from 'redux-saga/effects';
import axios from '../axiosi-ins';
import { getItem } from '../helpers/Utils';

// ...

// Saga to fetch masters list
function* fetchMastersListSaga() {
  if (getItem('token')) {
    axios.defaults.headers.token = getItem('token');
    try {
      const response = yield call(axios.get, 'form/masters');
      yield put({ type: 'SET_MASTERS_LIST', payload: response.data });
    } catch (error) {
      console.error(error);
    }
  }
}

// Watcher saga
function* watchFetchMastersListSaga() {
  yield takeLatest('FETCH_MASTERS_LIST', fetchMastersListSaga);
}

// Include the watcher saga in your root saga
export default function* rootSaga() {
  yield all([
    // ...
    watchFetchMastersListSaga(),
  ]);
}