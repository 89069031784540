import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from 'reactstrap';
import uplaod from '../assets/img/upload.svg';

function ImgDropZone(props) {
  const { childToParent, documents } = props;

  const [files, setFiles] = useState([]);
  const [error, setError] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    // Check the file type and size here
    const maxSize = 2097152; // 2MB

    const filteredFiles = acceptedFiles.filter((file) => {
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];

      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        return true;
      }

      return false;
    });

    if (filteredFiles.length === 0) {
      setError(true);
      childToParent('error');
      return;
    }

    setError(false);
    setFiles(
      filteredFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );

    childToParent(filteredFiles);
  }, [childToParent]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/jpg, image/png, application/pdf',
    onDrop,
  });

  const thumbs =
    files === 'error'
      ? ''
      : files?.map((file) => (
          <div key={file.name} className="profilepicUp position-relative">
            <img
              src={file.preview}
              alt="profile"
              className="img-fluid img-responsive profilepic"
            />
            <Button
              onClick={() => {
                setFiles([]);
              }}
            >
              Remove
            </Button>
          </div>
        ));

  const pic = () => {
    if (thumbs?.length === 0 && !documents) {
      return <i className="far fa-user-circle" />;
    }
    if (thumbs.length === 0 && documents) {
      return (
        <img
          src={typeof documents === 'object' ? documents?.preview : documents}
          alt=""
        />
      );
    }
    return thumbs;
  };

  return (
    <div className="bit-1 p-0 text-center">
      <div className="bit-25 profilepicUp">{pic()}</div>
      <div {...getRootProps({ className: 'dropzone bit-75' })}>
        <input {...getInputProps()} />
        <p>
          <img src={uplaod} alt="" style={{ height: '50px' }} />
        </p>
        <h4>Drag your picture here to Change</h4>
        <p>or click to browse</p>
      </div>

      {error ? (
        <div
          style={{
            display: error ? 'block' : 'none',
          }}
          className=" bg-danger bit-1 text-white imgalert p-2"
        >
          {error}
          <i className="fas fa-exclamation-triangle pr-2" />
          <span> Image size should be under 2MB and in PDF, JPG, JPEG, or PNG format</span>
        </div>
      ) : null}
    </div>
  );
}

export default ImgDropZone;
