/* eslint-disable no-unused-vars */
import React, { useState, useReducer, useEffect, useMemo,useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { setFormData } from '../redux/formDataSlice';
import { setMasterData } from '../redux/masterDataSlice'; 
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip'; 
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert'; 
import { Button } from 'reactstrap';
import FormControl from '@mui/material/FormControl';
import useAxiosInstance from '../axiosi-ins';
import Loader from '../Loader'
import LoanProgress from './LoanProgress';
import { updateProgress } from '../redux/actions';
import { getItem, getItemSession, setItem } from '../helpers/Utils';
import TopButton from './BackButton';
import DataUContext from '../context/DataUContext';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Reference = React.memo(() => {
    const axios = useAxiosInstance();
    const blue = {
        100: '#DAECFF',
        200: '#b6daff',
        400: '#3399FF',
        500: '#007FFF',
        600: '#0072E5',
        900: '#003A75',
    };

    const grey = {
        50: '#F3F6F9',
        100: '#E5EAF2',
        200: '#DAE2ED',
        300: '#C7D0DD',
        400: '#B0B8C4',
        500: '#9DA8B7',
        600: '#6B7A90',
        700: '#434D5B',
        800: '#303740',
        900: '#1C2025',
    };

    const Textarea = styled(BaseTextareaAutosize)(
        ({ theme }) => `
        width: 320px;
        font-family: IBM Plex Sans, sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 8px 12px;
        border-radius: 8px;
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
        background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
        box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    
        &:hover {
          border-color: ${blue[400]};
        }
    
        &:focus {
          border-color: ${blue[400]};
          box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
        }
    
        // firefox
        &:focus-visible {
          outline: 0;
        }
      `,
    );

    const { applicationId } = useParams();
    const dispatch = useDispatch();
    const history = useNavigate();
    // const dataU  = useContext(DataUContext); 
    const dataU  = useSelector((state) => state.payload); 
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'right',
    });
    const [open, setOpen] = React.useState(false);
    const { vertical, horizontal } = state;
    const handleClose = () => {
        setOpen(false)
    };
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isSubmitted, isSubmitting },
        getValues,
        setError,
        setValue
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onBlur',
        defaultValues: {
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "application_id": applicationId,
            "is_draft": 0,
            "reference1": {
                "name": "",
                "relationship": "",
                "email": "",
                "phone": "",
                "address": ""
            },
            "reference2": {
                "name": "",
                "relationship": "",
                "email": "",
                "phone": "",
                "address": ""
            }
        }
    });
    const [mastersList, setMastersList] = useState([]);
    const formData = useSelector((state) => state.formData.dataBasic);
    const [loading, setLoading] = useState(false);
    const [dataresponse, setdataresponse] = useState([]);
    const [snackBarOption, setSnackBarOption] = useState({
        message: null,
        type: 'E',
    });
    
    const getMasterList = () => {
        axios.get('form/masters')
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    setMastersList(res.data)
                    setLoading(true);
                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                }
            })
            .catch((err) => {
                setdataresponse([]);
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                console.log(errorMessage);
            });
    };
    const handleSubmitDarft = () => {
        setValue('is_draft', 1)
        submitForm();
    };
    const submitForm = (dd) => {
        console.log('getValues() :>> ', getValues());

        axios.post(`/loan/saveRefrences`, getValues())
            .then((response) => {
                // console.log(response);
                const res = response.data;
                if (res.success === true) {
                    setdataresponse(res)
                    setSnackBarOption({
                        type: 'S',
                        message: res.message,
                    });
                    setOpen(true);
                    setTimeout(() => {
                        history(`/loan/previous-loan/` + applicationId);
                    }, 3000);


                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)

                } 
            })
            .catch((error) => {
                let errorMessage = error.message;
                if (error?.response?.data?.message) {
                    errorMessage = error.response.data.message;
                    const res = error.response.data
                    if (res.message === "VALIDATION_ERROR") {
                        Object.entries(res.data).map(([value, text]) => setError(value, { message: text }))
                    }
                } 
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
            });
        setTimeout(() => {
            setSnackBarOption({
                type: 'E',
                message: null,
            });
        }, 5000);
    };


    useEffect(() => {
        dispatch(updateProgress(4))
        getMasterList();
        const sendData = {
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "application_id": applicationId
        }
        axios.get(`/loan/reportDetail?${queryString.stringify(sendData)}`)
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    setdataresponse(res.data);
                    if (res.data.references.length > 0) {

                        res.data.references.forEach((refer) => {
                            const setNestedValue = (prefix, object) => {
                                Object.entries(object).forEach(([key, value]) => {
                                    const field = `${prefix}.${key}`;
                                    setValue(field, value);
                                });
                            };
                            if (refer.type === "REF1") {
                                setNestedValue('reference1', refer);
                            } else if (refer.type === "REF2") {
                                setNestedValue('reference2', refer);
                            }
                        });
                    } 
                    setLoading(true);
                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)
                }
            })
            .catch((err) => {
                setdataresponse([]);
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
                console.log(errorMessage);
            });
            // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [applicationId, dispatch, setdataresponse, setValue]);
    return (
        <div>
            {loading ? (
                <div className="App">
                    <div className='container width_1170'>
                        <div className='card'>
                            <div className='row'>
                                <TopButton />
                            </div>
                            <div className='row mt-4 mb-2 '>
                                <LoanProgress />
                            </div>
                            <form method="post" onSubmit={handleSubmit(submitForm)} className="mt-3 px-3 bit-1" id="checkEligibility_form">
                                <div className='m-2' >
                                    <Divider>
                                        <Chip label="Reference 1" />
                                    </Divider>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="reference1.name"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="reference1.name"
                                                        label="Name of Reference*"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('reference1.name', e.target.value);
                                                        }}
                                                        {...register('reference1.name', {
                                                            required: 'name is required',
                                                            minLength: {
                                                                value: 3,
                                                                message: 'Minimum 3 Characters Required for Name',
                                                            },
                                                            maxLength: {
                                                                value: 30,
                                                                message: 'Name was Too High',
                                                            },
                                                            pattern: {
                                                                value: /^[a-zA-z ]{3,30}$/,
                                                                message: 'Enter a valid Name',
                                                            },
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.reference1?.name && <p className="error"> {errors.reference1.name.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <FormControl sx={{ width: '100%' }}   >
                                                <InputLabel id="reference1.relationship">Relationship*</InputLabel>
                                                <Controller
                                                    name="reference1.relationship"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            required
                                                            labelId="reference1.relationship"
                                                            variant="outlined"
                                                            {...field}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue('reference1.reletionship', e.target.value);
                                                            }}
                                                        {...register('reference1.relationship', {
                                                            required: 'Relationship is required',
                                                        })}
                                                        >
                                                            <MenuItem value="" disabled > <em>Select Relationship</em> </MenuItem>
                                                            {
                                                                mastersList?.filter(master => master.type === "reference_relationship").map((value) => {
                                                                    return (
                                                                        <MenuItem key={value.value} value={value.label}>
                                                                            {value.value}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                            {errors.reference1?.relationship && getValues('reference1.relationship') === "" && <p className="error"> {errors.reference1.relationship.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="reference1.email"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="reference1.emailemail"
                                                        label="Email ID*"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('reference1.email', e.target.value);
                                                        }}
                                                        {...register('reference1.email', {
                                                            required: 'Email is required',
                                                            pattern: {
                                                                value: /\S+@\S+\.\S+/,
                                                                message: 'Entered value does not match email format',
                                                            },
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.reference1?.email && <p className="error"> {errors.reference1.email.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="reference1.phone"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="reference1.phone"
                                                        label="Mobile Number*"
                                                        type='number'
                                                        variant="outlined"  {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('reference1.phone', e.target.value);
                                                        }}
                                                        {...register('reference1.phone', {
                                                            required: 'Mobile no is required',
                                                            pattern: {
                                                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                                                                message:
                                                                    'Entered value Mobile Number in valid format',
                                                            },
                                                            minLength: {
                                                                value: 10,
                                                                message: 'Enter the valid Phone Number',
                                                            },
                                                            maxLength: {
                                                                value: 10,
                                                                message: 'Enter the valid Phone Number',
                                                            },
                                                            type: Number,
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.reference1?.phone && <p className="error"> {errors.reference1.phone.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="reference1.address"
                                                control={control}
                                                render={({ field }) => (
                                                    <Textarea
                                                        minLength={2}
                                                        minRows={3}
                                                        id="reference1.address"
                                                        placeholder="Local Address *"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('reference1.address', e.target.value);
                                                        }}
                                                        {...register('reference1.address', {
                                                            required: 'Local Address is required',
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.reference1?.address && <p className="error"> {errors.reference1.address.message}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className='m-2' >
                                    <Divider>
                                        <Chip label="Reference 2" />
                                    </Divider>
                                </div>
                                <div className="row">
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="reference2.name"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="reference2.name"
                                                        label="Name of Reference 2*"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('reference2.name', e.target.value);
                                                        }}
                                                        variant="outlined"
                                                        {...register('reference2.name', {
                                                            required: 'name is required',
                                                            minLength: {
                                                                value: 3,
                                                                message: 'Minimum 3 Characters Required for Name',
                                                            },
                                                            maxLength: {
                                                                value: 30,
                                                                message: 'Name was Too High',
                                                            },
                                                            pattern: {
                                                                value: /^[a-zA-z ]{3,30}$/,
                                                                message: 'Enter a valid Name',
                                                            },
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.reference2?.name && <p className="error"> {errors.reference2.name.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <FormControl sx={{ width: '100%' }}   >
                                                <InputLabel id="reference2.relationship">Relationship*</InputLabel>
                                                <Controller
                                                    name="reference2.relationship"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            required
                                                            labelId="reference2.relationship"
                                                            name="reference2.relationship"
                                                            variant="outlined"
                                                            {...field}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue('reference2.relationship', e.target.value);
                                                            }}
                                                        {...register('reference2.relationship', {
                                                            required: 'Relationship is required',
                                                        })}
                                                        >
                                                            <MenuItem value="" disabled > <em>Select Relationship</em> </MenuItem>
                                                            {
                                                                mastersList?.filter(master => master.type === "reference_relationship").map((value) => {
                                                                    return (
                                                                        <MenuItem key={value.value} value={value.label}>
                                                                            {value.value}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                            {errors.reference2?.relationship && getValues('reference2.relationship') === "" && <p className="error"> {errors.reference2.relationship.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="reference2.email"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="reference2.email"
                                                        label="Email ID*"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('reference2.email', e.target.value);
                                                        }}
                                                        {...register('reference2.email', {
                                                            required: 'Email is required',
                                                            pattern: {
                                                                value: /\S+@\S+\.\S+/,
                                                                message: 'Entered value does not match email format',
                                                            },
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.reference2?.email && <p className="error"> {errors.reference2.email.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="reference2.phone"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="reference2.phone"
                                                        label="Mobile Number*"
                                                        type='number'
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('reference2.phone', e.target.value);
                                                        }}
                                                        {...register('reference2.phone', {
                                                            required: 'Mobile no is required',
                                                            pattern: {
                                                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                                                                message:
                                                                    'Entered value Mobile Number in valid format',
                                                            },
                                                            minLength: {
                                                                value: 10,
                                                                message: 'Enter the valid Phone Number',
                                                            },
                                                            maxLength: {
                                                                value: 10,
                                                                message: 'Enter the valid Phone Number',
                                                            },
                                                            type: Number,
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.reference2?.phone && <p className="error"> {errors.reference2.phone.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="reference2.address"
                                                control={control}
                                                render={({ field }) => (
                                                    <Textarea
                                                        minLength={2}
                                                        minRows={3}
                                                        id="reference2.address"
                                                        placeholder="Local Address *"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('reference2.address', e.target.value);
                                                        }}
                                                        {...register('reference2.address', {
                                                            required: 'Local Address is required',
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.reference2?.address && <p className="error"> {errors.reference2.address.message}</p>}
                                        </div>
                                    </div>

                                </div>


                                <div className='btn-center'>
                                    <Button color="secondary" variant="contained" className=" ml-2 " onClick={() => history(`/loan/co-applicant/` + applicationId)} >
                                        Previous Page
                                    </Button>
                                    <Button color='warning' type="button" className='ml-2' onClick={handleSubmitDarft} >
                                        Save as Draft
                                    </Button>
                                    <Button type="button" color='primary' className='ml-2' onClick={handleSubmit(submitForm)} disabled={isSubmitting}>
                                        Submit
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                    {snackBarOption.message ? (
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                            anchorOrigin={{ vertical, horizontal }}>
                            <Alert onClose={handleClose} severity={snackBarOption.type === 'E' ? "error" : 'success'} sx={{ width: '100%' }}>
                                {snackBarOption.message}
                            </Alert>
                        </Snackbar>
                    ) : null}


                </div>) : (<Loader />)}
        </div>
    )
});

export default Reference;
