export const UserRole = {
  Admin: 0,
  Editor: 1,
};
/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1200;
export const menuHiddenBreakpoint = 992;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];
export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
};
export const adminRoot = '/app';
export const superRoot = '/superadmin';
export const consultantRoot = '/consultantportal';
export const cdnUrl = 'https://d3e0ld6arspcd6.cloudfront.net/';
export const searchPath = `${adminRoot}/#`;

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.blueolympic';
export const isDarkSwitchActive = false;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = false;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

export const instantPersonalLoan = 62
export const bissnessLoan = 61
export const personalLoan = 60
export const balanceTransfer = 126
export const overDraft = 125
export const MSME = 124
export const SME = 123
export const loanAgainstProperty = 122
export const vehicleLoan = 117
export const educationLoan = 116
export const homeLoan = 115
export const LOAN_TYPE={
  '60': 'Personal Loan',
  '61': 'Business Loan',
  '62': 'Instant Personal Loan',
  '115': 'Home Loan',
  '116': 'Education Loan',
  '117': 'Vehicle Loan',
  '122': 'Loan Against Property.',
  '123': 'SME Loan',
  '124': 'MSME Loan',
  '125': 'Over Draft Loan',
  '126': 'Balance Transfer Loan ',
};
export const cardBank = {
  "-1": "All",
  "IDFC": "IDFC",
  "INDUSIND": "IndusInd",
  "SBI": "SBI",
  "AXIS": "Axis",
  "rbl": "RBL",
  "AK": "AU Small Finance Bank",
  "YC": "Yes Bank",
  "HD": "HDFC Bank",
};
export const dateType = {  
  "application_date": "Application Date",
  "approval_date": "Approval Date",
};