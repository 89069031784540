/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoansList from '../components/LoansList';
import Home from '../components/Home';
import CreditCardList from '../components/CreditCard/CreditCardList';
import CreditCardReport from '../components/CreditCard/CreditCardReport';
import DataUContext from '../context/DataUContext';
import LoanView from '../components/LoanView';
import CreditCardDetails from '../components/CreditCard/CreditCardDetails';

function DynamicRoutes() {
  const history = useNavigate();
  const data = useContext(DataUContext);
  useEffect(() => {
    if (data?.catype) {
      history('/credit-cards-details/' + data.catype);
    }
    if (data?.editAppId) {
      history('/view-Application/' + data.editAppId);
    }
  }, [data.catype,data.editAppId,history]); 

  if (data && data?.services === "CRT") {
    if (data?.plugin_accessibility === "Darcy" || data?.plugin_accessibility === 'Eliza') {
      // return <LoansList />;
      return <CreditCardReport />;
    } else {
      if (data?.catype && data?.catype !== null && data?.catype !== undefined) {
        console.log('data.catype', data.catype)
        history('/credit-cards-details/' + data?.catype);
        
      }else{
        return <CreditCardList />;

      }
    }
  } else {
    if (data?.editAppId) {
      history('/view-Application/' + data?.editAppId);
    }
    if (data?.plugin_accessibility && (data?.plugin_accessibility === "Darcy" || data?.plugin_accessibility === 'Eliza')) {
      return <LoansList />;
    } else {

      return <Home />;
    }

  }
}

export default DynamicRoutes;
