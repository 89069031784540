/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import larg_loader from './assets/images/larg_loader.gif';
import small_loader from './assets/images/small_loader.gif';
import list_loader from './assets/images/list_loader.gif';
function Loader({ list = false }) {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // const imageUrl = windowSize.width > 768 ? larg_loader : small_loader;
  return (
    <div className="loader center loading-overlay loading_overlay">
      {/*list && list === true ? <img src={list_loader} alt="Loader Please wait...." /> : <img src={windowSize.width > 768 ? larg_loader : small_loader} alt="Loader Please wait...." />*/}
      <span className="loading" />
      {/* <div className="loader">Loading...</div> */}
      <div className="spinner">
        <div className="double-bounce1" />
        <div className="double-bounce2" />
      </div>
    </div>
  );
}

export default Loader;
