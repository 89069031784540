/* eslint-disable no-unused-vars */
import React, { useState, useReducer, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { setFormData } from '../redux/formDataSlice';
import { updateLoanApplication } from '../redux/actions';
import { setMasterData } from '../redux/masterDataSlice';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Button } from 'reactstrap'; 
import FormControl from '@mui/material/FormControl';
import useAxiosInstance from '../axiosi-ins';
import Loader from '../Loader'
import LoanProgress from './LoanProgress';
import { updateProgress } from '../redux/actions';
import * as constVal from '../constants/defaultValues';
import { getItem, getItemSession, setItem } from '../helpers/Utils';
import TopButton from './BackButton';
import DataUContext from '../context/DataUContext';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function LoanBasicDetails() {
    const { applicationId } = useParams();
    const axios = useAxiosInstance();
    const dispatch = useDispatch();
    const history = useNavigate();
    // const dataU  = useContext(DataUContext); 
    const dataU  = useSelector((state) => state.payload); 
    const {
        register,
        control,
        handleSubmit,
        formState: { errors, isSubmitted, isSubmitting },
        setValue,
        setError,
        getValues,
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onBlur',
        defaultValues: {
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "application_id": applicationId,
            "is_draft": 0,
            "first_name": "",
            "middle_name": "",
            "sur_name": "",
            "gender": "",
            "father_name": "",
            "mother_name": "",
            "marital_status": "",
            "religion": "",
            "qualification": "",
            "current_emp_stability": "",
            "total_emp_stability": "",
            "industry_working": "",
            "company_type": "",
            "employer_name": "",
            "designation": "",
            "net_home_salary": "",
            "salary_bank_account": "",
            "bank_branch": "",
            "account_type": "",
            "salary_account_no": "",
            "dependent": "",
            "emi_towards": "",
            "loan_amount": "",
            "tenure": "",
            "organization_type": "",
            "loan_type": "",
            "driving_licence_no": "",
            "dl_valid_upto_date": "",
            "designation_relation_with_company": "",
            "vehicle_category": "",
            "vehicle_type": "",
            "manufacturer": "",
            "vehicle_model": "",
            "supplier": "",
            "cost_of_vehicle": "",
            "cost_of_insurance": "",
            "cost_of_accessories": "",
            "road_tax": "",
            "other": "",
            "date_of_incorporation": "",
            "no_of_years_in_current_office": "",
            "nature_of_business": "",
            "company_pancard": "",
            "gst_no": "",
            "msme": "",
            "annual_turnover_for_first_year": "",
            "annual_turnover_for_second_year": "",
            "annual_turnover_for_third_year": "",
        }
    });
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'right',
    });
    const [open, setOpen] = React.useState(false);
    const { vertical, horizontal } = state;
    const handleClose = () => {
        setOpen(false)
    };
    const [mastersList, setMastersList] = useState([]);
    const [eduMastersList, setEduMastersList] = useState([]);
    const formData = useSelector((state) => state.formData.dataBasic);
    const [loading, setLoading] = useState(false);
    const [incomeSource, setIncomeSource] = useState();
    const [dataresponse, setdataresponse] = useState([]);
    const [bankLists, setBankLists] = useState([]);
    const [snackBarOption, setSnackBarOption] = useState({
        message: null,
        type: 'E',
    }); 
    const getAllBank = () => {
        axios.get('master/all-banks')
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    setBankLists(res.data);
                    setLoading(true);
                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)
                }
            })
            .catch((err) => {
                setdataresponse([]);
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
                console.log(errorMessage);
            });
    };
    const getMasterList = () => {
        axios.get('form/masters')
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    setMastersList(res.data)
                    setLoading(true);
                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)
                }
            })
            .catch((err) => {
                setdataresponse([]);
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
                console.log(errorMessage);
            });
    };
    const getEduLoanSubcategories = () => {
        const sendData = {
            "ref_code": dataU?.refCode || getItemSession('refCode'),
        }
        axios.get(`loan/getEduLoanSubcategories?${queryString.stringify(sendData)}`)
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    setEduMastersList(res.data)
                    setLoading(true);
                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)
                }
            })
            .catch((err) => {
                setdataresponse([]);
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
                console.log(errorMessage);
            });
    };
    const handleSubmitDarft = () => {
        setValue('is_draft', 1)
        submitForm();
    };
    const submitForm = () => {
        axios.post(`/loan/saveApplicant`, getValues())
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    setLoading(true);
                    setSnackBarOption({
                        type: 'S',
                        message: res.message,
                    });
                    setOpen(true)
                    setTimeout(() => {
                        history(`/loan/address/` + res.data.application_id);
                    }, 3000);

                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)

                }
            })
            .catch((error) => {
                let errorMessage = error.message;
                if (error?.response?.data?.message) {
                    errorMessage = error.response.data.message;
                    const res = error.response.data
                    if (res.message === "VALIDATION_ERROR") {
                        Object.entries(res.data).map(([value, text]) => setError(value, { message: text }))
                    }
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
            });
    };


    useEffect(() => {
        dispatch(updateProgress(1))
        getAllBank();
        // if(mastersList  === undefined || mastersList === null){
        getMasterList();
        // }
        const sendData = {
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "application_id": applicationId
        }
        axios.get(`/loan/reportDetail?${queryString.stringify(sendData)}`)
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    if (res.data.applicant_details === null) {
                        res.data.applicant_details = {};
                    }
                    const splitedName = res.data.name.split(" ");
                    if (!res.data.hasOwnProperty('first_name')) {
                        res.data.first_name = splitedName[0];
                        setValue('first_name', splitedName[0]);
                    }
                    if (!res.data.hasOwnProperty('middle_name')) {
                        res.data.middle_name = splitedName.length >= 3 ? splitedName[1] : '';
                        setValue('middle_name', res.data.middle_name);
                    }
                    if (!res.data.hasOwnProperty('sur_name')) {
                        if (splitedName.length === 2) {
                            res.data.sur_name = splitedName.slice(1).join(' ')
                        }
                        if (splitedName.length > 2) {
                            res.data.sur_name = splitedName.slice(2).join(' ')
                        }
                        setValue('sur_name', res.data.sur_name);
                    }
                    setdataresponse(res.data);
                    if (res.data?.loan_type_id === constVal.educationLoan) { 
                        getEduLoanSubcategories();
                    }
                    Object.entries(res.data.applicant_details).map(([value, text]) => setValue(value, text))
                    if (!res.data.applicant_details.hasOwnProperty('loan_amount')) {
                        setValue('loan_amount', res.data.loan_amount)
                    }
                    if (!res.data.applicant_details.hasOwnProperty('net_home_salary')) {
                        setValue('net_home_salary', res.data.monthly_income)
                    }
                    const updatedData = {
                        dataBasic: res.data,
                    };
                    dispatch(setFormData(updatedData));
                    dispatch(updateLoanApplication(res.data));
                    setLoading(true);
                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)

                }

            })
            .catch((err) => {
                setdataresponse([]);
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
                console.log(errorMessage);
            });
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [applicationId, dispatch, setdataresponse, setValue]);
    return (
        <div>
            {loading ? (
                <div className="App">
                    <div className='container width_1170'>
                        <div className='card'>
                            <div className='row'>
                                <TopButton/>
                            </div>
                            <div className='row mt-4 mb-2 '>
                                <LoanProgress />
                            </div>
                            <form method="post" onSubmit={handleSubmit(submitForm)} className="mt-3 px-3 bit-1" id="checkEligibility_form">
                                <div className='row'>
                                    <div className='col-lg-3'>
                                        <div className="mb-5 position-relative">
                                            <Controller
                                                name="first_name"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('first_name', e.target.value);
                                                        }}
                                                        variant="outlined"
                                                        fullWidth
                                                        id="first_name"
                                                        label="First Name*"
                                                        name="first_name"
                                                        // onChange={(e) => setValue('first_name',e.target.value)} 
                                                        {...register('first_name', {
                                                            required: 'First is required',
                                                            minLength: {
                                                                value: 3,
                                                                message: 'Minimum 3 Characters Required for Name',
                                                            },
                                                            maxLength: {
                                                                value: 30,
                                                                message: 'Name was Too High',
                                                            },
                                                            pattern: {
                                                                value: /^[a-zA-z ]{3,30}$/,
                                                                message: 'Enter a valid Name',
                                                            },
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.first_name && <p className="error"> {errors.first_name.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-5 position-relative">
                                            <Controller
                                                name="middle_name"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="middle_name"
                                                        label="Middle Name"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('middle_name', e.target.value);
                                                        }}
                                                        // onChange={(e) => setValue('middle_name',e.target.value)}  
                                                        {...register('middle_name', {
                                                            minLength: {
                                                                value: 3,
                                                                message: 'Minimum 3 Characters Required for Name',
                                                            },
                                                            maxLength: {
                                                                value: 30,
                                                                message: 'Name was Too High',
                                                            },
                                                            pattern: {
                                                                value: /^[a-zA-z ]{3,30}$/,
                                                                message: 'Enter a valid Name',
                                                            },
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.middle_name && <p className="error"> {errors.middle_name.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-5 position-relative">
                                            <Controller
                                                name="sur_name"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="sur_name"
                                                        label="Last Name*"
                                                        name="sur_name"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('sur_name', e.target.value);
                                                        }}  
                                                        {...register('sur_name', {
                                                            required: 'Last name is required',
                                                            minLength: {
                                                                value: 3,
                                                                message: 'Minimum 3 Characters Required for Name',
                                                            },
                                                            maxLength: {
                                                                value: 30,
                                                                message: 'Name was Too High',
                                                            },
                                                            pattern: {
                                                                value: /^[a-zA-z ]{3,30}$/,
                                                                message: 'Enter a valid Name',
                                                            },
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.sur_name && <p className="error"> {errors.sur_name.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-5 position-relative">
                                            <FormControl sx={{ width: '100%' }}   >
                                                <InputLabel id="gender">Select Gender*</InputLabel>
                                                <Controller
                                                    name="gender"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            required
                                                            labelId="gender"
                                                            name="gender"
                                                            {...field}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue('gender', e.target.value);
                                                            }}
                                                            // value={getValues('gender')} 
                                                            variant="outlined" 
                                                            {...register('gender', {
                                                                required: 'Gender is required',
                                                            })}
                                                        >
                                                            <MenuItem value="" disabled > <em>Select Gender</em> </MenuItem>
                                                            {
                                                                mastersList?.filter(master => master.type === "gender").map((value) => {
                                                                    return (
                                                                        <MenuItem key={value.value} value={value.value}>
                                                                            {value.label}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                            {errors.gender && getValues('gender') === "" && <p className="error"> {errors.gender.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-5 position-relative">
                                            <Controller
                                                name="father_name"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="father_name"
                                                        label="Name of Father/Husband*"
                                                        name="father_name"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('father_name', e.target.value);
                                                        }} 
                                                        {...register('father_name', {
                                                            required: 'Name of Father/Husband is required',
                                                            minLength: {
                                                                value: 3,
                                                                message: 'Minimum 3 Characters Required for Name',
                                                            },
                                                            maxLength: {
                                                                value: 50,
                                                                message: 'Name was Too High',
                                                            },
                                                            pattern: {
                                                                value: /^[a-zA-z ]{3,50}$/,
                                                                message: 'Enter a valid Name',
                                                            },
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.father_name && <p className="error"> {errors.father_name.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-5 position-relative">
                                            <Controller
                                                name="mother_name"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="mother_name"
                                                        label="Mother's Maiden Name*"
                                                        name="mother_name"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('mother_name', e.target.value);
                                                        }} 
                                                        {...register('mother_name', {
                                                            required: 'Mothers Maiden Name is required',
                                                            minLength: {
                                                                value: 3,
                                                                message: 'Minimum 3 Characters Required for Name',
                                                            },
                                                            maxLength: {
                                                                value: 50,
                                                                message: 'Name was Too High',
                                                            },
                                                            pattern: {
                                                                value: /^[a-zA-z ]{3,50}$/,
                                                                message: 'Enter a valid Name',
                                                            },
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.mother_name && <p className="error"> {errors.mother_name.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-5 position-relative">
                                            <FormControl sx={{ width: '100%' }}   >
                                                <InputLabel id="marital_status">Marital Status*</InputLabel>
                                                <Controller
                                                    name="marital_status"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            required
                                                            labelId="marital_status"
                                                            // value={getValues('marital_status')}
                                                            variant="outlined" 
                                                            {...field}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue('marital_status', e.target.value);
                                                            }} 
                                                            {...register("marital_status", {
                                                                required: 'Marital status is required',
                                                            })}
                                                        >
                                                            <MenuItem value="" disabled > <em>Select Marital status</em> </MenuItem>
                                                            {
                                                                mastersList?.filter(master => master.type === "marital_status").map((value) => {
                                                                    return (
                                                                        <MenuItem key={value.value} value={value.value}>
                                                                            {value.label}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                            {errors.marital_status && getValues('marital_status') === "" && <p className="error"> {errors.marital_status.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-5 position-relative">
                                            <FormControl sx={{ width: '100%' }}   >
                                                <InputLabel id="religion">Religion*</InputLabel>
                                                <Controller
                                                    name="religion"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            required
                                                            labelId="religion"
                                                            variant="outlined" 
                                                            {...field}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue('religion', e.target.value);
                                                            }} 
                                                            {...register('religion', {
                                                                required: 'Religion is required',
                                                            })}
                                                        >
                                                            <MenuItem value="" disabled > <em>Select Religion</em> </MenuItem>
                                                            {
                                                                mastersList?.filter(master => master.type === "religion").map((value) => {
                                                                    return (
                                                                        <MenuItem key={value.value} value={value.value}>
                                                                            {value.label}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                            {errors.religion && <p className="error"> {errors.religion.message}</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-12 m-1">
                                        <h5> <strong>Educational &amp; Professional Detail </strong> </h5>
                                        <span className="pesrsonal_detail_hr"></span>
                                    </div>
                                    <div className='col-lg-3 mt-1'>
                                        <div className="mb-5 position-relative">
                                            <FormControl sx={{ width: '100%' }}   >
                                                <InputLabel id="qualification">Educational Qualification*</InputLabel>
                                                <Controller
                                                    name="qualification"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            required
                                                            labelId="qualification"
                                                            variant="outlined"
                                                            {...field}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue('qualification', e.target.value);
                                                            }} 
                                                            {...register('qualification', {
                                                                required: 'Loan Type is required',
                                                            })}
                                                        >
                                                            <MenuItem value="" disabled > <em>Select Qualification</em> </MenuItem>
                                                            {
                                                                mastersList?.filter(master => master.type === "qualification").map((value) => {
                                                                    return (
                                                                        <MenuItem key={value.value} value={value.label}>
                                                                            {value.label}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                            {errors.qualification && getValues('qualification') === "" && <p className="error"> {errors.qualification.message}</p>}
                                        </div>
                                    </div>
                                    {dataresponse?.income_source === "1" ?
                                        <>
                                            <div className='col-lg-3'>
                                                <div className="mb-5 position-relative">
                                                    <FormControl sx={{ width: '100%' }}   >
                                                        <InputLabel id="current_emp_stability">Current company Experience (years) *</InputLabel>
                                                        <Controller
                                                            name="current_emp_stability"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    required
                                                                    labelId="current_emp_stability"
                                                                    variant="outlined" 
                                                                    {...field}
                                                                    onChange={(e) => {
                                                                        field.onChange(e);
                                                                        setValue('current_emp_stability', e.target.value);
                                                                    }} 
                                                                    {...register('current_emp_stability', {
                                                                        required: 'Current employment stability is required',
                                                                    })}
                                                                >
                                                                    <MenuItem value="" disabled > <em>Select Current employment stability</em> </MenuItem>
                                                                    {
                                                                        mastersList?.filter(master => master.type === "employment_eligibility").map((value) => {
                                                                            return (
                                                                                <MenuItem key={value.value} value={value.label}>
                                                                                    {value.value}
                                                                                </MenuItem>
                                                                            );
                                                                        })
                                                                    }
                                                                </Select>
                                                            )}
                                                        />
                                                    </FormControl>
                                                    {errors.current_emp_stability && getValues('current_emp_stability') === "" && <p className="error"> {errors.current_emp_stability.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-5 position-relative">
                                                    <FormControl sx={{ width: '100%' }}   >
                                                        <InputLabel id="total_emp_stability">Total working experience*</InputLabel>
                                                        <Controller
                                                            name="total_emp_stability"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    required
                                                                    labelId="total_emp_stability"
                                                                    variant="outlined" 
                                                                    {...field}
                                                                    onChange={(e) => {
                                                                        field.onChange(e);
                                                                        setValue('total_emp_stability', e.target.value);
                                                                    }} 
                                                                    {...register('total_emp_stability', {
                                                                        required: 'Total emploment stablity is required',
                                                                    })}
                                                                >
                                                                    <MenuItem value="" disabled > <em>Select Total emploment stability</em> </MenuItem>
                                                                    {
                                                                        mastersList?.filter(master => master.type === "employment_eligibility").map((value) => {
                                                                            return (
                                                                                <MenuItem key={value.value} value={value.label}>
                                                                                    {value.value}
                                                                                </MenuItem>
                                                                            );
                                                                        })
                                                                    }
                                                                </Select>
                                                            )}
                                                        />
                                                    </FormControl>
                                                    {errors.total_emp_stability && <p className="error"> {errors.total_emp_stability.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-5 position-relative">
                                                    <FormControl sx={{ width: '100%' }}   >
                                                        <InputLabel id="industry_working">Field of Employment*</InputLabel>
                                                        <Controller
                                                            name="industry_working"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    required
                                                                    labelId="industry_working"
                                                                    name="industry_working"
                                                                    {...field}
                                                                    onChange={(e) => {
                                                                        field.onChange(e);
                                                                        setValue('industry_working', e.target.value);
                                                                    }} 
                                                                    {...register('industry_working', {
                                                                        required: 'Industry working is required',
                                                                    })}
                                                                >
                                                                    <MenuItem value="" disabled > <em>Select Industry working</em> </MenuItem>
                                                                    {
                                                                        mastersList?.filter(master => master.type === "organization_type").map((value) => {
                                                                            return (
                                                                                <MenuItem key={value.value} value={value.label}>
                                                                                    {value.value}
                                                                                </MenuItem>
                                                                            );
                                                                        })
                                                                    }
                                                                </Select>
                                                            )}
                                                        />
                                                    </FormControl>
                                                    {errors.industry_working && getValues('total_emp_stability') === "" && <p className="error"> {errors.industry_working.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-5 position-relative">
                                                    <Controller
                                                        name="employer_name"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                fullWidth
                                                                id="employer_name"
                                                                label="Company Name*"
                                                                variant="outlined"
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('employer_name', e.target.value);
                                                                }} 
                                                                {...register('employer_name', {
                                                                    required: 'Company Name is required',
                                                                    minLength: {
                                                                        value: 3,
                                                                        message: 'Minimum 3 Characters Required for Name',
                                                                    },
                                                                    maxLength: {
                                                                        value: 50,
                                                                        message: 'Name was Too High',
                                                                    },
                                                                    pattern: {
                                                                        value: /^[a-zA-z ]{3,50}$/,
                                                                        message: 'Enter a valid Name',
                                                                    },
                                                                })}
                                                            />
                                                        )}
                                                    />
                                                    {errors.employer_name && <p className="error"> {errors.employer_name.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-5 position-relative">
                                                    <Controller
                                                        name="designation"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                fullWidth 
                                                                label="Designation*"
                                                                variant="outlined"
                                                                InputLabelProps={{ shrink: true }}
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('designation', e.target.value);
                                                                }} 
                                                                {...register('designation', {
                                                                    required: 'Designation is required',
                                                                    minLength: {
                                                                        value: 3,
                                                                        message: 'Minimum 3 Characters Required for Name',
                                                                    },
                                                                    maxLength: {
                                                                        value: 50,
                                                                        message: 'Name was Too High',
                                                                    },
                                                                    pattern: {
                                                                        value: /^[a-zA-z ]{3,50}$/,
                                                                        message: 'Enter a valid Name',
                                                                    },
                                                                })}
                                                            />
                                                        )}
                                                    />
                                                    {errors.designation && <p className="error"> {errors.designation.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-5 position-relative">
                                                    <Controller
                                                        name="net_home_salary"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                fullWidth 
                                                                label="Net Income*"
                                                                // InputProps={{
                                                                //     readOnly: true,
                                                                // }}
                                                                InputLabelProps={{ shrink: true }}
                                                                variant="outlined"
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('net_home_salary', e.target.value);
                                                                }}
                                                                {...register('net_home_salary', {
                                                                    required: 'Net Income is required',
                                                                    minLength: {
                                                                        value: 5,
                                                                        message: 'Enter the valid Monthly Income',
                                                                    },
                                                                    maxLength: {
                                                                        value: 10,
                                                                        message: 'Enter the valid Monthly Income',
                                                                    },
                                                                })}
                                                            />
                                                        )}
                                                    />
                                                    {errors.net_home_salary && <p className="error"> {errors.net_home_salary.message}</p>}
                                                </div>
                                            </div> </>
                                        : <>
                                            <div className='col-lg-3'>
                                                <div className="mb-5 position-relative">
                                                    <FormControl sx={{ width: '100%' }}   >
                                                        <InputLabel id="company_type">Company Type*</InputLabel>
                                                        <Controller
                                                            name="company_type"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    required
                                                                    labelId="company_type"
                                                                    name="company_type"
                                                                    {...field}
                                                                    onChange={(e) => {
                                                                        field.onChange(e);
                                                                        setValue('company_type', e.target.value);
                                                                    }} 
                                                                    {...register('company_type', {
                                                                        required: 'Company Type is required',
                                                                    })}
                                                                >
                                                                    <MenuItem value="" disabled > <em>Select Company type</em> </MenuItem>
                                                                    {
                                                                        mastersList?.filter(master => master.type === "organization_type").map((value) => {
                                                                            return (
                                                                                <MenuItem key={value.value} value={value.label}>
                                                                                    {value.value}
                                                                                </MenuItem>
                                                                            );
                                                                        })
                                                                    }
                                                                </Select>
                                                            )}
                                                        />
                                                    </FormControl>
                                                    {errors.company_type && getValues('company_type') === "" && <p className="error"> {errors.company_type.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-5 position-relative">
                                                    <Controller
                                                        name="date_of_incorporation"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                type='date'
                                                                InputLabelProps={{ shrink: true }}
                                                                fullWidth
                                                                id="date_of_incorporation"
                                                                label="Date Of Incorporation*"
                                                                variant="outlined"
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('date_of_incorporation', e.target.value);
                                                                }}
                                                                {...register('date_of_incorporation', {
                                                                    required: 'Date Of Incorporation is required',
                                                                })}
                                                            />
                                                        )}
                                                    />
                                                    {errors.date_of_incorporation && <p className="error"> {errors.date_of_incorporation.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-5 position-relative">
                                                    <Controller
                                                        name="no_of_years_in_current_office"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                fullWidth
                                                                id="no_of_years_in_current_office"
                                                                label="Number of Years In Current Office*"
                                                                variant="outlined"
                                                                type='number'
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('no_of_years_in_current_office', e.target.value);
                                                                }}
                                                                {...register('no_of_years_in_current_office', {
                                                                    required: 'Number of Years In Current Office is required',
                                                                    type: Number
                                                                })}
                                                            />
                                                        )}
                                                    />
                                                    {errors.no_of_years_in_current_office && <p className="error"> {errors.no_of_years_in_current_office.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-5 position-relative">
                                                    <FormControl sx={{ width: '100%' }}   >
                                                        <InputLabel id="nature_of_business">Trade Nature*</InputLabel>
                                                        <Controller
                                                            name="nature_of_business"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    required
                                                                    labelId="nature_of_business"
                                                                    name="nature_of_business"
                                                                    {...field}
                                                                    onChange={(e) => {
                                                                        field.onChange(e);
                                                                        setValue('nature_of_business', e.target.value);
                                                                    }} 
                                                                    {...register('nature_of_business', {
                                                                        required: 'Nature of business  is required',
                                                                    })}
                                                                >
                                                                    <MenuItem value="" disabled > <em>Select Nature of business</em> </MenuItem>
                                                                    {
                                                                        mastersList?.filter(master => master.type === "working_industry").map((value) => {
                                                                            return (
                                                                                <MenuItem key={value.value} value={value.label}>
                                                                                    {value.value}
                                                                                </MenuItem>
                                                                            );
                                                                        })
                                                                    }
                                                                </Select>
                                                            )}
                                                        />
                                                    </FormControl>
                                                    {errors.nature_of_business && getValues('nature_of_business') === "" && <p className="error"> {errors.nature_of_business.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-5 position-relative">
                                                    <Controller
                                                        name="company_pancard"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                fullWidth
                                                                id="company_pancard"
                                                                label="Company Pan Card No*"
                                                                variant="outlined"
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('company_pancard', e.target.value);
                                                                }}
                                                                {...register('company_pancard', {
                                                                    required: 'Company Pan Card No is required',
                                                                    maxLength: {
                                                                        value: 10,
                                                                        message: 'pan no was Too High',
                                                                    },
                                                                    pattern: {
                                                                        value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                                                                        message: 'Enter a valid PAN Card (e.g., XXXXX1234X)',
                                                                    },
                                                                })}
                                                            />
                                                        )}
                                                    />
                                                    {errors.company_pancard && <p className="error"> {errors.company_pancard.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-5 position-relative">
                                                    <Controller
                                                        name="gst_no"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                fullWidth
                                                                id="gst_no"
                                                                label="GST No"
                                                                variant="outlined"
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('gst_no', e.target.value);
                                                                }}
                                                                // {...register('gst_no', {
                                                                //     required: 'GST No is required',
                                                                // })}
                                                            />
                                                        )}
                                                    />
                                                    {errors.gst_no && <p className="error"> {errors.gst_no.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-5 position-relative">
                                                    <Controller
                                                        name="msme"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                fullWidth
                                                                id="msme"
                                                                label="MSME"
                                                                variant="outlined"
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('msme', e.target.value);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errors.msme && <p className="error"> {errors.msme.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-5 position-relative">
                                                    <Controller
                                                        name="annual_turnover_for_first_year"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                fullWidth
                                                                id="annual_turnover_for_first_year"
                                                                label="Annual Turn over For First year"
                                                                variant="outlined"
                                                                type='number'
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('annual_turnover_for_first_year', e.target.value);
                                                                }}
                                                                {...register('annual_turnover_for_first_year', {
                                                                    type: Number
                                                                })}
                                                            />
                                                        )}
                                                    />
                                                    {errors.annual_turnover_for_first_year && <p className="error"> {errors.annual_turnover_for_first_year.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-5 position-relative">
                                                    <Controller
                                                        name="annual_turnover_for_second_year"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                fullWidth
                                                                id="annual_turnover_for_second_year"
                                                                label="Annual Turn over For Second year"
                                                                variant="outlined"
                                                                type='number'
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('annual_turnover_for_second_year', e.target.value);
                                                                }}
                                                                {...register('annual_turnover_for_second_year', {
                                                                    type: Number
                                                                })}
                                                            />
                                                        )}
                                                    />
                                                    {errors.annual_turnover_for_second_year && <p className="error"> {errors.annual_turnover_for_second_year.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-5 position-relative">
                                                    <Controller
                                                        name="annual_turnover_for_third_year"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                fullWidth
                                                                id="annual_turnover_for_third_year"
                                                                label="Annual Turn over For Third year"
                                                                variant="outlined"
                                                                type='number'
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('annual_turnover_for_third_year', e.target.value);
                                                                }}
                                                                {...register('annual_turnover_for_third_year', {
                                                                    type: Number
                                                                })}
                                                            />
                                                        )}
                                                    />
                                                    {errors.annual_turnover_for_third_year && <p className="error"> {errors.annual_turnover_for_third_year.message}</p>}
                                                </div>
                                            </div>

                                        </>}
                                    <div className="col-md-12 m-2">
                                        <h5><strong>Bank Account Detail</strong></h5>
                                        <span className="pesrsonal_detail_hr"></span>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-5 position-relative">
                                            <FormControl sx={{ width: '100%' }}   >
                                                <InputLabel id="salary_bank_account">Bank Account*</InputLabel>

                                                <Controller
                                                    name="salary_bank_account"
                                                    control={control}
                                                    render={({ field }) => (<Select
                                                        required
                                                        labelId="salary_bank_account" 
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('salary_bank_account', e.target.value);
                                                        }} 
                                                        {...register('salary_bank_account', {
                                                            required: 'Bank account name  is required',
                                                        })}
                                                    >
                                                        <MenuItem value="" disabled > <em> Select Bank account name </em> </MenuItem>
                                                        {
                                                            bankLists.map((value) => {
                                                                return (
                                                                    <MenuItem key={value.id} value={value.id} >
                                                                        {value.name}
                                                                    </MenuItem>
                                                                );
                                                            })
                                                        }
                                                    </Select>
                                                    )}
                                                />
                                            </FormControl>
                                            {errors.salary_bank_account && getValues('salary_bank_account') === "" &&  <p className="error"> {errors.salary_bank_account.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-5 position-relative">
                                            <Controller
                                                name="bank_branch"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="bank_branch"
                                                        label="Bank Branch*"
                                                        variant="outlined" 
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('bank_branch', e.target.value);
                                                        }}
                                                        {...register('bank_branch', {
                                                            required: 'Bank Branch is required',
                                                            minLength: {
                                                                value: 3,
                                                                message: 'Minimum 3 Characters Required for Name',
                                                            },
                                                            maxLength: {
                                                                value: 50,
                                                                message: 'Name was Too High',
                                                            },
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.bank_branch && <p className="error"> {errors.bank_branch.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-5 position-relative">
                                            <FormControl sx={{ width: '100%' }}   >
                                                <InputLabel id="account_type">Account Type*</InputLabel>
                                                <Controller
                                                    name="account_type"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            required
                                                            labelId="account_type"
                                                            variant="outlined" 
                                                            {...field}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue('account_type', e.target.value);
                                                            }} 
                                                            {...register('account_type', {
                                                                required: 'Account type is required',
                                                            })}
                                                        >
                                                            <MenuItem value="" disabled > <em>Select Account type</em> </MenuItem>
                                                            {
                                                                mastersList?.filter(master => master.type === "account_type").map((value) => {
                                                                    return (
                                                                        <MenuItem key={value.value} value={value.value}>
                                                                            {value.label}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                            {errors.account_type && getValues('account_type') === "" && <p className="error"> {errors.account_type.message}</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-12 m-2">
                                        <h5><strong>Loan Detail</strong></h5>
                                        <span className="pesrsonal_detail_hr"></span>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-5 position-relative">
                                            <Controller
                                                name="dependent"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="dependent"
                                                        variant="outlined"
                                                        label="Household Members*"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('dependent', e.target.value);
                                                        }}
                                                        {...register('dependent', {
                                                            required: 'Household Members is required',
                                                            type: Number
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.dependent && <p className="error"> {errors.dependent.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <FormControl sx={{ width: '100%' }}   >
                                                <InputLabel id="emi_towards">EMI toward Other Loan*</InputLabel>
                                                <Controller
                                                    name="emi_towards"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            required
                                                            labelId="emi_towards"
                                                            {...field}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue('emi_towards', e.target.value);
                                                            }}
                                                            {...register('emi_towards', {
                                                                required: 'Emi towards other loan is required',
                                                            })}
                                                        >
                                                            <MenuItem value="" disabled > <em>Select Emi toward other loan</em> </MenuItem>
                                                            {
                                                                mastersList?.filter(master => master.type === "emi_towards_other_loans").map((value) => {
                                                                    return (
                                                                        <MenuItem key={value.value} value={value.value}>
                                                                            {value.label}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                            {errors.emi_towards && getValues('emi_towards') ===""  && <p className="error"> {errors.emi_towards.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="loan_amount"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="loan_amount"
                                                        label="Loan Amount*"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('loan_amount', e.target.value);
                                                        }}
                                                        {...register('loan_amount', {
                                                            required: 'Loan Amount is required',
                                                            type: Number,
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.loan_amount && <p className="error"> {errors.loan_amount.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <FormControl sx={{ width: '100%' }}   >
                                                <InputLabel id="tenure">Loan Tenure (Months)*</InputLabel>
                                                <Controller
                                                    name="tenure"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            required
                                                            labelId="tenure"
                                                            {...field}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue('tenure', e.target.value);
                                                            }} 
                                                            {...register('tenure', {
                                                                required: 'Tenure is required',
                                                            })}
                                                        >
                                                            <MenuItem value="" disabled > <em>Select Tenure</em> </MenuItem>
                                                            {
                                                                mastersList?.filter(master => master.type === "loan_tenure_months").map((value) => {
                                                                    return (
                                                                        <MenuItem key={value.value} value={value.value}>
                                                                            {value.label}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                            {errors.tenure && getValues('tenure') === "" && <p className="error"> {errors.tenure.message}</p>}
                                        </div>
                                    </div>
                                    {dataresponse?.loan_type_id === constVal.vehicleLoan ?
                                        <div className='col-lg-3'>
                                            <div className="mb-3 position-relative">
                                                <FormControl sx={{ width: '100%' }}   >
                                                    <InputLabel id="loan_type">Loan Sub Type*</InputLabel>
                                                    <Controller
                                                        name="loan_type"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                required
                                                                fullWidth
                                                                labelId="loan_type"
                                                                id="loan_type"
                                                                label="Income Source"
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('loan_type', e.target.value);
                                                                }} 
                                                                {...register('loan_type', {
                                                                    required: 'Loan sub type is required',
                                                                })}
                                                            >
                                                                <MenuItem value="" disabled > <em>Select Loan sub type</em> </MenuItem>
                                                                <MenuItem value={1}>New Car Loan (Salaried)</MenuItem>
                                                                <MenuItem value={2}>New Car Loan (Self Employed)</MenuItem>
                                                                <MenuItem value={3}>User Car Loan (Salaried)</MenuItem>
                                                                <MenuItem value={4}>User Car Loan (Self Employed)</MenuItem>
                                                            </Select>
                                                        )}
                                                    />
                                                </FormControl>
                                                {errors.loan_type && getValues('loan_type') === "" && <p className="error"> {errors.loan_type.message}</p>}
                                            </div>
                                        </div>
                                        : null}
                                    {dataresponse?.loan_type_id === constVal.homeLoan ?
                                        <div className='col-lg-3'>
                                            <div className="mb-3 position-relative">
                                                <FormControl sx={{ width: '100%' }}   >
                                                    <InputLabel id="loan_type">Loan Sub Type*</InputLabel>
                                                    <Controller
                                                        name="loan_type"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                required
                                                                fullWidth
                                                                labelId="loan_type"
                                                                id="loan_type"
                                                                label="Income Source"
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('loan_type', e.target.value);
                                                                }} 
                                                                {...register('loan_type', {
                                                                    required: 'Loan sub type is required',
                                                                })}
                                                            >
                                                                <MenuItem value="" disabled > <em>Select Loan sub type</em> </MenuItem>
                                                                <MenuItem value="CONSTRUCTION-OF-INDEPENDENT-HOUSE">CONSTRUCTION OF INDEPENDENT HOUSE</MenuItem>
                                                                <MenuItem value="PURCHASE-OF-NEW HOUSE/FLAT">PURCHASE OF NEW HOUSE/FLAT</MenuItem>
                                                                <MenuItem value="LOAN-AGAINST-PROPERTY">LOAN AGAINST PROPERTY</MenuItem>
                                                            </Select>
                                                        )}
                                                    />
                                                </FormControl>
                                                {errors.loan_type && getValues('loan_type') === "" && <p className="error"> {errors.loan_type.message}</p>}
                                            </div>
                                        </div>
                                        : null}
                                    {dataresponse?.loan_type_id === constVal.educationLoan ?
                                        <div className='col-lg-3'>
                                            <div className="mb-3 position-relative">
                                                <FormControl sx={{ width: '100%' }}   >
                                                    <InputLabel id="loan_type">Loan Sub Type*</InputLabel>
                                                    <Controller
                                                        name="loan_type"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                required
                                                                fullWidth
                                                                labelId="loan_type"
                                                                id="loan_type"
                                                                label="Income Source"
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('loan_type', e.target.value);
                                                                }} 
                                                                {...register('loan_type', {
                                                                    required: 'Loan Type is required',
                                                                })}
                                                            >
                                                                <MenuItem value="" disabled > <em>select Loan sub type</em> </MenuItem>
                                                                {
                                                                    eduMastersList?.map((value) => {
                                                                        return (
                                                                            <MenuItem key={value.value} value={value.id}>
                                                                                {value.sucategory_name
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    })
                                                                }
                                                            </Select>
                                                        )}
                                                    />
                                                </FormControl>
                                                {errors.loan_type && getValues('loan_type') === "" && <p className="error"> {errors.loan_type.message}</p>}
                                            </div>
                                        </div>
                                        : null}
                                    {dataresponse?.loan_type_id === constVal.vehicleLoan ?
                                        <>
                                            <div className="col-md-12 m-2">
                                                <h5>Vehicle Detail</h5>
                                                <span className="pesrsonal_detail_hr"></span>
                                            </div>
                                            <div className='col-lg-3 mt-2 '>
                                                <div className="mb-3 position-relative">
                                                    <Controller
                                                        name="vehicle_category"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                fullWidth
                                                                id="vehicle_category"
                                                                label="Vehicle Category"
                                                                variant="outlined"
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('vehicle_category', e.target.value);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errors.vehicle_category && <p className="error"> {errors.vehicle_category.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-3 position-relative">
                                                    <FormControl sx={{ width: '100%' }}   >
                                                        <InputLabel id="vehicle_type">Vehicle Type*</InputLabel>
                                                        <Controller
                                                            name="vehicle_type"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Select
                                                                    required
                                                                    labelId="vehicle_type"
                                                                    {...field}
                                                                    onChange={(e) => {
                                                                        field.onChange(e);
                                                                        setValue('vehicle_type', e.target.value);
                                                                    }} 
                                                                    {...register('vehicle_type', {
                                                                        required: 'Vehicle type is required',
                                                                    })}
                                                                >
                                                                    <MenuItem value="" disabled > <em>Select Vehicle type</em> </MenuItem>
                                                                    {
                                                                        mastersList?.filter(master => master.type === "vehicle_type").map((value) => {
                                                                            return (
                                                                                <MenuItem key={value.value} value={value.value}>
                                                                                    {value.label}
                                                                                </MenuItem>
                                                                            );
                                                                        })
                                                                    }
                                                                </Select>
                                                            )}
                                                        />
                                                    </FormControl>
                                                    {errors.vehicle_type && getValues('vehicle_type') === "" && <p className="error"> {errors.vehicle_type.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-3 position-relative">
                                                    <Controller
                                                        name="manufacturer"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                fullWidth
                                                                id="manufacturer"
                                                                label="Manufacturer"
                                                                variant="outlined"
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('manufacturer', e.target.value);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errors.manufacturer && <p className="error"> {errors.manufacturer.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-3 position-relative">
                                                    <Controller
                                                        name="vehicle_model"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                fullWidth
                                                                id="vehicle_model"
                                                                label="Vehicle Model"
                                                                variant="outlined"
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('vehicle_model', e.target.value);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errors.vehicle_model && <p className="error"> {errors.vehicle_model.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-3 position-relative">
                                                    <Controller
                                                        name="supplier"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                fullWidth
                                                                id="supplier"
                                                                label="Supplier"
                                                                variant="outlined"
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('supplier', e.target.value);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errors.supplier && <p className="error"> {errors.supplier.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-3 position-relative">
                                                    <Controller
                                                        name="cost_of_vehicle"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                fullWidth
                                                                id="cost_of_vehicle"
                                                                label="Cost Of Vehicle"
                                                                variant="outlined"
                                                                type='number'
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('cost_of_vehicle', e.target.value);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errors.cost_of_vehicle && <p className="error"> {errors.cost_of_vehicle.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-3 position-relative">
                                                    <Controller
                                                        name="cost_of_insurance"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                fullWidth
                                                                id="cost_of_insurance"
                                                                label="Cost Of Insurance    "
                                                                variant="outlined"
                                                                type='number'
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('cost_of_insurance', e.target.value);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errors.cost_of_insurance && <p className="error"> {errors.cost_of_insurance.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-3 position-relative">
                                                    <Controller
                                                        name="cost_of_accessories"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                fullWidth
                                                                id="cost_of_accessories"
                                                                label="Cost Of Accessories"
                                                                variant="outlined"
                                                                type='number'
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('cost_of_accessories', e.target.value);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errors.cost_of_accessories && <p className="error"> {errors.cost_of_accessories.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-3 position-relative">
                                                    <Controller
                                                        name="road_tax"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                fullWidth
                                                                id="road_tax"
                                                                label="Road Tax "
                                                                variant="outlined"
                                                                type='number'
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('road_tax', e.target.value);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errors.road_tax && <p className="error"> {errors.road_tax.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className="mb-3 position-relative">
                                                    <Controller
                                                        name="other"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <TextField
                                                                fullWidth
                                                                id="other"
                                                                label="Road Tax "
                                                                variant="outlined"
                                                                type='number'
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    setValue('other', e.target.value);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    {errors.other && <p className="error"> {errors.other.message}</p>}
                                                </div>
                                            </div>
                                        </>
                                        : null}
                                </div>


                                <div className='btn-center'>

                                    <Button color='info' onClick={() => history(`/apply-loan/` + applicationId)}>
                                        Previous Page
                                    </Button>

                                    <Button color='warning' type="button" className='ml-2' onClick={handleSubmitDarft} >
                                        Save as Draft
                                    </Button>
                                    <Button type="button" color='primary' className='ml-2' onClick={handleSubmit(submitForm)} disabled={isSubmitting}>
                                        Submit
                                    </Button>


                                </div>
                            </form>
                        </div>
                    </div>
                    {snackBarOption.message ? (
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                            anchorOrigin={{ vertical, horizontal }}>
                            <Alert onClose={handleClose} severity={snackBarOption.type === 'E' ? "error" : 'success'} sx={{ width: '100%' }}>
                                {snackBarOption.message}
                            </Alert>
                        </Snackbar>) : null}
                </div>) : (<Loader />)}
        </div>
    )
}
