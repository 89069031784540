/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

module.exports = {
  /* 01.General */
  'general.copyright':
    'Admission Department by Artifintel © 2021 All Rights Reserved.',

  /* 02.User Login, Logout, Register */
  'user.login-title': 'Login',
  'user.register': 'Register',
  'user.forgot-password': 'Forgot Password',
  'user.email': 'E-mail',
  'user.password': 'Password',
  'user.forgot-password-question': 'Forget password?',
  'user.fullname': 'Full Name',
  'user.login-button': 'LOGIN',
  'user.register-button': 'REGISTER',
  'user.reset-password-button': 'RESET',
  'user.buy': 'BUY',
  'user.username': 'Username',

  /* 03.Menu */
  'menu.home': 'Home',
  'menu.app': 'Home',
  'menu.dashboards': 'Dashboards',
  'menu.gogo': 'Dashboard',
  'menu.student': 'Application',
  'menu.associates': 'Associates',
  'menu.associateprofile': 'Associate Details',
  'menu.studentPay': 'Payment',
  'menu.studentEnquiry': 'Enquiries',
  'menu.paymentdetails': 'Payment Detail',
  'menu.start': 'Start',
  'menu.second': 'Leads Management',
  'menu.menuleadmanagement': 'Leads',
  'menu.ui': 'UI',
  'menu.charts': 'Charts',
  'menu.chat': 'Chat',
  'menu.survey': 'Survey',
  'menu.todo': 'Todo',
  'menu.search': 'Search',
  'menu.blank-page': 'Enquiries',
  'menu.settings': 'Settings',
  'menu.accounts': 'Accounts',
  'menu.enquiry': 'Enquiry',
  'menu.user': 'Users',
  'menu.userm': 'User Management',
  'menu.docs': 'Doc & Files',
  'menu.mail': 'Message',
  'menu.support': 'Support',
  'menu.accmanagement': 'Account Management',
  'menu.enqmanagement': 'Enquiry Management',
  'menu.accountDetailPage': 'Details Page',
  'menu.payment': 'Payment Link Creation',
  'menu.reports': 'Reports and Statistics',
  'menu.formmanagement': 'Form Management',
  'menu.leadprofile': 'Lead Profile',
  'menu.userprofile': 'User Profile',
  'menu.applicationdetails': 'Application Detail',
  'menu.accountreport': 'Reports and Statistics',
  'menu.enqFormmanagement': 'Enquiry Form Management',
  'menu.enqreport': 'Reports and Statistics',
  'menu.consultantPortalDashboard': 'Dashboard',
  'menu.consultantPortalLeads': 'Leads',
  'menu.consultantPortalAccounts': 'Accounts',
  'menu.consultantPortalUser': 'Users',
  'menu.consultantPortalUserManagement': 'User Management',
  'menu.assocSearch': 'Assoc.Search',
  'menu.allAssociates': 'All Associates',
  'menu.favoriteAssociates': 'Favorite Associates',
  'menu.onReviewAssociates': 'On Review Associates',
  'menu.assignedAssociates': 'Assigned Associates',
  'menu.removedAssociates': 'Removed Associates',
  /* 04.Error Page */
  'pages.error-title': 'Ooops... looks like an error occurred!',
  'pages.error-code': 'Error code',
  'pages.go-back-home': 'GO BACK HOME',

  'pages.error-401': 'Unauthorized Session!',
  'pages.go-back-login': 'GO BACK LOGIN',
};
