import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { getItem, removeItem } from '../helpers/Utils';
import { useSelector } from 'react-redux';

function DynamicRouting() {
  const history = useNavigate();
  const data = useSelector(state => state);
  console.log('data :>> ', data);

//   const waitForStorageItem = async (key) => {
//     return new Promise((resolve) => {
//       const checkItem = () => {
//         const item = getItem(key);
//         if (item !== null) {
//           resolve(item);
//         } else {
//           setTimeout(checkItem, 100); // Check again after a short delay
//         }
//       };
//       checkItem();
//     });
//   };

  useEffect(() => {
        if (data?.payLoad?.plugin_accessibility !== null && (data?.payLoad?.plugin_accessibility === "Darcy" || data?.payLoad?.plugin_accessibility === 'Eliza')) { 
                    history('/home/') 
            }
            if (data?.payLoad?.editAppId !== null ) {
                history('/apply-loan/'+data?.payLoad?.editAppId)
                sessionStorage.removeItem('editAppId')
            } 

    // setTimeout(() => {
        // console.log('sessionStorage.getItem("apiKey") :>> ', sessionStorage.getItem('apiKey'));
        // console.log('data :>> ', data);
        
    // }, 1000);
    // const setupDynamicRouting = async () => {
    //   return new Promise((resolve) => {
    //     window.addEventListener('message', async (event) => {
    //       const data = event.data;
    //       if (
    //         data &&
    //         Object.keys(data).length > 0 &&
    //         !data.hasOwnProperty('data')
    //       ) {
    //         if (
    //           data.plugin_accessibility !== null &&
    //           (data.plugin_accessibility === 'Darcy' ||
    //             data.plugin_accessibility === 'Eliza')
    //         ) {
    //           await waitForStorageItem('plugin_accessibility'); // Replace with your actual storage key
    //           history('/home/');
    //           resolve();
    //         } else if (data.editAppId !== null) {
    //           await waitForStorageItem('editAppId'); // Replace with your actual storage key
    //           history('/apply-loan/' + data.editAppId);
    //           removeItem('editAppId');
    //           resolve();
    //         } else {
    //           await waitForStorageItem('api_key'); // Replace with your actual storage key
    //           history('/apply-loan');
    //           resolve();
    //         }
    //       }
    //     });
    //   });
    // };

    // setupDynamicRouting();
//   }, [history]);
  }, [history,data]);

  return <div>All Applied Loans</div>;
}

export default DynamicRouting;
