import { createSlice } from '@reduxjs/toolkit';

const formDataSlice = createSlice({
  name: 'formData',
  initialState: {
    data: null,
    dataBasic: null,
  },
  reducers: {
    setFormData: (state, action) => {
      if (action.payload.data) {
        state.data = action.payload.data;
      }
      if (action.payload.dataBasic) {
        state.dataBasic = action.payload.dataBasic;
      }
      // state.data = action.payload.data;
      // state.dataBasic = action.payload.dataBasic;
    },
  },
});

export const { setFormData } = formDataSlice.actions;

export default formDataSlice.reducer;