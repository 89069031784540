/* eslint-disable no-unused-vars */
import React, { useState, useReducer, useEffect, useMemo,useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { setFormData } from '../redux/formDataSlice';
import { setMasterData } from '../redux/masterDataSlice';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button, Input } from 'reactstrap';
import { Toast, ToastBody, ToastHeader, Spinner } from 'reactstrap';
import FormControl from '@mui/material/FormControl';
import useAxiosInstance from '../axiosi-ins';
import Loader from '../Loader'
import LoanProgress from './LoanProgress';
import { updateProgress } from '../redux/actions';
import { getItem, getItemSession, setItem } from '../helpers/Utils';
import DataUContext from '../context/DataUContext';
import TopButton from './BackButton';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Addresses = React.memo(() => {
    const axios = useAxiosInstance();
    const { applicationId } = useParams();
    const dispatch = useDispatch();
    const history = useNavigate();
    const dataU  = useSelector((state) => state.payload); 
    // const dataU  = useContext(DataUContext); 
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isSubmitted, isSubmitting },
        setValue,
        setError,
        getValues
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onBlur',
        defaultValues: {
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "application_id": applicationId,
            "residential_status": "",
            "residence_type": "",
            "years_of_residence": "",
            "monthly_rent": "",
            "is_draft": 0,
            "addresses": {
                "present_address": {
                    "address_line1": "",
                    "address_line2": "",
                    "address_line3": "",
                    "pincode": "",
                    "state": "",
                    "city": "",
                    "state_name": "",
                    "city_name": "",
                    "landmark": "",
                    "email": "",
                    "phone": ""
                },
                "permanent_address": {
                    "address_line1": "",
                    "address_line2": "",
                    "address_line3": "",
                    "pincode": "",
                    "state": "",
                    "city": "",
                    "state_name": "",
                    "city_name": "",
                    "landmark": "",
                    "email": "",
                    "phone": ""
                },
                "office_address": {
                    "address_line1": "",
                    "address_line2": "",
                    "address_line3": "",
                    "pincode": "",
                    "state": "",
                    "city": "",
                    "state_name": "",
                    "city_name": "",
                    "landmark": "",
                    "email": "",
                    "phone": ""
                }
            }
        }
    });
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'right',
    });
    const [open, setOpen] = React.useState(false);
    const { vertical, horizontal } = state;
    const handleClose = () => {
        setOpen(false)
    };
    const [mastersList, setMastersList] = useState([]);
    const formData = useSelector((state) => state.formData.dataBasic);
    const [loading, setLoading] = useState(false);
    const [dataresponse, setdataresponse] = useState([]);
    const [snackBarOption, setSnackBarOption] = useState({
        message: null,
        type: 'E',
    });
    const [show, setShow] = useState(true);
    const toggle = () => setShow(!show);
    const [checked, setChecked] = useState(false);

    const getMasterList = () => {
        axios.get('form/masters')
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    setMastersList(res.data)
                    setLoading(true);
                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)
                }
            })
            .catch((err) => {
                setdataresponse([]);
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
                console.log(errorMessage);
            });
    };
    const handleChange1 = (event) => {
        const val = event.target.value
        const name = event.target.name
        if (val.length === 6) {
            axios.get(`pincodes-list?pincode=${val}`)
                .then((response) => {
                    const res = response.data;
                    if (res.success === true) {
                        const firstResult = res.data[0];
                        // console.log('firstResult :>> ', firstResult);
                        const city = firstResult.city_id;
                        const state = firstResult.state_id;
                        const cityName = firstResult.city;
                        const stateName = firstResult.state;
                        switch (name) {
                            case 'addresses.present_address.pincode':
                                setValue('addresses.present_address.state', state)
                                setValue('addresses.present_address.city', city)
                                setValue('addresses.present_address.state_name', stateName)
                                setValue('addresses.present_address.city_name', cityName)
                                break;
                            case 'addresses.permanent_address.pincode':
                                setValue('addresses.permanent_address.state', state)
                                setValue('addresses.permanent_address.city', city)
                                setValue('addresses.permanent_address.state_name', stateName)
                                setValue('addresses.permanent_address.city_name', cityName)
                                break;
                            case 'addresses.office_address.pincode':
                                setValue('addresses.office_address.state', state)
                                setValue('addresses.office_address.city', city)
                                setValue('addresses.office_address.state_name', stateName)
                                setValue('addresses.office_address.city_name', cityName)
                                break;
                            default:
                                break;
                        }
                    } else {
                        setSnackBarOption({
                            type: 'E',
                            message: res.message,
                        });
                        setOpen(true)
                    }
                })
                .catch((err) => {
                    setdataresponse([]);
                    let errorMessage = err.message;
                    if (err?.response?.data?.message) {
                        errorMessage = err.response.data.message;
                    }
                    setSnackBarOption({
                        type: 'E',
                        message: errorMessage,
                    });
                    setOpen(true)
                    console.log(errorMessage);
                });

        }
    };
    const handleChange = (event) => {
        setChecked(event.target.checked);
        if (event.target.checked) {
            const presentAddress = getValues('addresses.present_address');
            Object.entries(presentAddress).forEach(([key, value]) => {
                setValue(`addresses.permanent_address.${key}`, value);
            });
        } else {
            const prmanentAddress = getValues('addresses.permanent_address');
            Object.entries(prmanentAddress).forEach(([key, value]) => {
                setValue(`addresses.permanent_address.${key}`, '');
            });

        }
    };
    const handleSubmitDarft = () => {
        setValue('is_draft', 1)
        submitForm();
    };

    const submitForm = () => {
        axios.post(`/loan/saveAddresses`, getValues())
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    // setLoading(true);
                    setSnackBarOption({
                        type: 'S',
                        message: res.message,
                    });
                    setOpen(true)
                    setTimeout(() => {
                        history(`/loan/co-applicant/` + applicationId);
                    }, 3000);

                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)

                }
            })
            .catch((error) => {
                let errorMessage = error.message;
                if (error?.response?.data?.message) {
                    errorMessage = error.response.data.message;
                    const res = error.response.data
                    if (res.message === "VALIDATION_ERROR") {
                        Object.entries(res.data).map(([value, text]) => setError(value, { message: text }))
                    }
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
            });
    };


    useEffect(() => {
        dispatch(updateProgress(2))
        getMasterList();
        const sendData = {
            "ref_code": dataU?.refCode || getItemSession('refCode'),
            "application_id": applicationId
        }
        axios.get(`/loan/reportDetail?${queryString.stringify(sendData)}`)
            .then((response) => {
                const res = response.data;
                if (res.success === true) {
                    setdataresponse(res.data);

                    setValue('residential_status', res.data.addresse_details?.residential_status)
                    setValue('residence_type', res.data.addresse_details?.residence_type)
                    setValue('years_of_residence', res.data.addresse_details?.years_of_residence)
                    setValue('monthly_rent', res.data.addresse_details?.monthly_rent)

                    res.data.addresses.forEach((address) => {
                        const setNestedValue = (prefix, object) => {
                            Object.entries(object).forEach(([key, value]) => {
                                const field = `${prefix}.${key}`;
                                setValue(field, value);
                                if (value && key === 'city') {
                                    setValue(`${field}`, value.id);
                                    setValue(`${field}_name`, value.name);
                                }
                                if (value && key === 'state') {
                                    setValue(`${field}`, value.id);
                                    setValue(`${field}_name`, value.name);
                                }
                            });
                        };

                        if (address.address_type === 1) {
                            setNestedValue('addresses.present_address', address);
                        } else if (address.address_type === 2) {
                            setNestedValue('addresses.permanent_address', address);
                        } else if (address.address_type === 3) {
                            setNestedValue('addresses.office_address', address);
                        }
                    });
                    setLoading(true);
                } else {
                    setSnackBarOption({
                        type: 'E',
                        message: res.message,
                    });
                    setOpen(true)
                }

            })
            .catch((err) => {
                setdataresponse([]);
                let errorMessage = err.message;
                if (err?.response?.data?.message) {
                    errorMessage = err.response.data.message;
                }
                setSnackBarOption({
                    type: 'E',
                    message: errorMessage,
                });
                setOpen(true)
                console.log(errorMessage);
            });
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [applicationId, dispatch, setdataresponse, setValue]);
    return (
        <div>
            {loading ? (
                <div className="App">
                    <div className='container width_1170'>
                        <div className='card'>
                            <div className='row'>
                                <TopButton />
                            </div>
                            <div className='row mt-4 mb-2 '>
                                <LoanProgress />
                            </div>
                            <form method="post" onSubmit={handleSubmit(submitForm)} className="mt-3 px-3 bit-1" id="checkEligibility_form">
                                <div className='row'>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <FormControl sx={{ width: '100%' }}   >
                                                <InputLabel id="residential_status">Residential Status</InputLabel>
                                                <Controller
                                                    name="residential_status"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            labelId="residential_status"
                                                            variant="outlined"
                                                            {...field}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue('residential_status', e.target.value);
                                                            }}
                                                        >
                                                            <MenuItem value="" disabled > <em>Select Residential status</em> </MenuItem>
                                                            {
                                                                mastersList?.filter(master => master.type === "residential_status").map((value) => {
                                                                    return (
                                                                        <MenuItem key={value.value} value={value.value}>
                                                                            {value.label}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                            {errors.residential_status && <p className="error"> {errors.residential_status.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <FormControl sx={{ width: '100%' }}   >
                                                <InputLabel id="residence_type">Residence  Type</InputLabel>
                                                <Controller
                                                    name="residence_type"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            labelId="residence_type"
                                                            variant="outlined"
                                                            {...field}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue('residence_type', e.target.value);
                                                            }}
                                                        >
                                                            <MenuItem value="" disabled > <em>Select Residence type</em> </MenuItem>
                                                            {
                                                                mastersList?.filter(master => master.type === "residence_type").map((value) => {
                                                                    return (
                                                                        <MenuItem key={value.value} value={value.label}>
                                                                            {value.value}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                            {errors.residence_type && <p className="error"> {errors.residence_type.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <FormControl sx={{ width: '100%' }}   >
                                                <InputLabel id="years_of_residence">No.of year at Residence</InputLabel>
                                                <Controller
                                                    name="years_of_residence"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            labelId="years_of_residence"
                                                            variant="outlined"
                                                            {...field}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setValue('years_of_residence', e.target.value);
                                                            }}
                                                        >
                                                            <MenuItem value="" disabled > <em>Select Years of residence</em> </MenuItem>
                                                            {
                                                                mastersList?.filter(master => master.type === "years_of_residence").map((value) => {
                                                                    return (
                                                                        <MenuItem key={value.value} value={value.label}>
                                                                            {value.value}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                            {errors.years_of_residence && <p className="error"> {errors.years_of_residence.message}</p>}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="monthly_rent"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="monthly_rent"
                                                        type='number'
                                                        label="Monthly Rent"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('monthly_rent', e.target.value);
                                                        }}
                                                        {...register('monthly_rent', {
                                                            // required: 'Monthly Rent is required',
                                                            type: Number
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors.monthly_rent && <p className="error"> {errors.monthly_rent.message}</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-12 m-1">
                                        <h5>Present Address </h5>
                                        <span className="pesrsonal_detail_hr"></span>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.present_address.address_line1" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.present_address.address_line1"
                                                        label="Address Line 1*"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.present_address.address_line1', e.target.value);
                                                        }}
                                                        {...register('addresses.present_address.address_line1', {
                                                            required: 'Address Line 1 is required',
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.present_address?.address_line1 && (
                                                <p className="error"> {errors.addresses.present_address.address_line1.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.present_address.address_line2" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.present_address.address_line2"
                                                        label="Address Line 2*"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.present_address.address_line2', e.target.value);
                                                        }}
                                                        {...register('addresses.present_address.address_line2', {
                                                            required: 'Address Line 2 is required',
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.present_address?.address_line2 && (
                                                <p className="error"> {errors.addresses.present_address.address_line2.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.present_address.address_line3" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.present_address.address_line3"
                                                        label="Address Line 3"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.present_address.address_line3', e.target.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.present_address?.address_line3 && (
                                                <p className="error"> {errors.addresses.present_address.address_line3.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.present_address.pincode" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.present_address.pincode"
                                                        label="Pincode*"
                                                        variant="outlined"
                                                        type='number'
                                                        onKeyUp={handleChange1}
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.present_address.pincode', e.target.value);
                                                        }}
                                                        {...register('addresses.present_address.pincode', {
                                                            required: 'Pin Code is required',
                                                            pattern: {
                                                                value: /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/,
                                                                message: 'Entered the valid Zipcode',
                                                            },
                                                            minLength: {
                                                                value: 6,
                                                                message: 'Zipcode should be 6 in length',
                                                            },
                                                            maxLength: {
                                                                value: 6,
                                                                message: 'Zipcode should be 6 in length',
                                                            },
                                                            type: Number,
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.present_address?.pincode && (
                                                <p className="error"> {errors.addresses.present_address.pincode.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.present_address.city_name" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.present_address.city_name"
                                                        label="City*"
                                                        variant="outlined"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.present_address.city_name', e.target.value);
                                                        }}
                                                        {...register('addresses.present_address.city_name', {
                                                            required: 'City is required',
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.present_address?.city_name && (
                                                <p className="error"> {errors.addresses.present_address.city_name.message}</p>
                                            )}
                                            <Controller
                                                name="addresses.present_address.city"
                                                control={control}
                                                render={({ field }) => (
                                                    <input type='hidden' {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.present_address.city', e.target.value);
                                                        }} />
                                                )}
                                            />
                                        </div>

                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.present_address.state_name" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.present_address.state_name"
                                                        label="State*"
                                                        variant="outlined"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.present_address.state_name', e.target.value);
                                                        }}
                                                        {...register('addresses.present_address.state_name', {
                                                            required: 'State is required',
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.present_address?.state_name && (
                                                <p className="error"> {errors.addresses.present_address.state_name.message}</p>
                                            )}
                                            <Controller
                                                name="addresses.present_address.state"
                                                control={control}
                                                render={({ field }) => (
                                                    <input type='hidden' {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.present_address.state', e.target.value);
                                                        }} />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.present_address.landmark" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.present_address.landmark"
                                                        label="Landmark*"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.present_address.landmark', e.target.value);
                                                        }}
                                                        {...register('addresses.present_address.landmark', {
                                                            required: 'Land Mark is required',
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.present_address?.landmark && (
                                                <p className="error"> {errors.addresses.present_address.landmark.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.present_address.email" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.present_address.email"
                                                        label="EMAIL ID"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.present_address.email', e.target.value);
                                                        }}
                                                        {...register('addresses.present_address.email', {
                                                            pattern: {
                                                                value: /\S+@\S+\.\S+/,
                                                                message: 'Entered value does not match email format',
                                                            },
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.present_address?.email && (
                                                <p className="error"> {errors.addresses.present_address.email.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.present_address.phone" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.present_address.phone"
                                                        label="Phone Number"
                                                        variant="outlined"
                                                        type='number'
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.present_address.phone', e.target.value);
                                                        }}
                                                        {...register('addresses.present_address.phone', {
                                                            pattern: {
                                                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                                                                message:
                                                                    'Entered value Phone Number in valid format',
                                                            },
                                                            minLength: {
                                                                value: 10,
                                                                message: 'Enter the valid Phone Number',
                                                            },
                                                            maxLength: {
                                                                value: 10,
                                                                message: 'Enter the valid Phone Number',
                                                            },
                                                            type: Number,
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.present_address?.phone && (
                                                <p className="error"> {errors.addresses.present_address.phone.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-12 m-1">
                                        <h5>Permanent Address </h5>
                                        <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} />} label="Same as persent address " />
                                        <span className="pesrsonal_detail_hr"></span>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.permanent_address.address_line1" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.permanent_address.address_line1"
                                                        label="Address Line 1*"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.permanent_address.address_line1', e.target.value);
                                                        }}
                                                        {...register('addresses.permanent_address.address_line1', {
                                                            required: 'Address Line 1 is required',
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.permanent_address?.address_line1 && (
                                                <p className="error"> {errors.addresses.permanent_address.address_line1.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.permanent_address.address_line2" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.permanent_address.address_line2"
                                                        label="Address Line 2*"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.permanent_address.address_line2', e.target.value);
                                                        }}
                                                        {...register('addresses.permanent_address.address_line2', {
                                                            required: 'Address Line 2 is required',
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.permanent_address?.address_line2 && (
                                                <p className="error"> {errors.addresses.permanent_address.address_line2.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.permanent_address.address_line3" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.permanent_address.address_line3"
                                                        label="Address Line 3"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.permanent_address.address_line3', e.target.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.permanent_address?.address_line3 && (
                                                <p className="error"> {errors.addresses.permanent_address.address_line3.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.permanent_address.pincode" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.permanent_address.pincode"
                                                        label="Pincode*"
                                                        variant="outlined"
                                                        type='number'
                                                        onKeyUp={handleChange1}
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.permanent_address.pincode', e.target.value);
                                                        }}
                                                        {...register('addresses.permanent_address.pincode', {
                                                            required: 'Pin Code is required',
                                                            pattern: {
                                                                value: /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/,
                                                                message: 'Entered the valid Zipcode',
                                                            },
                                                            minLength: {
                                                                value: 6,
                                                                message: 'Zipcode should be 6 in length',
                                                            },
                                                            maxLength: {
                                                                value: 6,
                                                                message: 'Zipcode should be 6 in length',
                                                            },
                                                            type: Number,
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.permanent_address?.pincode && (
                                                <p className="error"> {errors.addresses.permanent_address.pincode.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.permanent_address.city_name" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.permanent_address.city_name"
                                                        label="City*"
                                                        variant="outlined"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.permanent_address.city_name', e.target.value);
                                                        }}
                                                        {...register('addresses.permanent_address.city_name', {
                                                            required: 'City is required',
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.permanent_address?.city_name && (
                                                <p className="error"> {errors.addresses.permanent_address.city_name.message}</p>
                                            )}
                                            <Controller
                                                name="addresses.permanent_address.city"
                                                control={control}
                                                render={({ field }) => (
                                                    <input type='hidden' {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.permanent_address.city', e.target.value);
                                                        }} />
                                                )}
                                            />
                                        </div>

                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.permanent_address.state_name" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.permanent_address.state_name"
                                                        label="State*"
                                                        variant="outlined"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.permanent_address.state_name', e.target.value);
                                                        }}
                                                        {...register('addresses.permanent_address.state_name', {
                                                            required: 'State is required',
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.permanent_address?.state_name && (
                                                <p className="error"> {errors.addresses.permanent_address.state_name.message}</p>
                                            )}
                                            <Controller
                                                name="addresses.permanent_address.state"
                                                control={control}
                                                render={({ field }) => (
                                                    <input type='hidden' {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.permanent_address.state', e.target.value);
                                                        }} />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.permanent_address.landmark" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.permanent_address.landmark"
                                                        label="Landmark*"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.permanent_address.landmark', e.target.value);
                                                        }}
                                                        {...register('addresses.permanent_address.landmark', {
                                                            required: 'Land Mark is required',
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.permanent_address?.landmark && (
                                                <p className="error"> {errors.addresses.permanent_address.landmark.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.permanent_address.email" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.permanent_address.email"
                                                        label="EMAIL ID"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.permanent_address.email', e.target.value);
                                                        }}
                                                        {...register('addresses.permanent_address.email', {
                                                            pattern: {
                                                                value: /\S+@\S+\.\S+/,
                                                                message: 'Entered value does not match email format',
                                                            },
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.permanent_address?.email && (
                                                <p className="error"> {errors.addresses.permanent_address.email.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.permanent_address.phone" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.permanent_address.phone"
                                                        label="Phone Number"
                                                        type='number'
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.permanent_address.phone', e.target.value);
                                                        }}
                                                        {...register('addresses.permanent_address.phone', {
                                                            pattern: {
                                                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                                                                message:
                                                                    'Entered value Phone Number in valid format',
                                                            },
                                                            minLength: {
                                                                value: 10,
                                                                message: 'Enter the valid Phone Number',
                                                            },
                                                            maxLength: {
                                                                value: 10,
                                                                message: 'Enter the valid Phone Number',
                                                            },
                                                            type: Number,
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.permanent_address?.phone && (
                                                <p className="error"> {errors.addresses.permanent_address.phone.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-12 m-1">
                                        <h5>Office Address </h5>
                                        <span className="pesrsonal_detail_hr"></span>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.office_address.address_line1" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.office_address.address_line1"
                                                        label="Address Line 1"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.office_address.address_line1', e.target.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.office_address?.address_line1 && (
                                                <p className="error"> {errors.addresses.office_address.address_line1.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.office_address.address_line2" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.office_address.address_line2"
                                                        label="Address Line 2"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.office_address.address_line2', e.target.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.office_address?.address_line2 && (
                                                <p className="error"> {errors.addresses.office_address.address_line2.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.office_address.address_line3" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.office_address.address_line3"
                                                        label="Address Line 3"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.office_address.address_line3', e.target.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.office_address?.address_line3 && (
                                                <p className="error"> {errors.addresses.office_address.address_line3.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.office_address.pincode" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.office_address.pincode"
                                                        label="Pincode"
                                                        type='number'
                                                        variant="outlined"
                                                        onKeyUp={handleChange1}
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.office_address.pincode', e.target.value);
                                                        }}
                                                        {...register('addresses.office_address.pincode', {
                                                            pattern: {
                                                                value: /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/,
                                                                message: 'Entered the valid Zipcode',
                                                            },
                                                            minLength: {
                                                                value: 6,
                                                                message: 'Zipcode should be 6 in length',
                                                            },
                                                            maxLength: {
                                                                value: 6,
                                                                message: 'Zipcode should be 6 in length',
                                                            },
                                                            type: Number,
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.office_address?.pincode && (
                                                <p className="error"> {errors.addresses.office_address.pincode.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.office_address.city_name" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.office_address.city_name"
                                                        label="City"
                                                        variant="outlined"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.office_address.city_name', e.target.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.office_address?.city_name && (
                                                <p className="error"> {errors.addresses.office_address.city_name.message}</p>
                                            )}
                                            <Controller
                                                name="addresses.office_address.city"
                                                control={control}
                                                render={({ field }) => (
                                                    <input type='hidden' {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.office_address.city', e.target.value);
                                                        }} />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.office_address.state_name" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.office_address.state_name"
                                                        label="State"
                                                        variant="outlined"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.office_address.state_name', e.target.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.office_address?.state_name && (
                                                <p className="error"> {errors.addresses.office_address.state_name.message}</p>
                                            )}
                                            <Controller
                                                name="addresses.office_address.state"
                                                control={control}
                                                render={({ field }) => (
                                                    <input type='hidden' {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.office_address.state', e.target.value);
                                                        }} />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.office_address.landmark" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.office_address.landmark"
                                                        label="Landmark"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.office_address.landmark', e.target.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.office_address?.landmark && (
                                                <p className="error"> {errors.addresses.office_address.landmark.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.office_address.email" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.office_address.email"
                                                        label=" Office Eamil ID"
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.office_address.email', e.target.value);
                                                        }}
                                                        {...register('addresses.office_address.email', {
                                                            pattern: {
                                                                value: /\S+@\S+\.\S+/,
                                                                message: 'Entered value does not match email format',
                                                            },
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.office_address?.email && (
                                                <p className="error"> {errors.addresses.office_address.email.message}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="mb-3 position-relative">
                                            <Controller
                                                name="addresses.office_address.phone" // Corrected field name
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        fullWidth
                                                        id="addresses.office_address.phone"
                                                        label="Phone Number"
                                                        type='number'
                                                        variant="outlined"
                                                        {...field}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            setValue('addresses.office_address.phone', e.target.value);
                                                        }}
                                                        {...register('addresses.office_address.phone', {
                                                            pattern: {
                                                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                                                                message:
                                                                    'Entered value Phone Number in valid format',
                                                            },
                                                            minLength: {
                                                                value: 10,
                                                                message: 'Enter the valid Phone Number',
                                                            },
                                                            maxLength: {
                                                                value: 10,
                                                                message: 'Enter the valid Phone Number',
                                                            },
                                                            type: Number,
                                                        })}
                                                    />
                                                )}
                                            />
                                            {errors?.addresses?.office_address?.phone && (
                                                <p className="error"> {errors.addresses.office_address.phone.message}</p>
                                            )}
                                        </div>
                                    </div>

                                </div>


                                <div className='btn-center'>

                                    <Button color="secondary" variant="contained" className=" ml-2 " onClick={() => history(`/loan/basic-details/` + applicationId)} >
                                        Previous Page
                                    </Button>
                                    <Button color='warning' type="button" className='ml-2' onClick={handleSubmitDarft} >
                                        Save as Draft
                                    </Button>
                                    <Button type="button" color='primary' className='ml-2' onClick={handleSubmit(submitForm)} disabled={isSubmitting}>
                                        Submit
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                    {snackBarOption.message ? (
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                            anchorOrigin={{ vertical, horizontal }}>
                            <Alert onClose={handleClose} severity={snackBarOption.type === 'E' ? "error" : 'success'} sx={{ width: '100%' }}>
                                {snackBarOption.message}
                            </Alert>
                        </Snackbar>) : null}
                </div>) : (<Loader />)}
        </div>
    )
});

export default Addresses;
