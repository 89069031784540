/* eslint-disable no-unused-vars */
import React, { Suspense, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { connect, useDispatch } from 'react-redux';
import { configureStore } from './redux/store';
import { setMasterData } from './redux/masterDataSlice';
import reportWebVitals from './reportWebVitals';
import App from './App';
import Loader from './Loader';
import { updatePayLoad } from './redux/actions';
import { setItem,setItemSession} from './helpers/Utils';
import LoaderInit from './LoaderInit';
const Main = () => {
  const [datSource, setDatSource] = React.useState({});
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true); // New loading state
  const [progress, setProgress] = useState(0);


  useEffect(() => { 
    const fetchDataAndDispatch = async () => {
      try {

        // setTimeout(() => {
        //   if (sessionStorage.getItem('isreloaded') !== '1') {
        //     sessionStorage.setItem('isreloaded', '1');
        //     window.location.reload(true); 
        //   }
        // }, 5000);

        // const event = await Promise.race([
        //   new Promise((resolve) => window.addEventListener('message', resolve)),
        //   new Promise((_, reject) => setTimeout(() => reject('Timeout'), 5000)) // 5000ms timeout
        // ]);
        const event = await new Promise((resolve) => {
          window.addEventListener('message', resolve);
        });
        // console.log('event', event)

        // if (event === 'Timeout') {
        //   console.error('Message event timed out.');
        //   window.location.reload(true);           
        //   return;
        // }

        const Datas = event.data;
        // const Datas = {

        //   // live bharatearns 
        //   // apiKey: "7ee4238e5b87eef69602213861fc641b1fd8c05476548451552430a130358822", 
        //   // apiSecret: "FZmJdKogcK1gxvKinWQ74W0wZseWR7s0QeFvPaRy6bP6ziPt+Qmts+VttiLTrgRc1PWUbxlz2P+MjFKrQDEuWOM/kOkh+RNuGcYpPklaz5qna/drnuZYikp/xdTREIUk3JzKJt1RywWhx3uQfwKEyK468P6FNIrtBcpA/Loh6/nBBs1Y5NSZBDH9VvCdfIto",  
        //   // refCode: `YXOZF28823`, 
        //   // serverIp: `162.215.172.60`,
        //   // services: 'CRT',
        //   // // catype:12,
        //   // plugin_accessibility: `Bingley`, //Grid, Form, All -  this is required 
        //   // source:'web',

        //   // staging bharatearns 
        //   apiKey: "f415d0dca75ea17bf0d433e5b9ab3360f0eaeb4262215d58091e873922fbff90", 
        //   apiSecret: "bk33RsF+sE7gH1fdAGTz//ygdL/+ZFiceZvgh6JDL0NHoujGykx6bXjbvlO12H8ry7p53unewUSUK18XkeXT2ndokZE6so1y/uNDeynZthPqimdU9p/X9HokMMkdDFhwh3Z9EaFz5KsKphBP15KtbtOgmVxx0VF6kUrAiIUyaA3sjM9+TOQoWtU3ZM5aD8Z5",  
        //   refCode: `YXOZF28823`, 
        //   serverIp: `162.215.172.60`,
        //   // editAppId: 'SLA00528',
        //   // services: 'CRT', 
        //   // catype:`121`,
        //   plugin_accessibility: `Bingley`, //Grid, Form, All -  this is required 
        //   source:'web',

        //   // apiKey: "f415d0dca75ea17bf0d433e5b9ab3360f0eaeb4262215d58091e873922fbff90", 
        //   // apiSecret: "bk33RsF+sE7gH1fdAGTz//ygdL/+ZFiceZvgh6JDL0NHoujGykx6bXjbvlO12H8ry7p53unewUSUK18XkeXT2ndokZE6so1y/uNDeynZthPqimdU9p/X9HokMMkdDFhwh3Z9EaFz5KsKphBP15KtbtOgmVxx0VF6kUrAiIUyaA3sjM9+TOQoWtU3ZM5aD8Z5",  
        //   // refCode: `YXOZF28823`,  
        //   // serverIp: `162.215.172.60`,
        //   // services: 'CRT',
        //   // // laptype: 'CRT',
        //   // // other_info: 'CRT',
        //   // // editAppId: 'SLA00528',
        //   // plugin_accessibility: `Eliza`, //Grid, Form, All -  this is required 
        //   // source: 'web',
        // };
        

        if (Datas && Object.keys(Datas).length > 0 && !Datas.hasOwnProperty('data')) {
          setItem('apiKey', Datas?.apiKey);
          setItem('apiSecret', Datas?.apiSecret);
          setItem('refCode', Datas?.refCode);
          setItem('serverIp', Datas?.serverIp);
          setItem('source', Datas?.source);
          setItem('plugin_accessibility', Datas?.plugin_accessibility);
          setItem('services', Datas?.services);
          setItem('laptype', Datas?.laptype);
          setItem('editAppId', Datas?.editAppId);
          setItem('device_id', Datas?.device_id);
          setItem('other_info', Datas?.other_info);
          setItem('catype', Datas?.catype);

          setItemSession('apiKey', Datas?.apiKey);
          setItemSession('apiSecret', Datas?.apiSecret);
          setItemSession('refCode', Datas?.refCode);
          setItemSession('serverIp', Datas?.serverIp);
          setItemSession('source', Datas?.source);
          setItemSession('plugin_accessibility', Datas?.plugin_accessibility);
          setItemSession('services', Datas?.services);
          setItemSession('laptype', Datas?.laptype);
          setItemSession('editAppId', Datas?.editAppId);
          setItemSession('device_id', Datas?.device_id);
          setItemSession('other_info', Datas?.other_info);
          setItemSession('catype', Datas?.catype);

          setDatSource(Datas);

          dispatch(updatePayLoad(Datas));
          // console.log('Datas from Apprender.js in use effect as handel message ', Datas);
          let intervalId;
          let count = 0;

          const updateProgress = () => {
            setProgress(count);
            count++;
            if (count <= 100) {
              intervalId = setTimeout(updateProgress, 10);
            }
          };

          updateProgress();

          return () => {
            clearTimeout(intervalId);
          };
          // for (let i = 0; i <= 100; i++) {
          //   setTimeout(() => {
          //     setProgress(i);
          //   }, i * 10); // Update progress every 10ms for smooth animation
          // }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // Set loading to false regardless of success or 
        // console.log('first')
        setLoading(false);
      }
    };

    // fetchDataAndDispatch(); // Call the async function
    fetchDataAndDispatch().then(() => {
      // Continue with rendering the App component once data is available
      console.log('Data has been fetched, rendering App component');
    });

    return () => {
      window.removeEventListener('message', fetchDataAndDispatch);
    };

  }, [dispatch, setDatSource]);
  if (loading) {
    return <LoaderInit progress={progress} />;
  }
  // return (
  //   <Suspense fallback={<Loader />}>
  //     <App dataU={datSource} />
  //   </Suspense>
  // );
  return datSource ? (
    <Suspense fallback={<LoaderInit progress={progress} />}>
      <App dataU={datSource} />
    </Suspense>
  ) : null;
};

const store = configureStore(); // Create the Redux store
const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store} > {/* Wrap the Main component with the Provider and provide the Redux store */}
    <Main />
  </Provider>
);
reportWebVitals();
// const root = createRoot(document.getElementById('root')); // Create a root
// root.render(<Main />);
// // ReactDOM.render(, document.getElementById('root'));

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// <Provider store={configureStore()}>
//       <Suspense fallback={<Loader />}>
//         {/* <Suspense fallback={<div className="loading" />}> */}
//         <App dataU={Data} />
//       </Suspense>
//     </Provider>
