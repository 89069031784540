/* eslint-disable global-require */ 
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-circular-progressbar/dist/styles.css';
import './assets/css/custom.css';
import './assets/css/responsive.css';
// import 'react-perfect-scrollbar/dist/css/styles.css';
// import 'react-big-calendar/lib/css/react-big-calendar.css';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import {
  isMultiColorActive,
  defaultColor,
  isDarkSwitchActive,
} from './constants/defaultValues';
import { getCurrentColor, setCurrentColor } from './helpers/Utils';

const color =
  isMultiColorActive || isDarkSwitchActive ? getCurrentColor() : defaultColor;
setCurrentColor(color);
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js')
      .then(registration => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch(error => {
        console.error('Service Worker registration failed:', error);
      });
  });
}

const render = () => {
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
    require('./AppRenderer');
  });
};
render();



// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import { createBrowserRouter,
//   RouterProvider,
// } from "react-router-dom";
// import { createStore } from 'redux'
// import { Provider } from 'react-redux'
// // import store from './store';

// // import App from './App';
// import './index.css';
// import reportWebVitals from './reportWebVitals';
// import Home from './components/Home';
// import NotFound from './components/NotFound';
// import rootReducer from './reducers'

// const store = createStore(rootReducer)
// // import AppRouter from './router/AppRouter';
// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Home/>,
//     errorElement:<NotFound/>
//   },
//   {
//     path: "/home",
//     element: <Home/>,
//     errorElement:<NotFound/>
//   }
// ]);
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode> 
//   <Provider store={store}>
//     { 
//       // <App /> 
//     }
//     <RouterProvider router={router} /> 
//   </Provider>,
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
