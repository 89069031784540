import { useNavigate } from "react-router-dom";
import { Button } from 'reactstrap';
import { getItem } from '../helpers/Utils';

export default function TopButton() {
  const history = useNavigate();

  return (
    getItem('plugin_accessibility') !== null && (getItem('plugin_accessibility') === "Darcy" || getItem('plugin_accessibility') === 'Eliza') ?
      <div className='all_aplied_backBTN'>
        <Button color='info' onClick={() => history('/home')}>
          &lArr;	All Applied Loans
        </Button>
      </div> : null
  );
}